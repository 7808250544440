import cn from 'classnames';
import { format } from 'date-fns';
import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { CloseIcon, ExclamationCircleFillDarkIcon, SearchLineDarkIcon } from 'tcp-react-icons';
import {
  ButtonDropdownFileUploader,
  FileUploaderOption,
} from '../../../components/button-dropdown-file-uploader';
import { CheckboxSelect, CheckboxSelectOption } from '../../../components/checkbox-select';
import { filterListWithPosBySearchText, translate, isNumber } from '../../../helpers/utils';
import { ControlPanel } from '../control-panel';
import { useSimpleViewTable } from '../../../hooks/use-simple-view-table';
import { InputIcon } from '../../../components/input-icon';
import { IsVisibleToggle } from '../../../components/is-visible-toggle';
import { LocaleColumn } from '../../../components/locale-column';
import { Notification } from '../../../components/notification';
import { ResultsNotFound } from '../../../components/results-not-found';
import { Toolbar } from '../../../components/toolbar';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { ExportOption, ViewExporter } from '../../../components/view-exporter';
import {
  CAMPAIGNS_VIEW_ACTIVE_ONLY_TOGGLE,
  CAMPAIGNS_VIEW_BID_OPTION_DROPDOWN,
  COLUMN_CAMPAIGNS_CPA_VALUE,
  COLUMN_CAMPAIGNS_ID,
  COLUMN_CAMPAIGNS_LOCALE_CODE,
  COLUMN_CAMPAIGNS_MIN_CPA_VALUES,
  COLUMN_CAMPAIGNS_PROPERTIES,
  COLUMN_CAMPAIGNS_TYPE,
  COLUMN_KEY_PROPERTIES_ALL,
  COLUMN_KEY_PROPERTIES_INACTIVE,
  COLUMN_KEY_PROPERTIES_INVISIBLE,
  COLUMN_KEY_PROPERTIES_NO_BID,
  COLUMN_POS,
  DATE_FORMAT_ISO_8601,
  GRID_VIEW_CONFIGURATION,
  MULTI_SELECT_CAMPAIGN_PREFIX,
  MULTI_SELECT_DROPDOWN_APPLY,
  MULTI_SELECT_DROPDOWN_OPEN,
  NotificationLevel,
  POS_GLOBAL_VALUE,
  TIMEOUT_NOTIFICATION,
} from '../../../constants';
import { BidFormContext } from '../../../features/bid-form/bid-form-context';
import { BidFormUnit, EURO } from '../../../features/bid-form/bid-form.types';
import { useBidForm } from '../../../features/bid-form/use-bid-form';
import { AppContext } from '../../../global/context/app-context';
import { BiddingResponse } from '../../../helpers/api/bidding/types';
import { roundPercentage } from '../../../helpers/formater';
import { ClickableCellProps, formatColumnsAndRows } from '../../../helpers/grid-formatter';
import {
  getCampaignFiltersFromLocalStorage,
  saveCampaignFiltersToLocalStorage,
} from '../../../helpers/local-storage-helper';
import { useExportPropertiesList } from '../../../hooks/use-export-properties-list';
import {
  Aggregation,
  BaseNotification,
  CampaignTypeCode,
  CPC_CAMPAIGN_CODE,
  FileFormat,
  getAllCampaignTypes,
  Row,
  View,
} from '../../../models';
import { getPartner, hasUserBiddingRights } from '../../../services/app-service';
import TrackerService from '../../../services/tracker/tracker-service';
import styles from './campaigns.module.css';
import { PROPERTY_LIST_TYPE_COLUMN_NAME, PropertyListType } from './campaigns.types';
import { RevertBidChanges } from './revert-bid-changes';
import { useBidFileUploaderOptions } from './use-bid-file-uploader-options';
import { Button } from '../../../core-ui/components/button/button';
import { Anchor } from '../../../core-ui/components/anchor';
import { ColumnBase, Grid, StyleRow } from '../../../components/grid';

export const Campaigns: FC = () => {
  const { partner, subPartners, user, locales } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [bidFormValue, setBidFormValue] = useState('');
  const [bidFormUnit, setBidFormUnit] = useState<BidFormUnit>(EURO);
  const [notification, setNotification] = useState<BaseNotification | undefined>();

  const [isActiveOnly, setIsActiveOnly] = useState(true);
  const hiddenCampaigns: CampaignTypeCode[] = ['CPA 99_beta'];
  const availableCampaigns = getAllCampaignTypes().filter(
    ({ code }) => !hiddenCampaigns.includes(code),
  );
  const selectedCampaignCodes: CampaignTypeCode[] =
    getCampaignFiltersFromLocalStorage(availableCampaigns);

  const dropdownOptions: CheckboxSelectOption<string>[] = availableCampaigns.map(({ code }) => ({
    id: code,
    key: code,
    value: code,
    label: code,
    checked: true,
  }));

  const [selectedOptions, setSelectedOptions] = useState<CheckboxSelectOption<string>[]>(
    selectedCampaignCodes.map(code => ({
      id: code,
      key: code,
      value: code,
      label: code,
      checked: selectedCampaignCodes.includes(code),
    })),
  );

  const clickApply = (options: CheckboxSelectOption<string>[]) => {
    setSelectedOptions(options);
    saveCampaignFiltersToLocalStorage(options.map(({ value }) => value));
  };

  const {
    table: {
      columns,
      data: { rows },
    },
    pagination: { pagination },
    resetData,
    isLoading,
    retry,
    selection: {
      selectedPages,
      selectedRowsById,
      selectRow,
      setSelectedPages,
      setSelectedRowsById,
    },
  } = useSimpleViewTable(
    View.CAMPAIGNS,
    {
      aggregation: Aggregation.CAMPAIGNS,
      localeCodes: [POS_GLOBAL_VALUE],
    },
    {
      columnId: COLUMN_CAMPAIGNS_ID,
      isUsingPagination: false,
    },
  );

  const { exportPropertiesList } = useExportPropertiesList(
    row => row[COLUMN_CAMPAIGNS_TYPE],
    row => row[COLUMN_CAMPAIGNS_LOCALE_CODE],
  );

  const propertyNumClickableCellProps = (
    propertyListType: PropertyListType,
  ): ClickableCellProps => ({
    action: (row: Row, fileFormat: FileFormat) =>
      exportPropertiesList(row, propertyListType, fileFormat),
    displayValue: (row: Row) => row[PROPERTY_LIST_TYPE_COLUMN_NAME[propertyListType]] || '-',
    fileDownloadProps: { fileFormats: [FileFormat.CSV, FileFormat.EXCEL] },
  });

  const row2Campaign = (row: Row) => {
    return {
      id: row.campaigns_id.toString(),
      cpaValue:
        row[COLUMN_CAMPAIGNS_CPA_VALUE] !== ''
          ? roundPercentage(row[COLUMN_CAMPAIGNS_CPA_VALUE])
          : undefined,
      minCpaValue: roundPercentage(row[COLUMN_CAMPAIGNS_MIN_CPA_VALUES]),
      type: availableCampaigns.find(type => type.code === row[COLUMN_CAMPAIGNS_TYPE])!,
      pos: locales.find(l => l.localeCode === row[COLUMN_CAMPAIGNS_LOCALE_CODE])!,
    };
  };

  const selectedCampaignRows = useMemo(
    () => Array.from(selectedRowsById.values()).map(row2Campaign),
    [selectedRowsById],
  );

  useEffect(() => {
    if (bidFormUnit === EURO) {
      if (selectedCampaignRows.length === 1) {
        setBidFormValue(selectedCampaignRows[0].cpaValue?.toString() || '');
      } else {
        setBidFormValue('');
      }
    }
  }, [selectedCampaignRows, bidFormUnit]);

  let { formattedColumns, formattedRows } = formatColumnsAndRows({
    columns,
    rows,
    total: undefined,
    id: COLUMN_CAMPAIGNS_ID,
    isShowingCheckbox: true,
    customColumns: {
      posLocale: {
        key: COLUMN_CAMPAIGNS_LOCALE_CODE,
        children: (data: { localeCode: string; name: string }) => {
          return (
            <LocaleColumn
              key={data.localeCode}
              pos={{
                localeCode: data.localeCode,
                name: data.name,
              }}
              styles={{
                font: 'u-color--juri u-font-size--tiny',
              }}
            />
          );
        },
      },
      liveBid: {
        key: COLUMN_CAMPAIGNS_CPA_VALUE,
        children: (value: number, row) => <CpaValueCell value={value} row={row} />,
      },
      columnCellClickActions: {
        [COLUMN_KEY_PROPERTIES_ALL]: propertyNumClickableCellProps(PropertyListType.ALL),
        [COLUMN_KEY_PROPERTIES_INACTIVE]: propertyNumClickableCellProps(PropertyListType.INACTIVE),
        [COLUMN_KEY_PROPERTIES_INVISIBLE]: propertyNumClickableCellProps(
          PropertyListType.INVISIBLE,
        ),
        [COLUMN_KEY_PROPERTIES_NO_BID]: propertyNumClickableCellProps(PropertyListType.NO_BID),
      },
    },
  });

  formattedColumns = formattedColumns.map(columnTmp => {
    (columnTmp as ColumnBase).isSortable = false;
    return columnTmp;
  });
  formattedRows = formattedRows.map(rowTmp => {
    const locale = locales.find(
      ({ localeCode }) => localeCode === rowTmp[COLUMN_CAMPAIGNS_LOCALE_CODE],
    );
    const localeCode = rowTmp[COLUMN_CAMPAIGNS_LOCALE_CODE];
    rowTmp[COLUMN_POS] = {
      localeCode: localeCode,
      name: locale !== undefined ? locale.name : localeCode,
    };
    rowTmp[COLUMN_CAMPAIGNS_LOCALE_CODE] = {
      localeCode: localeCode,
      name: locale !== undefined ? locale.name : localeCode,
    };
    return rowTmp;
  });

  const filterBySelectedTypes =
    <T extends Row>() =>
    (row: T) => {
      return selectedOptions.map(option => option.value).includes(row[COLUMN_CAMPAIGNS_TYPE]);
    };

  const filterByEmptyInventory =
    <T extends Row>() =>
    (row: T) => {
      return isActiveOnly ? +row[COLUMN_CAMPAIGNS_PROPERTIES] > 0 : true;
    };

  const filterByInactiveCampaigns = (row: Row) => {
    if (isActiveOnly) return row;

    return {
      ...row,
      [COLUMN_CAMPAIGNS_CPA_VALUE]:
        row[COLUMN_CAMPAIGNS_CPA_VALUE] === '' ? '-' : row[COLUMN_CAMPAIGNS_CPA_VALUE],
    };
  };

  const filteredRows = formattedRows
    .filter(filterByEmptyInventory())
    .filter(filterBySelectedTypes())
    .filter(row => filterListWithPosBySearchText(search)(row as any))
    .map(row => filterByInactiveCampaigns(row));

  const shouldShowCheckbox = (row: Row) => row[COLUMN_CAMPAIGNS_TYPE] !== CPC_CAMPAIGN_CODE;

  const onActiveOnlyToggle = () => {
    TrackerService.track(CAMPAIGNS_VIEW_ACTIVE_ONLY_TOGGLE(!isActiveOnly));
    setIsActiveOnly(!isActiveOnly);
  };

  const createExportOption = (optionText: string, fileFormat: FileFormat): ExportOption => {
    const dateCreated = format(new Date(), DATE_FORMAT_ISO_8601);
    const partnerId = getPartner(partner, subPartners).partnerId;
    const fileNameWithoutExtension = `${dateCreated}_${partnerId}_Campaigns`;
    return {
      text: optionText,
      fileFormat: fileFormat,
      payload: {
        filters: [
          {
            columnName: COLUMN_CAMPAIGNS_TYPE,
            columnLabel: 'Campaign Type',
            type: 'IN',
            value: selectedOptions.map(o => o.value).join(),
          },
        ],
        locales: [POS_GLOBAL_VALUE],
      },
      fileName: fileNameWithoutExtension,
    };
  };

  const bidFileUploaderOptions = useBidFileUploaderOptions();
  const handleBidFormUnitChange = (unit: BidFormUnit) => {
    setBidFormUnit(unit);
    TrackerService.track(CAMPAIGNS_VIEW_BID_OPTION_DROPDOWN, {
      option: translate(
        unit === EURO ? 'bid_form_cpc_set_to' : 'bid_form_cpc_change_by_percentage',
      ),
    });
  };
  return (
    <>
      <div className="u-padding-top--gutter u-padding-left--gutter u-padding-right--gutter">
        <h2 className="u-padding-left--gutter u-padding-right--gutter u-padding-bottom--gutter">
          {translate('bidding_campaigns__title')}
        </h2>
        <Toolbar
          filters={[
            <InputIcon
              key="key-campaign-input-icon"
              icon={search.length === 0 ? SearchLineDarkIcon : CloseIcon}
              className={cn('c-input', styles.searchField)}
              placeholder={translate('campaign_dashboard__search_placeholder')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onClickIcon={() => {
                if (search.length !== 0) {
                  setSearch('');
                }
              }}
              value={search}
            />,
            <IsVisibleToggle
              key="key-campaign-only-actives-filter"
              defaults={{
                isVisible: false,
                tooltip: { text: translate('campaign_only_actives_filter_tooltip') },
              }}
              onToggle={onActiveOnlyToggle}
            />,
            <CampaignsDropdownSelector
              options={dropdownOptions}
              clickApply={clickApply}
              selectedOptions={selectedOptions}
            />,
          ]}
          actions={[
            <ViewExporter
              key="key-view-exporter"
              title={translate('analytics_home_export_report')}
              exportOptions={[
                createExportOption(translate('campaigns_toolbar_export'), FileFormat.CSV),
                createExportOption(translate('campaigns_toolbar_export_excel'), FileFormat.EXCEL),
              ]}
              view={View.CAMPAIGNS}
              columns={columns}
              aggregation={Aggregation.CAMPAIGNS}
              filters={[]}
            />,
            <RevertBidChanges key="revert-bid-changes" />,
            hasUserBiddingRights(user) && renderFileUploaderDropdown(bidFileUploaderOptions),
          ]}
        />
      </div>

      <BidFormContext.Provider
        value={{
          value: bidFormValue,
          setValue: setBidFormValue,
          unit: bidFormUnit,
          setUnit: handleBidFormUnitChange,
          bidItems: selectedCampaignRows.map(campaign => ({
            id: campaign.id!,
            biddingType: campaign.type.value,
            name: `${campaign.pos.name} - ${campaign.type.code}`,
            baseValue: Number(campaign.cpaValue),
            minValue: campaign.minCpaValue,
            capValue: 100,
            localeCode: campaign.pos.localeCode,
          })),
        }}
      >
        {selectedRowsById.size > 0 && (
          <ControlPanel
            campaigns={selectedCampaignRows}
            onClose={() => {
              setSelectedRowsById(new Map());
              setSelectedPages(new Set());
            }}
            onApply={result => {
              resetData();
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setNotification(buildNotification(result));
              setTimeout(() => setNotification(undefined), TIMEOUT_NOTIFICATION);
            }}
          />
        )}
        {notification && (
          <Notification notification={notification} onClose={() => setNotification(undefined)} />
        )}
        <div className={styles.gridWrapper}>
          <Grid
            columns={formattedColumns}
            numColumnsLeftAlignment={3}
            rows={filteredRows}
            initialData={{
              initialSelectedRowsId: new Set(selectedRowsById.keys()),
              initialSelectedPages: selectedPages,
            }}
            selectableRow={{
              onSelectPage: selectedPagesParam => setSelectedPages(selectedPagesParam),
              onSelectRow: selectRow,
              conditionFn: shouldShowCheckbox,
            }}
            pagination={{
              page: pagination,
              isHidden: true,
            }}
            isLoading={isLoading}
            configuration={GRID_VIEW_CONFIGURATION}
            resultsNotFound={<ResultsNotFound retry={retry} />}
            rowStyles={[
              {
                style: StyleRow.LARGE,
              },
            ]}
          />
        </div>
      </BidFormContext.Provider>
    </>
  );
};

function buildNotification(biddingResult: BiddingResponse): BaseNotification {
  const { totalBids, updatedBids } = biddingResult;
  const notification: BaseNotification = {
    level: NotificationLevel.ERROR,
    message: 'analytics_perform_bids_error',
  };
  if (updatedBids === 0) {
    notification.level = NotificationLevel.ERROR;
    notification.message = `${translate('common_fail')} ${translate(
      'analytics_perform_bids_error',
    )}`;
  } else if (updatedBids === totalBids) {
    notification.level = NotificationLevel.SUCCESS;
    const keyCampaign = totalBids === 1 ? 'common_campaign' : 'common_campaigns';
    notification.message = `${translate('common_success')} ${translate(
      'analytics_perform_bids_success',
    )} ${totalBids} ${translate(keyCampaign)}. ${translate(
      'analytics_perform_bids_success_extra_info',
    )}`;
  } else {
    notification.level = NotificationLevel.WARNING;
    notification.message = `${translate('notification_partial_success')} ${translate(
      'analytics_perform_bids_partial_success',
    )} ${updatedBids} ${translate('common_of_the')} ${totalBids} ${translate(
      'common_selected_campaigns',
    )} ${translate('analytics_check_status')}`;
  }
  return notification;
}

const renderFileUploaderDropdown = (bidFileUploaderOptions: FileUploaderOption[]) =>
  renderOnboardingTooltip(
    <ButtonDropdownFileUploader key="upload-bids-key" options={bidFileUploaderOptions} />,
  );

const renderOnboardingTooltip = (children: ReactNode) => (
  <div style={{ position: 'relative' }}>{children}</div>
);

const CampaignsDropdownSelector = ({
  options,
  clickApply,
  selectedOptions,
}: {
  options: CheckboxSelectOption<string>[];
  clickApply: (options: CheckboxSelectOption<string>[]) => void;
  selectedOptions: CheckboxSelectOption<string>[];
}) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const biddingTitle =
    selectedOptions.length === 1
      ? selectedOptions[0].value
      : `${selectedOptions.length} ${translate('campaign_groups_dropdown_multiple_selected')}`;

  const handleApply = (checkedOptions: CheckboxSelectOption<string>[]) => {
    const filteredOptions = checkedOptions.filter(({ checked }) => checked);
    clickApply(filteredOptions);
    setAnchor(null);
    TrackerService.trackWithPrefix(MULTI_SELECT_CAMPAIGN_PREFIX, MULTI_SELECT_DROPDOWN_APPLY, {
      selectedOptions: filteredOptions.map(option => option.value),
    });
  };

  return (
    <>
      <Button
        variant="tertiary"
        text={biddingTitle}
        onClick={event => {
          setAnchor(event.currentTarget as HTMLElement);
          TrackerService.trackWithPrefix(MULTI_SELECT_CAMPAIGN_PREFIX, MULTI_SELECT_DROPDOWN_OPEN);
        }}
        icon={{ name: 'arrowDown', rotation: anchor ? 180 : 0 }}
      />
      <TooltipWrapper
        position={TooltipPosition.TOP_CENTER}
        noTinyPaddingTop={true}
        message={translate('analytics_bidding_type_dropdown_information_tooltip')}
      >
        <ExclamationCircleFillDarkIcon
          className={styles.biddingTypeTooltip}
          width={14}
          height={14}
        />
      </TooltipWrapper>
      {anchor && (
        <Anchor anchor={anchor} onClose={() => setAnchor(null)}>
          <CheckboxSelect
            options={options.map(option => ({
              ...option,
              checked: selectedOptions.some(({ value }) => value === option.value),
            }))}
            onApply={handleApply}
          />
        </Anchor>
      )}
    </>
  );
};

const CpaValueCell: FC<{ value: number | string; row: Row }> = ({ value, row }) => {
  const { newValue, hasWarning } = useBidChangeFor(row.id);
  if (newValue === undefined) {
    const val = isNumber(value) ? `${roundPercentage(Number(value))}%` : '-';
    return <span>{val}</span>;
  }
  const newCurrent = isNumber(value) ? `${roundPercentage(Number(value))} % ` : '-';
  return (
    <span>
      {newCurrent} to{' '}
      <b className={cn({ [styles.cpaInvalidValue]: hasWarning })}>{roundPercentage(newValue)} %</b>
    </span>
  );
};

function useBidChangeFor(id: string | number): {
  newValue: number | undefined;
  hasWarning: boolean;
} {
  const { adjustedBidItems, isFormInvalid } = useBidForm(false);
  const item = adjustedBidItems.find(it => it.id === id);
  const hasWarning = item ? item.isBelow || item.isExceeded : false;
  const isValueInvalid =
    !item || (!isNumber(item.acceptedValue) && (isFormInvalid || !item.acceptedValue));
  if (isValueInvalid) {
    return { newValue: undefined, hasWarning };
  }

  const newValue = item!.acceptedValue / 100;
  return { newValue, hasWarning };
}
