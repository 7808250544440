/* eslint-disable @typescript-eslint/naming-convention */

import {
  ANALYTICS_ROUTE,
  CAMPAIGNS_ROUTE,
  CHANGE_BID_ON_BOARDING,
  DASHBOARD_ROUTE,
  QUICK_DATA_EXPLORATION_ON_BOARDING,
  SPONSORED_ANALYTICS_ROUTE,
} from '.';

const createTourSteps = (
  prefix: string,
  title: string,
  steps: number,
  trackingFeatureName: string,
) => {
  const tourSteps: Record<string, string> = {};
  for (let i = 1; i <= steps; i++) {
    tourSteps[`STEP_${i}`] = `${prefix}-step-${i}`;
  }
  tourSteps.title = title;
  tourSteps.trackingFeatureName = trackingFeatureName;
  return tourSteps;
};

export const ONBOARDING_TOUR_ROUTES: Record<string, string[]> = {
  [QUICK_DATA_EXPLORATION_ON_BOARDING]: [DASHBOARD_ROUTE],
  [CHANGE_BID_ON_BOARDING]: [ANALYTICS_ROUTE, CAMPAIGNS_ROUTE, SPONSORED_ANALYTICS_ROUTE],
};

export const ONBOARDING_TOURS = {
  QUICK_DATA_EXPLORATION: createTourSteps(
    'quick-data-exploration',
    'Examine performance',
    4,
    'Quick data exploration on yesterday’s overview',
  ),
  CHANGE_BID: createTourSteps('change-bid-cpc', 'Adjust bidding by percentage', 3, 'Change bid'),
};
