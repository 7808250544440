import { FC, HTMLAttributes, useState } from 'react';
import { ButtonIcon } from '../button-icon/button-icon';
import { AnchoredMenu } from '../menu/anchored-menu';
import { AnchorObject, MenuItem } from '../menu/menu';
import sharedStyles from './shared.module.css';
import styles from './grid-cell-context-menu.module.css';
import { Tooltip, TooltipPosition } from '../tooltip/tooltip';
import cn from 'classnames';
import { GridContextMenu } from '../../../models';
import { GridValue } from '../../../components/grid/grid-cell/grid-cell.types';
import { ONBOARDING_TOURS } from '../../../constants/onboarding-targets';

type GridCellContextMenuProps = {
  gridCell: JSX.Element | string | null;
  menuItems: GridContextMenu[];
  rowId: string | number;
  tooltipTitle?: string;
  menuTitle?: string;
  value: GridValue;
  applyMenuValue: (value: GridValue, menu: GridContextMenu | undefined) => void;
  tooltipPosition?: TooltipPosition;
  handleMenuClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const GridCellContextMenu: FC<GridCellContextMenuProps> = ({
  gridCell,
  menuItems,
  rowId,
  tooltipTitle,
  menuTitle,
  value,
  applyMenuValue,
  tooltipPosition,
  handleMenuClick,
}) => {
  const [anchor, setAnchor] = useState<AnchorObject>({
    anchor: null,
    id: null,
  });

  if (!gridCell && gridCell !== '') return null;

  const anchorMenuItems: MenuItem[] = menuItems.map((item, index) => ({
    id: index,
    label: item.menu,
    onClick: () => {
      applyMenuValue(value, item);
    },
    nestedMenu: item.nestedMenu
      ? item.nestedMenu.map((nestedItem, nestedIndex) => ({
          id: nestedIndex,
          label: nestedItem.menu,
          onClick: () => {
            applyMenuValue(value, nestedItem);
          },
        }))
      : undefined,
  }));

  return (
    <>
      <span className={cn(styles.contextMenuGridCell)}>
        {gridCell}
        <span
          onClick={e => setAnchor({ anchor: e.currentTarget as HTMLElement, id: rowId })}
          className={cn(sharedStyles.showOnHover, {
            [sharedStyles.activeShowOnHover]: anchor.id === rowId,
          })}
        >
          <div style={{ position: 'relative' }}>
            <Tooltip
              position={tooltipPosition ?? 'rightCenter'}
              title={tooltipTitle ?? ''}
              isDelayed
            >
              <ButtonIcon
                icon={{ name: 'kebabMenu', width: 18, height: 18 }}
                onClick={e => {
                  setAnchor({ anchor: e.currentTarget as HTMLElement, id: rowId });
                  handleMenuClick && handleMenuClick();
                }}
                title=""
                className={cn(styles.contextMenuButton, {
                  [styles.contextMenuButtonActive]: anchor.id === rowId,
                })}
                data-tour={ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.STEP_1}
              />
            </Tooltip>
          </div>
        </span>
      </span>
      {anchor && anchor.id === rowId && (
        <AnchoredMenu
          anchor={anchor.anchor as HTMLElement}
          onClose={() => setAnchor({ anchor: null, id: null })}
          items={anchorMenuItems}
          title={menuTitle ?? ''}
          style={{ width: 200 }}
          data-tour={ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.STEP_2}
        />
      )}
    </>
  );
};
