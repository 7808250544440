import { FC } from 'react';
import { ModalDialog } from '../../core-ui/components/modal-dialog/modal-dialog';
import { useIntermediateDialog } from '../../core-ui/components/modal-dialog/use-intermediate-dialog';
import { translate } from '../../helpers/utils';
import styles from './bid-form.module.css';

type ApplyBidsConfirmDialogProps = {
  itemsCount: number;
  isCPC: boolean;
  bidItemName?: string;
  onConfirm: (value: boolean) => void;
};

export const useApplyBidsConfirmDialog = () => {
  return useIntermediateDialog<boolean, Omit<ApplyBidsConfirmDialogProps, 'onConfirm'>>(
    ({ data, resolve }) => {
      if (!data) return null;

      return (
        <ApplyBidConfirmDialog
          itemsCount={data.itemsCount}
          bidItemName={data.bidItemName}
          isCPC={data.isCPC}
          onConfirm={resolve}
        />
      );
    },
  );
};

const ApplyBidConfirmDialog: FC<ApplyBidsConfirmDialogProps> = ({
  itemsCount,
  isCPC,
  bidItemName,
  onConfirm,
}) => {
  return (
    <ModalDialog
      className={styles.confirmDialog}
      open
      hasCloseButton
      onClose={() => onConfirm(false)}
      title={translate(isCPC ? 'confirm_bid_update' : 'apply_cpa_value_changes')}
      buttons={{
        end: [
          { text: 'Cancel', variant: 'tertiary', onClick: () => onConfirm(false) },
          { text: 'Apply', variant: 'primary', onClick: () => onConfirm(true) },
        ],
      }}
    >
      <span className={styles.confirmDialogDesc}>
        {itemsCount > 1
          ? translate(
              isCPC ? 'confirm_cpc_bid_update_multiple' : 'confirm_cpa_bid_update_multiple',
              String(itemsCount),
            )
          : translate(
              isCPC ? 'confirm_cpc_bid_update' : 'confirm_cpa_bid_update',
              bidItemName || '',
            )}
      </span>
    </ModalDialog>
  );
};
