import { createRef, FC, useContext, useEffect, useRef, useState } from 'react';
import './navigation.css';
import { NavLink } from 'react-router-dom';
import {
  DASHBOARD_ROUTE,
  ANALYTICS_ROUTE,
  CAMPAIGNS_ROUTE,
  REPORTS_ROUTE,
  SPONSORED_ROUTE,
  HISTORY_ROUTE,
  MENU,
  SPONSORED_ANALYTICS_ROUTE,
  SPONSORED_CAMPAIGNS_ROUTE,
  ADMIN_ROUTE,
} from '../../../../constants';
import {
  ENTERED_DASHBOARD_NAME,
  ENTERED_ANALYTICS_NAME,
  ENTERED_CAMPAIGN_NAME,
  ENTERED_REPORTS_NAME,
  ENTERED_SPONSORED_NAME,
  ENTERED_HISTORY_NAME,
  ENTERED_SPONSORED_ANALYTICS_NAME,
  ENTERED_SPONSORED_CAMPAIGNS_NAME,
  ENTERED_ADMIN_NAME,
} from '../../../../services/tracker/customer-actions';
import TrackerService from '../../../../services/tracker/tracker-service';
import { OnboardingTooltip } from '../../../../components/onboarding-tooltip';
import {
  isFeatureEnabledForUser,
  isDeveloper,
  translate,
  translateHtml,
  TranslationKey,
} from '../../../../helpers/utils';
import cn from 'classnames';
import { AppContext } from '../../../context/app-context';
import { fromTo, MenuDropdownItemOption, MenuItemOption } from '../../../../models';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { Menu } from '../../../../components/menu';
import { findMenuOptions } from '../../../../services/app-service';
import { PopoverDirection } from '../../../../components/popover';
import { useLocation } from 'react-router-dom';

export const Navigation: FC = () => {
  const location = useLocation();
  const { user } = useContext(AppContext);

  const ref = createRef<HTMLUListElement>();
  const [indexOpenDropdown, setIndexOpenDropdown] = useState(-1);

  useOnClickOutside(ref, () => setIndexOpenDropdown(-1));

  const previousPath = useRef<string>();

  useEffect(() => {
    if (previousPath !== undefined) {
      trackCustomerAction();
    }
    setIndexOpenDropdown(-1);
    previousPath.current = location.pathname;
  }, [location.pathname, user]);

  const clickDropdownItem = (index: number, customerAction: string) => {
    TrackerService.track(customerAction);
    setIndexOpenDropdown(index === indexOpenDropdown ? -1 : index);
  };

  const trackCustomerAction = () => {
    TrackerService.track(TRACK_CUSTOMER_ACTION[location.pathname], {
      previous_tab: previousPath.current,
    });
  };

  const { id } = findMenuOptions(location.pathname);

  function isMenuItemShown(item: MenuItemOption | MenuDropdownItemOption): boolean {
    if ((item as MenuItemOption).hidden === true) return false;
    return isFeatureEnabledForUser(item.right, user) || isDeveloper(user.partnerLevel);
  }

  return (
    <ul ref={ref} className="c-navigation u-display--flex u-font-weight--bold  u-color--juri-light">
      {MENU.filter(isMenuItemShown).map((menuItem, i) => {
        return (
          <div
            key={menuItem.id}
            className={cn('u-position--relative', {
              'u-background--blue-lightest': !isFeatureEnabledForUser(menuItem.right, user),
            })}
          >
            {'link' in menuItem ? (
              <NavLink
                data-qa={menuItem.id}
                key={menuItem.name}
                className={({ isActive }) =>
                  cn('u-color--juri-light', {
                    'c-navigation__active u-font-weight--bold u-cursor--pointer': isActive,
                  })
                }
                to={menuItem.link}
                title={translate(menuItem.name as TranslationKey)}
              >
                <li
                  className={cn('u-padding-horizontal--s-large c-navigation__item ', {
                    'u-border-bottom--triple u-border--juri': id === menuItem.id,
                  })}
                >
                  {translate(menuItem.name as TranslationKey)}
                </li>
              </NavLink>
            ) : (
              <>
                <li
                  className={cn(
                    'u-padding-horizontal--s-large c-navigation__item u-cursor--pointer',
                    {
                      'c-navigation__active u-font-weight--bold u-border-bottom--triple u-border--juri':
                        id === menuItem.id,
                      'c-navigation__active-hover': i === indexOpenDropdown,
                    },
                  )}
                  onClick={() => clickDropdownItem(i, menuItem.customerAction)}
                >
                  {translate(menuItem.name as TranslationKey)}
                </li>
                {i === indexOpenDropdown && (
                  <Menu
                    body={{
                      items: menuItem.dropdown.filter(isMenuItemShown).map(subMenuItem => {
                        return (
                          <NavLink
                            data-qa={subMenuItem.id}
                            key={subMenuItem.name}
                            className={({ isActive }) =>
                              cn(
                                {
                                  'u-background--blue-lightest': !isFeatureEnabledForUser(
                                    subMenuItem.right,
                                    user,
                                  ),
                                  'c-navigation__active u-font-weight--bold u-cursor--pointer':
                                    isActive,
                                },
                                'u-color--juri-light u-width--100',
                              )
                            }
                            to={subMenuItem.link as string}
                            title={translate(subMenuItem.name as TranslationKey)}
                          >
                            {translate(subMenuItem.name as TranslationKey)}
                          </NavLink>
                        );
                      }),
                    }}
                    onSelectItem={() => ({})}
                  />
                )}
              </>
            )}

            {menuItem.onBoarding && (
              <div className="c-navigation__item-on-boarding-tooltip">
                <OnboardingTooltip
                  keyStorage={menuItem.onBoarding.storageKey}
                  direction={PopoverDirection.BOTTOM_LEADING}
                >
                  {translateHtml(menuItem.onBoarding.translationKey)}
                </OnboardingTooltip>
              </div>
            )}
          </div>
        );
      })}
    </ul>
  );
};

const TRACK_CUSTOMER_ACTION: fromTo<string, string> = {
  [DASHBOARD_ROUTE]: ENTERED_DASHBOARD_NAME,
  [ANALYTICS_ROUTE]: ENTERED_ANALYTICS_NAME,
  [CAMPAIGNS_ROUTE]: ENTERED_CAMPAIGN_NAME,
  [REPORTS_ROUTE]: ENTERED_REPORTS_NAME,
  [SPONSORED_ROUTE]: ENTERED_SPONSORED_NAME,
  [SPONSORED_ANALYTICS_ROUTE]: ENTERED_SPONSORED_ANALYTICS_NAME,
  [SPONSORED_CAMPAIGNS_ROUTE]: ENTERED_SPONSORED_CAMPAIGNS_NAME,
  [HISTORY_ROUTE]: ENTERED_HISTORY_NAME,
  [ADMIN_ROUTE]: ENTERED_ADMIN_NAME,
};
