/* eslint-disable @typescript-eslint/naming-convention */

import { ReactNode } from 'react';
import { Styles } from 'react-joyride';
import {
  ANALYTICS_ROUTE,
  CAMPAIGNS_ROUTE,
  CHANGE_BID_ON_BOARDING,
  DASHBOARD_ROUTE,
  QUICK_DATA_EXPLORATION_ON_BOARDING,
  SPONSORED_ANALYTICS_ROUTE,
} from '.';
import { getContent } from '../core-ui/components/onboarding-tour/index';

type Step = {
  target: string;
  content: ReactNode;
  placement: string;
  disableBeacon?: boolean;
  disableSkipButton?: boolean;
  styles?: Partial<Styles>;
  placementBeacon?: string;
  trackingFeatureName?: string;
  offset?: number;
};

const createStep = ({
  tour,
  step,
  content,
  disableBeacon,
  placement,
  placementBeacon,
  trackingFeatureName,
  styles,
}: {
  tour: { [key: string]: string | string[] };
  step: string;
  content: ReactNode;
  disableBeacon?: boolean;
  placement?: string;
  styles?: Partial<Styles>;
  placementBeacon?: string;
  trackingFeatureName: string;
}): Step => ({
  target: `[data-tour=${tour[step]}]`,
  content,
  disableBeacon: disableBeacon,
  placement: placement ?? 'top',
  placementBeacon,
  trackingFeatureName,
  styles,
});

const createTourSteps = (
  prefix: string,
  title: string,
  steps: number,
  trackingFeatureName: string,
) => {
  const tourSteps: Record<string, string> = {};
  for (let i = 1; i <= steps; i++) {
    tourSteps[`STEP_${i}`] = `${prefix}-step-${i}`;
  }
  tourSteps.title = title;
  tourSteps.trackingFeatureName = trackingFeatureName;
  return tourSteps;
};

export const ONBOARDING_TOUR_ROUTES: Record<string, string[]> = {
  [QUICK_DATA_EXPLORATION_ON_BOARDING]: [DASHBOARD_ROUTE],
  [CHANGE_BID_ON_BOARDING]: [ANALYTICS_ROUTE, CAMPAIGNS_ROUTE, SPONSORED_ANALYTICS_ROUTE],
};

export const ONBOARDING_TOURS = {
  QUICK_DATA_EXPLORATION: createTourSteps(
    'quick-data-exploration',
    'Examine performance',
    4,
    'Quick data exploration on yesterday’s overview',
  ),
  CHANGE_BID: createTourSteps('change-bid-cpc', 'Adjust bidding by percentage', 3, 'Change bid'),
};

const quickDataExploration = {
  STEP_1: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_1',
    disableBeacon: false,
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: 'You can now examine the performance of any metric by clicking the "More options" icon next to it.',
    }),
  }),
  STEP_2: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_2',
    disableBeacon: true,
    styles: { spotlight: { width: 420 } },
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: 'To dive deeper, select the related views to see performance details for the highest and lowest values or time periods.',
    }),
  }),
  STEP_3: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_3',
    disableBeacon: true,
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: 'Your selected metrics are now sorted from High to Low or Low to High, for easier comparison.',
    }),
  }),
  STEP_4: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_4',
    disableBeacon: true,
    placement: 'left',
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: `Toggle the 'Average Metric' switch to view data as averages or using the default formulas.`,
    }),
  }),
};

const changeBid = {
  STEP_1: createStep({
    tour: ONBOARDING_TOURS.CHANGE_BID,
    step: 'STEP_1',
    disableBeacon: false,
    placementBeacon: 'left',
    placement: 'left-start',
    trackingFeatureName: ONBOARDING_TOURS.CHANGE_BID.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.CHANGE_BID.title,
      body: 'Choose to input a specific value or adjust by percentage for flexible bid control',
    }),
    styles: {
      beacon: {
        transform: 'translateX(-15px) translateY(-1px)',
      },
    },
  }),
  STEP_2: createStep({
    tour: ONBOARDING_TOURS.CHANGE_BID,
    step: 'STEP_2',
    disableBeacon: true,
    placement: 'left-end',
    trackingFeatureName: ONBOARDING_TOURS.CHANGE_BID.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.CHANGE_BID.title,
      body: 'Set a direct value or apply a percentage to quickly update multiple bids at once.',
    }),
  }),
  STEP_3: createStep({
    tour: ONBOARDING_TOURS.CHANGE_BID,
    step: 'STEP_3',
    disableBeacon: true,
    placement: 'left-start',
    trackingFeatureName: ONBOARDING_TOURS.CHANGE_BID.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.CHANGE_BID.title,
      body: 'Preview bid updates in real-time to ensure optimal adjustments.',
    }),
  }),
};

export const ONBOARDING_TARGETS = {
  QUICK_DATA_EXPLORATION: quickDataExploration,
  CHANGE_BID: changeBid,
};
