import { useNavigate, useLocation } from 'react-router-dom';
import { QUERY_PARAM_ACTIVE_TAB } from '../../constants';
import { useQueryParam } from '../use-query-param';

export const IS_DATE_COMPARISON_ENABLED = 'isDateComparisonEnabled';

const paramKeyMap = {
  sortBy: 'columnKey',
  orderBy: 'direction',
  isDateComparisonEnabled: IS_DATE_COMPARISON_ENABLED,
  period: 'period',
};

export const useSortParams = (keys: (keyof typeof paramKeyMap)[]) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { allParams: allParamsArray }: { allParams: [string, string][] } =
    useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  const allParams = allParamsArray.reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<string, string>,
  );

  const { columnKey, direction, isDateComparisonEnabled, period } = keys.reduce(
    (acc, key) => {
      const paramKey = paramKeyMap[key];
      if (paramKey) {
        acc[paramKey] = allParams[key];
      }
      return acc;
    },
    {} as Record<string, string>,
  );

  const isDateComparisonActive = isDateComparisonEnabled === 'true';

  const params = new URLSearchParams(search);

  const setSortParams = (paramKeys: Record<string, string>) => {
    Object.entries(paramKeys).forEach(([key, value]) => params.set(key, value));
    navigate({ pathname, search: params.toString() });
  };
  const removeSortParams = (paramKeys: string[]) => {
    paramKeys.forEach(key => params.delete(key));
    navigate({ pathname, search: params.toString() });
  };

  return { isDateComparisonActive, columnKey, direction, period, setSortParams, removeSortParams };
};
