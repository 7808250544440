import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import {
  GLOBAL_INVENTORY_DOWNLOAD,
  NotificationLevel,
  REACT_QUERY_RETRY_PREDICATE,
  REACT_QUERY_STALE_TIME,
  ReactQueryKeys,
} from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { TranslationKey } from '../../../helpers/utils';
import { getPartnersId } from '../../../services/app-service';
import { showNotification } from '../../../services/notification-service';
import ReportService from '../../../services/report-service';
import TrackerService from '../../../services/tracker/tracker-service';
import ApiInventoryStatsHelper, { ApiInventoryStats } from './api-inventory-stats-helper';

type GlobalInventoryMetric = {
  count: number;
  downloadReport?: VoidFunction;
  label: string;
  tooltip?: TranslationKey;
};

type UseGlobalInventoryResult = {
  data: {
    metrics: GlobalInventoryMetric[];
  };
  isLoading: boolean;
  error: Error | null;
  refetch: VoidFunction;
  isDownloadingReport: boolean;
};

export function useGlobalInventory(): UseGlobalInventoryResult {
  const { partner, subPartners } = useContext(AppContext);
  const partnerIds = getPartnersId(partner, subPartners);

  const {
    data: stats,
    isLoading: isLoadingStats,
    isError: isStatsError,
    refetch: refetchStats,
  } = useQuery({
    queryKey: [ReactQueryKeys.INVENTORY_STATS, partnerIds.join()],
    queryFn: () => ApiInventoryStatsHelper.getInventoryStats(partnerIds),
    staleTime: REACT_QUERY_STALE_TIME,
    retry: REACT_QUERY_RETRY_PREDICATE,
  });

  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  async function downloadReport(downloadReportFn: () => Promise<void>) {
    try {
      setIsDownloadingReport(true);
      await downloadReportFn();
    } catch (e) {
      showNotification({
        message: (e as any).message,
        level: NotificationLevel.ERROR,
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }

  const downloadByReportName = (key: keyof ApiInventoryStats, name: string): Promise<void> => {
    TrackerService.track(GLOBAL_INVENTORY_DOWNLOAD, { report_name: name });
    return ReportService.triggerInventoryByType(key, partnerIds);
  };

  return {
    data: {
      metrics: [
        {
          label: 'Active matched',
          tooltip: 'analytics_tooltip_active_matched',
          count: sum(stats, 'active_matched'),
          downloadReport: () =>
            downloadReport(() => downloadByReportName('active_matched', 'Active matched')),
        },
        {
          label: 'Missing matches',
          count: sum(stats, 'missing_matches'),
          tooltip: 'dashboard_global_inventory_missing_matches_tooltip',
          downloadReport: () =>
            downloadReport(() => downloadByReportName('missing_matches', 'Missing matches')),
        },
        {
          label: 'Duplicate matches',
          tooltip: 'dashboard_global_inventory_duplicate_items_tooltip',
          count: sum(stats, 'duplicate_matches'),
          downloadReport: () =>
            downloadReport(() => downloadByReportName('duplicate_matches', 'Duplicate matches')),
        },
        {
          label: 'Active unmatched',
          tooltip: 'analytics_tooltip_active_unmatched',
          count: sum(stats, 'active_unmatched'),
          downloadReport: () =>
            downloadReport(() => downloadByReportName('active_unmatched', 'Active unmatched')),
        },
      ],
    },
    isLoading: isLoadingStats,
    error: isStatsError ? new Error('Unable to load global inventory') : null,
    refetch() {
      refetchStats();
    },
    isDownloadingReport,
  };
}

function sum(data: any[] | undefined, key: keyof ApiInventoryStats): number {
  if (!data) return 0;
  let total = 0;
  for (const el of data) {
    const value = el[key];
    if (!value) continue;
    total += value;
  }
  return total;
}
