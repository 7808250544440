import cs from 'classnames';
import { FC, PropsWithChildren } from 'react';
import './wrapper-error-hint.css';
import { Props } from './wrapper-error-hint.types';

export const WrapperErrorHint: FC<Props & PropsWithChildren> = ({
  message,
  isShown,
  extraClasses = '',
  children,
}) => {
  return (
    <div className="u-position--relative">
      {children}
      {isShown && <span className={cs('c-wrapper-error-hint', extraClasses)}>{message}</span>}
    </div>
  );
};
