import { createRef, CSSProperties, FC, RefObject, useRef, useState } from 'react';
import cn from 'classnames';
import DayPicker from 'react-day-picker';
import { WEEKDAYS_SHORT, formattedDate, translate } from '../../helpers/utils';
import './date-picker-with-compare.css';
import { DatePickerWithCompareProps } from './date-picker.types';
import { Switch } from '../switch';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { useDatePickerStyle } from './use-date-picker-style';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { useDatePicker } from './use-date-picker';
import { MAX_COMPARE_DATE_PERIODS, useDatePickerWithCompare } from './use-date-picker-with-compare';
import { CompareDateRangeInputs } from './compare-date-range-inputs';
import { DateRangePeriod } from '../../models';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import { isBefore } from 'date-fns';
import TrackerService from '../../services/tracker/tracker-service';
import { DATE_PICKER_COMPARE_TOGGLE } from '../../constants';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { DatePeriod } from '../../models';
import { isAfter, isValid } from 'date-fns';
import { Button } from '../../core-ui/components/button/button';
import { IS_DATE_COMPARISON_ENABLED, useSortParams } from '../../hooks/use-sort-params';

export const DatePickerWithCompare: FC<DatePickerWithCompareProps> = ({
  onClose,
  isPeriodChoosable,
  isTodayChoosable,
  isComparingPreselected = false,
}) => {
  const datePickerRef: RefObject<HTMLElement> = createRef();
  const dropdown = useRef(document.querySelector('.js-calendar') as HTMLElement);
  const currentView = useCurrentView();
  useOnClickOutside([datePickerRef, dropdown], onClose);
  const { isDateComparisonActive, removeSortParams } = useSortParams([IS_DATE_COMPARISON_ENABLED]);

  const {
    from,
    to,
    period,
    fromInput,
    toInput,
    isToInputFocus,
    isFromInputValid,
    isToInputValid,
    modifiers,
    isCustomPeriod,
    isFromInputFocus,
    onApply,
    onChangeSelectHandler,
    handleDayClick,
    setPeriod,
    getLastSelectableDay,
    onFromInputBlur,
    onToInputBlur,
    onChangeFromInput,
    onChangeToInput,
    onFromInputFocus,
    onToInputFocus,
  } = useDatePicker({ onClose, isPeriodChoosable, isTodayChoosable });

  const [isComparingPendingValue, setIsComparingPendingValue] = useState(isComparingPreselected);

  const {
    compareColors,
    isCompareEnabled,
    compareDateRangePeriods,
    selectedDatePeriodIndex,
    selectedDatePeriod,
    compareModifiers,
    currentSelectionColor,
    setCompareValue,
    setSelectedDatePeriodIndex,
    setIsComparing,
    handleCompareDayClick,
    addNewCompareDateRangePeriod,
    removeCompareDateRangePeriod,
    onCompare,
  } = useDatePickerWithCompare({
    from,
    to,
    period,
    setPeriod,
    onClose,
    getLastSelectableDay,
    isComparingPendingValue,
    setIsComparingPendingValue,
  });

  const { setOtherDatePeriodsStyle } = useDatePickerStyle({
    isComparing: isComparingPendingValue,
    compareDateRangePeriods,
    compareModifiers,
    selectedDatePeriodIndex,
  });

  const [hoverValue, setHoverValue] = useState<string | undefined>(undefined);

  const handleMouseEnter = (date: Date) => {
    const lastSelectableDay = getLastSelectableDay();
    if (isBefore(date, lastSelectableDay)) {
      setHoverValue(formattedDate(date));
    }
  };

  const handleMouseLeave = () => setHoverValue(undefined);

  const handleToggle = (isActive: boolean) => {
    if (!isComparingPendingValue) {
      TrackerService.track(DATE_PICKER_COMPARE_TOGGLE, {
        fromView: currentView,
      });
    }
    setIsComparingPendingValue(isActive);
    if (!isActive && isDateComparisonActive) removeSortParams([IS_DATE_COMPARISON_ENABLED]);
  };

  const areDatesValid = compareDateRangePeriods.every(
    dateRangePeriod =>
      dateRangePeriod.from &&
      isValid(dateRangePeriod.from) &&
      dateRangePeriod.to &&
      isValid(dateRangePeriod.to) &&
      !isAfter(dateRangePeriod.from, dateRangePeriod.to),
  );
  const isCompareInputInvalid = isCustomPeriod
    ? !isFromInputValid || !isToInputValid || !compareDateRangePeriods.length || !areDatesValid
    : false;

  return (
    <section
      className="c-datePicker u-background--white u-box-shadow u-border-radius u-display--flex u-flex-direction--column"
      ref={datePickerRef}
      style={
        {
          left: 16,
          '--selected-color': currentSelectionColor?.primary,
          '--selected-color-light': currentSelectionColor?.secondary,
        } as CSSProperties
      }
    >
      <div className="u-display--flex">
        <DayPicker
          className={cn(
            { Selectable: !isComparingPendingValue },
            'DatePickerWithCompare u-background--white',
          )}
          disabledDays={date => {
            const limitDate: Date = getLastSelectableDay();
            date.setHours(0, 0, 0);
            return date > limitDate;
          }}
          fixedWeeks={false}
          toMonth={getLastSelectableDay()}
          numberOfMonths={2}
          selectedDays={selectedDatePeriod as DateRangePeriod}
          modifiers={isComparingPendingValue ? compareModifiers : modifiers}
          modifiersStyles={{
            selectedDatePeriod: {
              borderRadius: '0px',
            },
            otherDatePeriods: {
              backgroundColor: '#EBECED',
            },
          }}
          onDayClick={selectedDatePeriodIndex !== null ? handleCompareDayClick : handleDayClick}
          onDayMouseEnter={handleMouseEnter}
          onDayMouseLeave={handleMouseLeave}
          showOutsideDays={true}
          firstDayOfWeek={1}
          weekdaysShort={WEEKDAYS_SHORT}
          onMonthChange={() => setOtherDatePeriodsStyle()}
        />
        <article
          className="u-padding--large u-display--flex u-flex-direction--column u-flex-grow--1"
          style={{ minWidth: '320px', borderLeft: '1px solid #EBECED', width: '340px' }}
        >
          <label>
            {isPeriodChoosable ? (
              <>
                {translate('analytics_calendar_time_period')}
                <select
                  data-qa="date-picker-select-period"
                  value={period}
                  onChange={onChangeSelectHandler}
                  className="c-datePicker__select"
                >
                  {Object.values(DatePeriod).map((mappedPeriod: DatePeriod) => (
                    <option key={mappedPeriod} value={mappedPeriod}>
                      {translate(mappedPeriod)}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              translate('history_calendar_time_date')
            )}
          </label>
          <div className="u-display--flex" onMouseOver={() => setSelectedDatePeriodIndex(null)}>
            {isCustomPeriod ? (
              <>
                {isComparingPendingValue && (
                  <div
                    className="c-date-period-marker"
                    onMouseOver={() => setSelectedDatePeriodIndex(null)}
                  />
                )}
                <input
                  className={cn('c-datePicker__input u-margin-right--small', {
                    'c-datePicker__input--invalid': !isFromInputValid,
                    'c-datePicker__input--full-width': !isComparingPendingValue,
                  })}
                  value={
                    selectedDatePeriodIndex == null && isFromInputFocus && hoverValue
                      ? hoverValue
                      : fromInput
                  }
                  onChange={onChangeFromInput}
                  onClick={() => setSelectedDatePeriodIndex(null)}
                  onBlur={onFromInputBlur}
                  onFocus={onFromInputFocus}
                  type="text"
                />
                <input
                  className={cn('c-datePicker__input', {
                    'c-datePicker__input--invalid': !isToInputValid,
                    'c-datePicker__input--is-focus': isToInputFocus,
                    'c-datePicker__input--full-width': !isComparingPendingValue,
                  })}
                  value={
                    selectedDatePeriodIndex == null && isToInputFocus && hoverValue
                      ? hoverValue
                      : toInput
                  }
                  onChange={onChangeToInput}
                  onClick={() => setSelectedDatePeriodIndex(null)}
                  onFocus={onToInputFocus}
                  onBlur={onToInputBlur}
                  type="text"
                />
              </>
            ) : (
              <>
                <input
                  data-qa="date-picker-from"
                  className={cn(
                    'c-datePicker__input u-margin-right--small c-datePicker__input--full-width',
                    {
                      'c-datePicker__input--disabled': isPeriodChoosable,
                    },
                  )}
                  value={from === undefined ? '' : formattedDate(from)}
                  type="text"
                  onFocus={() => setPeriod(DatePeriod.CUSTOM)}
                />
                <input
                  data-qa="date-picker-to"
                  className={cn('c-datePicker__input c-datePicker__input--full-width', {
                    'c-datePicker__input--disabled': isPeriodChoosable,
                  })}
                  value={to === undefined ? '' : formattedDate(to)}
                  type="text"
                  onFocus={() => setPeriod(DatePeriod.CUSTOM)}
                />
              </>
            )}
          </div>
          <div className="u-display--flex u-justify-content--space-between u-margin-top--medium u-margin-bottom--medium">
            <div className="u-display--flex u-flex-direction--row u-align-items--flex-start">
              <h1>Compare</h1>
            </div>
            <TooltipWrapper
              position={TooltipPosition.TRAILING_CENTER}
              noTinyPaddingTop={true}
              message={translate('analytics_date_compare_disabled_tooltip')}
            >
              <Switch
                isDisabled={!isCompareEnabled}
                isActive={isComparingPendingValue}
                onChange={handleToggle}
                variant="circle"
                size="medium"
              />
            </TooltipWrapper>
          </div>
          <div className="c-compare-periods-container">
            {isComparingPendingValue && (
              <div>
                {compareDateRangePeriods.map((datePeriod, index: number) => (
                  <div
                    className="u-display--flex"
                    key={index}
                    onMouseOver={() => setSelectedDatePeriodIndex(index)}
                  >
                    <div
                      className="c-date-period-marker"
                      style={{ background: compareColors[index]?.primary }}
                    />
                    <CompareDateRangeInputs
                      from={datePeriod.from}
                      to={datePeriod.to}
                      hoverValue={hoverValue}
                      isSelected={selectedDatePeriodIndex === index}
                      onClick={() => setSelectedDatePeriodIndex(index)}
                      onFromChange={(value: Date | undefined) =>
                        setCompareValue({ from: value }, index)
                      }
                      onToChange={(value: Date | undefined) =>
                        setCompareValue({ to: value }, index)
                      }
                    />
                    <div className="u-display--flex u-justify-content--center u-align-items--center u-height--36px u-margin-top--tiny u-margin-left--small">
                      <CloseIcon
                        className="u-margin-left--auto u-cursor--pointer"
                        onClick={() => removeCompareDateRangePeriod(index)}
                      />
                    </div>
                  </div>
                ))}
                {compareDateRangePeriods.length < MAX_COMPARE_DATE_PERIODS && (
                  <Button
                    variant="flat"
                    onClick={addNewCompareDateRangePeriod}
                    text={translate('analytics_date_add_period')}
                  />
                )}
              </div>
            )}
          </div>
        </article>
      </div>
      <div
        className="u-width--100 u-padding--large u-display--flex u-justify-content--flex-end"
        style={{ borderTop: '1px solid #EBECED', gap: 12 }}
      >
        <Button
          text={translate('analytics_home_cancel')}
          variant="flat"
          onClick={onClose}
          data-qa="date-picker-cancel"
        />
        {isComparingPendingValue ? (
          <Button
            data-qa="date-picker-apply"
            text={translate('analytics_date_compare')}
            onClick={() => {
              setIsComparing(true);
              onCompare();
            }}
            disabled={isCompareInputInvalid}
          />
        ) : (
          <Button
            data-qa="date-picker-apply"
            text={translate('analytics_home_apply')}
            onClick={() => {
              setIsComparing(false);
              onApply();
            }}
            disabled={isCustomPeriod ? !isFromInputValid || !isToInputValid : false}
          />
        )}
      </div>
    </section>
  );
};
