import React, { FC } from 'react';
import './filter-set-option.css';
import { getDefaultFilterSetName } from '../helpers/default-filter-set-name';

export const FilterSetOption: FC = () => {
  const DEFAULT_FILTER_SET_NAME = getDefaultFilterSetName();

  return (
    <article className="c-filter-set-option selected">
      <div className="c-filter-set-option__label">{DEFAULT_FILTER_SET_NAME}</div>
    </article>
  );
};
