import cn from 'classnames';
import {
  ComponentProps,
  CSSProperties,
  FC,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Icon, IconProps } from '../icon';
import styles from './menu.module.css';

export type MenuItem = {
  id: number | string;
  label: string;
  onClick?: VoidFunction;
  icon?: IconProps;
  disabled?: boolean;
  nestedMenu?: MenuItem[];
  isActive?: boolean;
};

export type MenuProps = {
  items: MenuItem[];
  title?: string;
} & ComponentProps<'div'>;

export type AnchorObject = {
  anchor: HTMLElement | null;
  id: string | number | null;
};

export const Menu: FC<MenuProps> = ({ items, title, className, ...props }) => {
  const [anchor, setAnchor] = useState<AnchorObject>({
    id: null,
    anchor: null,
  });
  return (
    <div {...props} className={cn(styles.menu, className)}>
      {title && <p className={styles.title}>{title}</p>}
      <ul>
        {items.map(item => (
          <MenuListItem key={item.id} item={item} anchor={anchor} setAnchor={setAnchor} />
        ))}
      </ul>
    </div>
  );
};

const MenuListItem: FC<{
  item: MenuItem;
  anchor: { id: string | number | null; anchor: HTMLElement | null };
  setAnchor: (anchor: AnchorObject) => void;
}> = ({ item, anchor, setAnchor }) => {
  const menuItemRef = useRef<HTMLLIElement>(null);
  const [nestedMenuStyle, setNestedMenuStyle] = useState<CSSProperties>({
    position: 'absolute',
  });

  const handleClick = () => {
    const { disabled, onClick, nestedMenu } = item;

    if (disabled || nestedMenu) return;
    if (onClick) return onClick();
  };

  const handleNestedMenu = () => {
    if (!item.nestedMenu) setAnchor({ id: null, anchor: null });
    setAnchor({
      id: item.id,
      anchor: menuItemRef.current,
    });
  };

  const calculateNestedMenuPosition = useCallback(() => {
    if (!menuItemRef.current) return null;

    const menuItemRect = menuItemRef.current.getBoundingClientRect();
    const parentMenuRect =
      menuItemRef.current.parentElement?.parentElement?.getBoundingClientRect();
    const nestedMenu = menuItemRef.current.parentElement?.parentElement?.querySelector(
      `.${styles.nestedMenu}`,
    );

    if (!parentMenuRect || !nestedMenu) return null;

    const nestedMenuHeight = nestedMenu?.getBoundingClientRect().height;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    let top = `${menuItemRect.top - parentMenuRect.top - 4}px`;
    let bottom = '';
    let left = menuItemRect.width + 8;

    if (menuItemRect.right + nestedMenuHeight > windowWidth) {
      left = -parentMenuRect.width;
    }

    if (menuItemRect.bottom + nestedMenuHeight > windowHeight) {
      top = '';
      bottom = '0px';
    }

    const nestedMenuCSSStyle: CSSProperties = {
      position: 'absolute',
      top: top,
      left: left,
      bottom: bottom,
    };

    return nestedMenuCSSStyle;
  }, [menuItemRef, anchor]);

  const updateNestedMenuPosition = useCallback(() => {
    const menuPosition = calculateNestedMenuPosition();
    if (menuPosition) {
      setNestedMenuStyle(menuPosition);
    }
  }, [calculateNestedMenuPosition]);

  useLayoutEffect(() => {
    updateNestedMenuPosition();
    window.addEventListener('resize', updateNestedMenuPosition);

    return () => {
      window.removeEventListener('resize', updateNestedMenuPosition);
    };
  }, [updateNestedMenuPosition]);

  return (
    <>
      <li
        className={cn(styles.menuItem, {
          [styles.disabled]: item.disabled,
          [styles.active]: anchor.id === item.id || item.isActive,
        })}
        title={item.label}
        onClick={handleClick}
        onMouseEnter={handleNestedMenu}
        ref={menuItemRef}
      >
        {item.icon ? <Icon {...item.icon} className={styles.icon} /> : null}
        <p className={styles.label}>
          {item.label}
          {item.nestedMenu ? <Icon name="arrowRight" className={styles.icon} /> : null}
        </p>
      </li>
      {item.nestedMenu && anchor.id === item.id && (
        <Menu items={item.nestedMenu} style={nestedMenuStyle} className={styles.nestedMenu} />
      )}
    </>
  );
};
