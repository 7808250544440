import { Column, ColumnBase } from '../../components/grid';
import { getNoOfCalendarDays } from '../../helpers/utils';
import { DateRangePeriod, Row, Sort } from '../../models';

export const ANALYTICS_AVERAGE_COLUMNS = [
  'clicks',
  'cost',
  'bookings',
  'gross_rev',
  'hotel_impressions',
];

export const getAverageRows = (rows: Row[], noOfDays: number) => {
  return rows.map(row => {
    const avgRow = { ...row };
    Object.keys(avgRow).forEach(key => {
      if (ANALYTICS_AVERAGE_COLUMNS.includes(key)) {
        avgRow[key] = avgRow[key] / noOfDays;
      }
    });
    return avgRow;
  });
};

export const getAverageRowsForNestedRows = (
  nestedRows: Row[],
  dateRanges: Omit<DateRangePeriod, 'period'>[],
) =>
  nestedRows?.map((nestedRow: Row, index: number) => getNestedRows(nestedRow, dateRanges[index]));

const getNestedRows = (nestedRow: Row, dateRange: Omit<DateRangePeriod, 'period'>) => {
  const dayCount = getNoOfCalendarDays(dateRange.from, dateRange.to);
  const averagedRow = getAverageRows([nestedRow], dayCount);
  return averagedRow[0];
};

export const getAverageColumns = (columns: ColumnBase[]): Column[] => {
  return columns.map(column =>
    ANALYTICS_AVERAGE_COLUMNS.includes(column.key)
      ? { ...column, name: `Avg. ${column.name}` }
      : column,
  ) as Column[];
};
