import { Props } from './widget-show-status.types';
import './widget-show-status.css';
import { FC, PropsWithChildren } from 'react';

export const WidgetShowStatus: FC<Props & PropsWithChildren> = ({ children }) => {
  return (
    <div className="c-widget-show-status u-color--juri-lighter position-center center">
      {children}
    </div>
  );
};
