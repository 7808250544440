import React, { FunctionComponent, useState } from 'react';
import { CheckboxSelect } from '../checkbox-select';
import { CheckboxSelectOption, DEFAULT_OPTIONS_LIMIT } from '../checkbox-select';
import { Props } from './bidding-type-dimension.types';
import { getAllBiddingTypesWithSL } from '../../helpers/transformers';
import { BIDDING_TYPE_DIMENSION } from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';

export const BiddingTypeDimension: FunctionComponent<Props> = ({
  applyBiddingTypeFilter,
  previouslySelectedTypes,
}) => {
  const selectedTypes = previouslySelectedTypes?.selectedOptions;
  const biddingTypes = getAllBiddingTypesWithSL().map(type => ({
    id: type.value,
    label: type.code,
    checked: !!selectedTypes?.some(option => option.value === type.value),
    value: type.value,
  }));

  const [selectedBiddingOptions, setSelectedBiddingOptions] =
    useState<CheckboxSelectOption<string>[]>(biddingTypes);

  const handleApply = (options: CheckboxSelectOption<string>[] | null) => {
    const selectedOptions = options?.filter(option => option.checked);

    if (selectedOptions) {
      setSelectedBiddingOptions(selectedOptions);
      const mappedSelectedOptions = selectedOptions.map(option => ({
        value: option.value,
        code: option.label,
      }));
      applyBiddingTypeFilter({
        name: BIDDING_TYPE_DIMENSION,
        selectedOptions: mappedSelectedOptions,
      });
    }
  };

  return (
    <div>
      <CheckboxSelect
        title="Bidding Type"
        searchEnabled={true}
        searchPlaceholder="Search bidding type"
        options={selectedBiddingOptions}
        onApply={options => handleApply(options)}
        selectedOptionsLimit={
          selectedBiddingOptions.length > DEFAULT_OPTIONS_LIMIT ? DEFAULT_OPTIONS_LIMIT : undefined
        }
      />
    </div>
  );
};
