import cn from 'classnames';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useDateRangePeriod } from '../../../../components/date-picker/date-picker.state';
import { Grid, Row, SortDirection } from '../../../../components/grid';
import { HotelControlPanel } from '../../../../components/hotel-control-panel';
import { ResultsNotFound } from '../../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../../components/shown-metrics-selector';
import { Toolbar } from '../../../../components/toolbar';
import { SearchTextFilter } from '../../../../components/toolbar/search-text-filter';
import { ViewExporter } from '../../../../components/view-exporter';
import {
  COLUMN_KEY_NAME_SL,
  COLUMN_KEY_PARTNER_REFERENCE_SL,
  COLUMN_KEY_URL_SL,
  GRID_VIEW_CONFIGURATION,
  HOTEL_DETAILS_ROUTE_PARTNER_REF_PARAM,
  SL_BID_CAP,
  SL_HOTEL_VIEW_OPEN_DETAILS,
  SL_HOTEL_VIEW_PREFIX,
  SPONSORED_HOTEL_DETAILS_ROUTE,
  SPONSORED_HOTEL_VIEW_BID_OPTION_DROPDOWN,
  TIMEOUT_NOTIFICATION,
  VIEW_NAME,
} from '../../../../constants';
import { BidFormContext } from '../../../../features/bid-form/bid-form-context';
import { BidFormUnit, EURO } from '../../../../features/bid-form/bid-form.types';
import { useBidForm } from '../../../../features/bid-form/use-bid-form';
import { AppContext } from '../../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../../helpers/grid-formatter';
import { slRow2Hotel } from '../../../../helpers/transformers';
import { isNumber, translate } from '../../../../helpers/utils';
import { useShownMetricsSelector } from '../../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../../hooks/use-view-table';
import {
  Aggregation,
  BaseNotification,
  BiddingActionType,
  FileFormat,
  View,
} from '../../../../models';
import TrackerService from '../../../../services/tracker/tracker-service';
import { withSortTracking } from '../../../analytics/with-sort-tracking';
import styles from '../common-view-styles.module.css';
import { renderAlertNotification } from './sponsored-hotel-view-notification';

export const SponsoredHotelView: FC = () => {
  const { selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();

  const view = View.SPONSORED_HOTEL;
  const numOfStickyColumns = 3;

  const [bidFormValue, setBidFormValue] = useState('');
  const [bidFormUnit, setBidFormUnit] = useState<BidFormUnit>(EURO);

  const {
    table: { columns, data, total },
    resetData,
    isLoading,
    pagination,
    setSearch,
    retry,
    selection: {
      selectedPages,
      setSelectedPages,
      selectedRowsById,
      setSelectedRowsById,
      selectRow,
    },
    filters: { allActiveFilters, hasGlobalFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useViewTable(
    view,
    Aggregation.HOTEL,
    {
      locales: selectedLocales,
      from,
      to,
    },
    {
      columnId: COLUMN_KEY_PARTNER_REFERENCE_SL,
    },
  );

  const clickHotelName = (row: Row) => {
    setSelectedItem(row);
    TrackerService.track(SL_HOTEL_VIEW_OPEN_DETAILS, {
      partnerRef: row[COLUMN_KEY_PARTNER_REFERENCE_SL],
    });
  };

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: data.rows,
    total,
    id: COLUMN_KEY_PARTNER_REFERENCE_SL,
    isShowingCheckbox: true,
    customColumns: {
      liveBid: {
        key: 'sl_bid_cpc',
        children: (value: number, row) => <LiveBidCpcCell value={value} row={row} />,
      },
      columnCellClickActions: {
        [COLUMN_KEY_NAME_SL]: {
          action: clickHotelName,
          displayValue: (row: Row) => row[COLUMN_KEY_NAME_SL] || '-',
        },
        [COLUMN_KEY_URL_SL]: {
          action: (row: Row) => {
            window.open(row[COLUMN_KEY_URL_SL], '_blank');
          },
          displayValue: () => translate('analytics_hotel_url_link_text'),
        },
      },
    },
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, numOfStickyColumns, columns, formattedColumns);

  const [selectedItem, setSelectedItem] = useState<Row>();
  const [notification, setNotification] = useState<BaseNotification>();

  const selectedHotelRows = useMemo(
    () => Array.from(selectedRowsById.values()).map(slRow2Hotel),
    [selectedRowsById],
  );

  useEffect(() => {
    if (bidFormUnit === EURO) {
      if (selectedHotelRows.length === 1) {
        setBidFormValue(selectedHotelRows[0].bidCPC?.toString());
      } else {
        setBidFormValue('');
      }
    }
  }, [selectedHotelRows, bidFormUnit]);

  const handleBidFormUnitChange = (unit: BidFormUnit) => {
    setBidFormUnit(unit);
    TrackerService.track(SPONSORED_HOTEL_VIEW_BID_OPTION_DROPDOWN, {
      option: translate(
        unit === EURO ? 'bid_form_cpc_set_to' : 'bid_form_cpc_change_by_percentage',
      ),
    });
  };

  return (
    <>
      {selectedItem !== undefined && (
        <Redirect
          to={`${SPONSORED_HOTEL_DETAILS_ROUTE.replace(
            HOTEL_DETAILS_ROUTE_PARTNER_REF_PARAM,
            selectedItem[COLUMN_KEY_PARTNER_REFERENCE_SL],
          )}`}
        />
      )}
      <div className={styles.toolbarWrapper}>
        <Toolbar
          filters={[
            <SearchTextFilter
              key="key-search-text-filters"
              placeholder={translate('sponsored_home_search_item_hotels')}
              onSearch={filterTmp => setSearch(filterTmp !== undefined ? [filterTmp] : [])}
              searchableColumnNames={[COLUMN_KEY_NAME_SL, COLUMN_KEY_PARTNER_REFERENCE_SL]}
            />,
          ]}
          actions={[
            <ShownMetricsSelector
              key="key-metrics-selector"
              options={availableMetrics}
              selectedByDefaultOptions={shownMetrics}
              view={view}
              onApply={setShownMetrics}
            />,
            <ViewExporter
              key="key-view-exporter"
              title={translate('sponsored_home_export_report')}
              exportOptions={[
                {
                  text: translate('sponsored_toolbar_export_performance_grid'),
                  fileFormat: FileFormat.CSV,
                },
                {
                  text: translate('sponsored_toolbar_export_performance_grid_excel'),
                  fileFormat: FileFormat.EXCEL,
                },
              ]}
              view={view}
              columns={columns}
              aggregation={Aggregation.HOTEL}
              filters={allActiveFilters}
              sort={sort}
            />,
          ]}
        />

        <BidFormContext.Provider
          value={{
            value: bidFormValue,
            setValue: setBidFormValue,
            unit: bidFormUnit,
            setUnit: handleBidFormUnitChange,
            bidItems: selectedHotelRows.map(hotel => ({
              baseValue: Number(hotel.bidCPC),
              biddingType: 0,
              capValue: SL_BID_CAP,
              minValue: 0,
              name: hotel.name,
              id: hotel.partnerRef,
            })),
          }}
        >
          {selectedRowsById.size > 0 && (
            <HotelControlPanel
              hotels={selectedHotelRows}
              onUpdateTable={() => {
                resetData();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              onShowNotification={(newNotification: BaseNotification) => {
                setNotification(newNotification);
                setTimeout(() => setNotification(undefined), TIMEOUT_NOTIFICATION);
              }}
              onClickLeading={() => {
                setSelectedRowsById(new Map());
                setSelectedPages(new Set());
              }}
              trackingPrefix={SL_HOTEL_VIEW_PREFIX}
              biddingActionType={BiddingActionType.SlBid}
              maxCPCBidCap={SL_BID_CAP}
            />
          )}
          {renderAlertNotification(notification, () => setNotification(undefined))}
          <div className={styles.gridWrapper}>
            <Grid
              numStickyColumns={numOfStickyColumns}
              columns={visibleFormattedColumns}
              rows={formattedRows}
              footerRows={formattedFooterRows}
              isLoading={isLoading}
              configuration={GRID_VIEW_CONFIGURATION}
              initialData={{
                initialSelectedRowsId: new Set(selectedRowsById.keys()),
                initialSelectedPages: selectedPages,
              }}
              selectableRow={{
                onSelectPage: selectedPagesTmp => setSelectedPages(selectedPagesTmp),
                onSelectRow: selectRow,
              }}
              onSort={withSortTracking((columnName: string, direction: SortDirection) => {
                setSort({ columnName, direction });
              }, VIEW_NAME[view])}
              pagination={{
                page: pagination.pagination,
                totalItems: data.count,
                onPreviousPage: pagination.setPrevPage,
                onNextPage: pagination.setNextPage,
                onUpdateRowsPerPage: pagination.setNumItemsPerPage,
              }}
              resultsNotFound={
                <ResultsNotFound
                  isButtonEnabled={hasGlobalFilters && !isLoading}
                  onClick={clearGlobalFilters}
                  message="sponsored_hotel_view_no_results"
                  retry={retry}
                />
              }
            />
          </div>
        </BidFormContext.Provider>
      </div>
    </>
  );
};

const LiveBidCpcCell: FC<{ value: number; row: Row }> = ({ value, row }) => {
  const { newValue, hasWarning } = useBidChangeFor(row.id);
  if (newValue === undefined) {
    return (
      <span>
        {value} {EURO}
      </span>
    );
  }
  return (
    <span>
      {value} {EURO} to{' '}
      <b className={cn({ [styles.cpcInvalidValue]: hasWarning })}>
        {newValue.toFixed(2)} {EURO}
      </b>
    </span>
  );
};

function useBidChangeFor(id: string | number): {
  newValue: number | undefined;
  hasWarning: boolean;
} {
  const { adjustedBidItems, isFormInvalid } = useBidForm(false);
  const item = adjustedBidItems.find(it => it.id === id);
  const hasWarning = item ? item.isBelow || item.isExceeded : false;
  const isValueInvalid =
    !item || (!isNumber(item.acceptedValue) && (isFormInvalid || !item.acceptedValue));
  if (isValueInvalid) {
    return { newValue: undefined, hasWarning };
  }
  return { newValue: item!.acceptedValue, hasWarning };
}
