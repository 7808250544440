import { Props } from './widget-table.types';
import './widget-table.css';
import { FC, PropsWithChildren } from 'react';

export const WidgetTable: FC<Props & PropsWithChildren> = ({ children }) => {
  return (
    <table className="c-widget-table">
      <tbody>{children}</tbody>
    </table>
  );
};
