import { FC } from 'react';
import { Tooltip } from '../../core-ui/components/tooltip/tooltip';
import styles from './bid-form.module.css';

type WarningTooltipProps = {
  warningMessage: string;
};

export const WarningTooltip: FC<WarningTooltipProps> = ({ warningMessage, children }) => {
  return (
    <Tooltip
      position="bottomStart"
      className={styles.tooltip}
      title={warningMessage}
      isSticky
      isHidden={!warningMessage}
    >
      {children}
    </Tooltip>
  );
};
