import { debounce } from 'lodash';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Joyride, { CallBackProps, TooltipRenderProps } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { ONBOARDING_TOUR_ROUTES } from '../../../constants/onboarding-targets';
import { ButtonIcon } from '../button-icon/button-icon';
import { Button } from '../button/button';
import styles from './onboarding-tour.module.css';
import { OnboardingTourProps, OnboardingTourState } from './onboarding-tour.types';

/*
https://docs.react-joyride.com/props
*/

export const OnboardingTour: FC<OnboardingTourProps> = ({ tour, callback, stepIndex }) => {
  const location = useLocation();
  const { tourState } = useContext(OnboardingTourContext);
  const [updateKey, setUpdateKey] = useState(0);

  const handleMutation = debounce(() => {
    setUpdateKey(prevKey => prevKey + 1);
  }, 250);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      const hasRootChanges = mutations.some(mutation => {
        const target = mutation.target as HTMLElement;
        return target.id === 'root' || target.closest('#root');
      });
      if (hasRootChanges) {
        handleMutation();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: false,
    });

    return () => {
      observer.disconnect();
      handleMutation.cancel();
    };
  }, [location.pathname, handleMutation]);

  if (!tour || !ONBOARDING_TOUR_ROUTES) return null;

  const { steps, run } = tour;
  if (!tourState.tourId) return null;
  const isBeaconVisible = ONBOARDING_TOUR_ROUTES[tourState.tourId]?.includes(location.pathname);

  if (!isBeaconVisible && tourState.stepIndex === 0) return null;

  return ReactDOM.createPortal(
    <ErrorBoundary fallback={null}>
      <Joyride
        key={updateKey}
        scrollToFirstStep
        hideBackButton
        disableOverlayClose
        disableScrolling
        continuous
        showProgress
        callback={callback}
        steps={steps as any}
        run={run}
        tooltipComponent={CustomTooltip}
        stepIndex={stepIndex}
        styles={{
          options: {
            arrowColor: '#007cc2',
          },
        }}
      />
    </ErrorBoundary>,
    document.body,
    'onboarding-tour',
  );
};

export const OnboardingTourContext = createContext<{
  tourState: OnboardingTourState;
  setTourState: (
    state: OnboardingTourState | ((prevState: OnboardingTourState) => OnboardingTourState),
  ) => void;
  startTour: (tourId: string, steps: any[], callback?: (data: CallBackProps) => void) => void;
}>({
  tourState: { tourId: null, tour: {}, callback: () => {}, stepIndex: 0 },
  setTourState: () => {},
  startTour: () => {},
});

export const OnboardingTourProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [tourState, setTourState] = useState<OnboardingTourState>({
    tourId: null,
    tour: {},
    callback: () => {},
    stepIndex: 0,
  });

  const currentTour = tourState.tourId ? tourState.tour[tourState.tourId] : null;

  const startTour = useCallback(
    (tourId: string, steps: any[], callback?: (data: CallBackProps) => void) => {
      const didTheTourRunBefore = localStorage.getItem(tourId) === 'true';

      setTourState(prev => ({
        ...prev,
        tourId,
        tour: {
          ...prev.tour,
          [tourId]: { steps, run: !didTheTourRunBefore, tourName: tourId },
        },
        callback: callback || prev.callback,
        stepIndex: 0,
      }));
    },
    [],
  );

  return (
    <OnboardingTourContext.Provider value={{ tourState, setTourState, startTour }}>
      {currentTour && (
        <OnboardingTour
          tour={currentTour}
          callback={tourState.callback}
          stepIndex={tourState.stepIndex}
        />
      )}
      {children}
    </OnboardingTourContext.Provider>
  );
};

const CustomTooltip = (props: TooltipRenderProps) => {
  const { step, closeProps, tooltipProps, primaryProps, index, size } = props;

  let navButtonText = 'Next';
  if (index === 0) {
    navButtonText = 'Start tour';
  }
  if (index + 1 === size) {
    navButtonText = 'End tour';
  }

  return (
    <div className={styles.container} {...tooltipProps}>
      <div className={styles.content}>
        <div>{step.content}</div>
        <ButtonIcon {...closeProps} icon={{ name: 'close' }} />
      </div>
      <div className={styles.footer}>
        {size > 1 && <span>{`${index + 1}/${size}`}</span>}
        <div>
          <Button {...primaryProps} text={navButtonText} className={styles.nextButton} />
        </div>
      </div>
    </div>
  );
};
