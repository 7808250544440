import { ApiInventoryStats } from 'src/views/custom-dashboard/global-inventory-widget/api-inventory-stats-helper';
import { AGGREGATION_NAME, FILE_FORMAT_VALUE } from '../constants';
import { ExportViewPayLoad } from '../helpers/api/analytics/types';
import { apiFileHelper } from '../helpers/api/api-file-helper';
import ApiReportsHelper from '../helpers/api/reports/api-reports-helper';
import { getProfileFromLocalStorage } from '../helpers/local-storage-helper';
import { getFormattedDates } from '../helpers/utils';
import { Aggregation, FileFormat, FilterColumn, ReportsType, View } from '../models';

class ReportService {
  private getToken() {
    const user = getProfileFromLocalStorage();
    if (!user) {
      return null;
    }
    return user.token.accessToken;
  }

  public async getReportsByPartner(partnerId: number) {
    return await ApiReportsHelper.fetchReports(partnerId);
  }

  public export(
    view: View,
    aggregations: Aggregation[],
    fileFormat: FileFormat,
    payload: ExportViewPayLoad,
    fileName?: string,
  ) {
    const { partnersId, locales, from, to, filters, sort, comparePeriods } = payload;
    const date = getFormattedDates(from, to);
    const condition = this.filters2Conditions(filters);
    const body = {
      partnerId: partnersId,
      aggregation: aggregations.map(aggregation => AGGREGATION_NAME[aggregation]),
      condition,
      localeCodes: locales,
      sort: sort ? [sort] : [],
      date,
      comparePeriods,
    };

    return apiFileHelper.downloadReportAnalyticsFile(view, body, fileFormat, fileName);
  }

  private filters2Conditions(filters: FilterColumn[]): any {
    return filters.map(({ columnName, type, value, condition }) => {
      const conditions = condition ? this.filters2Conditions(condition) : [];
      return { columnName, type, value, condition: conditions };
    });
  }

  public triggerInventoryByType(metric: keyof ApiInventoryStats, partnerIds: number[]) {
    return apiFileHelper.downloadInventoryStatsFile(partnerIds, metric);
  }

  public triggerExport(
    view: string,
    body: any,
    formatParam: string = FILE_FORMAT_VALUE[FileFormat.CSV],
  ) {
    window.location.href =
      process.env.REACT_APP_URL +
      `/analytics/export/download?view=${view}&filters=` +
      JSON.stringify(body) +
      `&auth=${this.getToken()}&format=${formatParam}`;
  }

  public async updateReport(partnerId: number, report: ReportsType, partnerFolder: string) {
    if (report.id === 0 && partnerFolder !== undefined) {
      await ApiReportsHelper.enableReports(partnerId, report, partnerFolder);
    } else {
      await ApiReportsHelper.updateReports(partnerId, report);
    }
  }
}

export default new ReportService();
