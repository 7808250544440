import { ComponentProps, FC } from 'react';
import styles from './alert.module.css';
import cn from 'classnames';
import { Icon } from '../icon';

type AlertVariant = 'success' | 'error' | 'warning';

export type AlertProps = {
  message?: string;
  variant: AlertVariant;
  onClose?: () => void;
} & ComponentProps<'div'>;

export const Alert: FC<AlertProps> = ({ message, variant, onClose, children, ...props }) => {
  return (
    <div {...props} className={cn(styles.alert, styles[variant])}>
      <div className={styles.content}>{message ?? children}</div>
      {onClose ? <Icon name="close" className={styles.close} onClick={onClose} /> : null}
    </div>
  );
};
