import { FC, useEffect } from 'react';
import { BidForm } from '../../features/bid-form/bid-form';
import { AdjustedBidItem } from '../../features/bid-form/bid-form.types';
import { ONBOARDING_TARGETS } from '../../constants/onboarding-targets';
import { TourStep } from '../../core-ui/components/onboarding-tour/onboarding-tour.types';
import { useOnboardingTour } from '../../hooks/use-onboarding-tour';
import { CHANGE_BID_ON_BOARDING } from '../../constants';

type UpdateHotelBidCPCFormProps = {
  label: string;
  onSubmit: (adjustedRows: AdjustedBidItem[]) => void;
};

export const UpdateHotelBidCPCForm: FC<UpdateHotelBidCPCFormProps> = ({ label, onSubmit }) => {
  const steps = Object.values(ONBOARDING_TARGETS.CHANGE_BID) as TourStep[];
  const { startTour } = useOnboardingTour({
    steps: steps,
    tourName: CHANGE_BID_ON_BOARDING,
  });

  useEffect(() => {
    startTour();
  }, []);

  return (
    <span>
      <BidForm type="CPC" label={label} onSubmit={onSubmit} />
    </span>
  );
};
