import { Navigate, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { Authentication } from '../../components/authentication/authentication';
import {
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  OAUTH_CHECK_ROUTE,
  UNAUTHORIZED_ACCOUNT_ROUTE,
} from '../../constants/routes';
import { App } from '../../global/app';
import { Unauthorized } from '../unauthorized';

const AuthenticationURL = () => {
  const redirectUri = process.env.REACT_APP_LOCAL!;
  window.location.href = getLoginUri() + redirectUri;
  return null;
};

function getLoginUri(): string {
  return process.env.REACT_APP_LOGIN!;
}

const router = createBrowserRouter([
  {
    path: LOGIN_ROUTE,
    element: <AuthenticationURL />,
  },
  {
    path: UNAUTHORIZED_ACCOUNT_ROUTE,
    element: <Unauthorized />,
  },
  {
    path: OAUTH_CHECK_ROUTE,
    element: <Authentication />,
  },
  {
    path: '/',
    element: <Navigate to={DASHBOARD_ROUTE} />,
  },
  {
    path: '/*',
    element: <App />,
  },
]);

export const Login = () => {
  const searchParams = new URLSearchParams(window.location ? window.location.search : '');
  const returnTo = searchParams.get('return_to');

  if (returnTo) {
    if (returnTo.includes('resourcecenter')) {
      localStorage.setItem('return_to', returnTo);
    } else {
      window.location.href = 'https://support.trivago.com';
      return null;
    }
  }

  return <RouterProvider router={router} />;
};
