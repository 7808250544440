import { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router';
import { SettingsLineDarkIcon } from 'tcp-react-icons';
import { Card } from '../../components/card';
import { FetchError } from '../../components/error/fetch-error';
import { Loading, LoadingOverlay } from '../../components/loading';
import { TeapotAnimation } from '../../components/teapot-animation';
import { REPORTS_ACCESS_REPORTS_DETAIL, REPORTS_HISTORY_ROUTE } from '../../constants';
import { AppContext } from '../../global/context/app-context';
import { isTrivagoEmployee, translate } from '../../helpers/utils';
import { useSftpConfig } from '../../hooks/use-sftp-config';
import { useSubscribeToReportModal } from '../../hooks/use-subscribe-to-report-modal';
import { useSubscriptions } from '../../hooks/use-subscriptions';
import { getPartner } from '../../services/app-service';
import TrackerService from '../../services/tracker/tracker-service';
import { ActiveReports } from './active-reports';
import { InactiveReports } from './inactive-reports';
import './report-tab.css';
import { Report, Subscription } from './report.types';
import { SftpFolderSetupModal } from './sftp-folder-setup-modal';

export const NewReportsTab: FC = () => {
  const navigate = useNavigate();
  const { user, partner, subPartners } = useContext(AppContext);
  const partnerId = getPartner(partner, subPartners).partnerId;
  const {
    folderName,
    isLoading: isLoadingSftpConfig,
    error: sftpConfigError,
    refetch: refetchSftpConfig,
  } = useSftpConfig(partnerId);
  const [showReportsSettingsModal, closeModal] = useModal(
    () => <SftpFolderSetupModal initialFolderName={folderName} onClose={closeModal} />,
    [folderName],
  );

  const {
    subscriptions,
    isLoading: isLoadingSubscriptions,
    refetch: refetchSubscriptions,
    error: fetchSubscriptionsError,
  } = useSubscriptions(partnerId);
  const showSubscribeModal = useSubscribeToReportModal(changesMade => {
    if (!changesMade) return;
    refetchSubscriptions();
  });

  function showReportDetails(data: Report | Subscription) {
    let report: Report;
    let subscription: Subscription | null = null;
    if ((data as any).name) {
      report = data as Report;
    } else {
      subscription = data as Subscription;
      report = subscription.reportDefinition.report;
    }
    TrackerService.track(REPORTS_ACCESS_REPORTS_DETAIL, {
      report_name: report.name,
    });
    const params: Record<string, any> = {};
    if (subscription != null) {
      params.reportDefinitionId = subscription.reportDefinition.id;
      params.isCombined = subscription.isCombined;
    }
    const paramsString = new URLSearchParams(params).toString();
    navigate(REPORTS_HISTORY_ROUTE.replace(':reportName', report.name) + '?' + paramsString);
  }

  return (
    <Card className="u-margin--gutter c-reports-tab">
      <div className="u-margin-left--huge u-margin-right--huge u-padding-bottom--gutter--large ">
        <div className="u-padding-h--large u-display--flex u-flex-direction--row u-justify-content--space-between u-align-items--center">
          <h2>{translate('reports_my_reports')}</h2>
          {isTrivagoEmployee(user.level) && (
            <SettingsLineDarkIcon
              onClick={() => showReportsSettingsModal()}
              className="u-cursor--pointer"
              style={{ width: 35, height: 35 }}
            />
          )}
        </div>
        {(() => {
          if (fetchSubscriptionsError) {
            return <FetchError message={fetchSubscriptionsError} retry={refetchSubscriptions} />;
          }
          if (!isLoadingSubscriptions && subscriptions.length === 0) {
            return <TeapotAnimation message={translate('reports_table_no_result_message')} />;
          }
          return (
            <LoadingOverlay isLoading={isLoadingSubscriptions}>
              <ActiveReports
                subscriptions={subscriptions}
                onShowReportDetails={showReportDetails}
                onSubscriptionDeleted={() => refetchSubscriptions()}
              />
            </LoadingOverlay>
          );
        })()}

        {(() => {
          if (!isTrivagoEmployee(user.level)) return null;
          if (isLoadingSftpConfig) return <Loading isWhite={false} />;
          if (sftpConfigError)
            return <FetchError message={sftpConfigError} retry={refetchSftpConfig} />;
          return (
            <InactiveReports
              isSftpFolderSetup={!!folderName}
              onSetupSftpFolder={showReportsSettingsModal}
              onShowReportDetails={showReportDetails}
              onSubscribeClick={showSubscribeModal}
            />
          );
        })()}
      </div>
    </Card>
  );
};
