import { FC, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';
import { DASHBOARD_ROUTE, HOTEL_DETAILS_PARTIAL_ROUTE } from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { Analytics } from '../analytics';
import { HotelDetailView } from '../hotel-detail-view';
import './analytics-router.css';

export const AnalyticsRouter: FC = () => {
  const { selectedLocales, partner, subPartners } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();

  const renderAnalytics = () => (
    <Analytics
      selectedPartner={partner}
      posLocales={Array.from(selectedLocales)}
      subBrands={Array.from(subPartners)}
      from={from}
      to={to}
    />
  );

  return (
    <Routes>
      <Route path={''} element={renderAnalytics()} />
      <Route path={`${HOTEL_DETAILS_PARTIAL_ROUTE}`} element={<HotelDetailView />} />
      <Route path={DASHBOARD_ROUTE} element={<Navigate to={DASHBOARD_ROUTE} />} />
    </Routes>
  );
};
