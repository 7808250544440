import cn from 'classnames';
import { ChangeEvent, FC } from 'react';
import { CHANGE_BID_ON_BOARDING, HOTEL_BID_OPTION_DROPDOWN } from '../../constants';
import { Button } from '../../core-ui/components/button/button';
import { InputField } from '../../core-ui/components/input/input';
import { isNumber, translate } from '../../helpers/utils';
import trackerService from '../../services/tracker/tracker-service';
import { BidFormLabel } from './bid-form-label';
import styles from './bid-form.module.css';
import { BidFormProps, BidFormTypesEnum, BidFormUnit, EURO, PERCENTAGE } from './bid-form.types';
import { useApplyBidsConfirmDialog } from './confirm-dialog';
import { useBidForm } from './use-bid-form';
import { WarningTooltip } from './warning-tooltip';

export const BidForm: FC<BidFormProps> = ({ label, type, onSubmit }) => {
  const confirm = useApplyBidsConfirmDialog();
  const isCPC = type === BidFormTypesEnum.CPC;
  const { value, setValue, unit, setUnit, adjustedBidItems, warningMessage, isFormInvalid } =
    useBidForm(isCPC);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isValidNumber = (input: string) => {
      const num = parseFloat(input);
      const decimalPlaces = input.split('.')[1];
      if (!isNumber(num) || decimalPlaces?.length > 2) return false;
      return true;
    };

    const inputValue = e.target.value;
    const isSigned = unit === PERCENTAGE;
    const isInputValid = inputValue === '' || (inputValue === '-' && isSigned);
    if (isInputValid || isValidNumber(inputValue)) {
      setValue(inputValue);
    }
  };

  const applyBids = async () => {
    const isConfirmed = await confirm({
      itemsCount: adjustedBidItems.length,
      bidItemName: adjustedBidItems.length > 1 ? '' : adjustedBidItems[0].name,
      isCPC,
    });
    if (!isConfirmed) return;

    onSubmit(adjustedBidItems);
    setValue('');
  };

  return (
    <form
      className={styles.formContainer}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <BidFormLabel
        unit={unit}
        label={label}
        onUnitChange={u => {
          setUnit(u);
          setValue('');
        }}
      />
      <div className={styles.inputContainer}>
        <WarningTooltip warningMessage={warningMessage}>
          <InputField
            value={value}
            type="numeric"
            onChange={handleChange}
            onInputChange={handleChange}
            className={cn({ [styles.inputWarning]: warningMessage }, CHANGE_BID_ON_BOARDING)}
            placeholder={adjustedBidItems.length > 1 ? 'Multiple' : '0.00'}
            decoration={unit === PERCENTAGE || !isCPC ? PERCENTAGE : EURO}
          />
        </WarningTooltip>
      </div>
      <Button text="Apply" disabled={isFormInvalid} type="submit" onClick={applyBids} />
    </form>
  );
};
