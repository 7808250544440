import { FC, ReactNode, useCallback, useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { withErrorBoundary } from '../../../components/error/error-boundary';
import { ManagementRightsGuard } from '../../../components/management-rights-guard';
import { PageFooter } from '../../../components/page-footer';
import { SnackbarDisplayContainer } from '../../../components/snackbar/snackbar-display-container';
import {
  ADMIN_ROUTE,
  ANALYTICS_ROUTE,
  CAMPAIGNS_ROUTE,
  DASHBOARD_ROUTE,
  HISTORY_ROUTE,
  REPORTS_HISTORY_ROUTE,
  REPORTS_ROUTE,
  SPONSORED_ANALYTICS_ROUTE,
  SPONSORED_CAMPAIGNS_ROUTE,
} from '../../../constants';
import { findItemsMenuOptions } from '../../../services/app-service';
import { Admin } from '../../../views/admin';
import { AnalyticsRouter } from '../../../views/analytics/analytics-router';
import { Bidding } from '../../../views/bidding';
import { CustomDashboard } from '../../../views/custom-dashboard/custom-dashboard';
import { History } from '../../../views/history';
import { NewReportsTab } from '../../../views/reports/new-reports-tab';
import { ReportHistory } from '../../../views/reports/report-history/report-history';
import { SponsoredAnalyticsRouter } from '../../../views/sponsored/sponsored-analytics/sponsored-analytics-router';
import { SponsoredCampaigns } from '../../../views/sponsored/sponsored-campaigns';
import { AppContext } from '../../context/app-context';
import { HeaderPage } from '../header/header-page';

export const AppRoute: FC = () => {
  const location = useLocation();

  const { user, selectedLocales, partner, subPartners, onUpdateSubpartners } =
    useContext(AppContext);

  const updateSubPartnersSelectedForCurrentPage = useCallback(() => {
    const menuOption = findItemsMenuOptions(location.pathname);

    if (!menuOption) return;

    if (!menuOption.selectorBar.allowMultipleBrandSelection && subPartners.length > 1) {
      const brand = subPartners.find(({ partnerId }) => partnerId === partner!.partnerId);
      onUpdateSubpartners([brand ?? subPartners[0]]);
    }
  }, [location.pathname, onUpdateSubpartners, partner, subPartners]);

  useEffect(() => {
    updateSubPartnersSelectedForCurrentPage();
  }, [updateSubPartnersSelectedForCurrentPage]);

  useEffect(() => {
    updateSubPartnersSelectedForCurrentPage();
  }, [location, partner, updateSubPartnersSelectedForCurrentPage]);

  const hasLocalesLoaded = selectedLocales.length > 0;

  const renderCustomDashboard = () => {
    return hasLocalesLoaded ? <CustomDashboard /> : null;
  };

  const renderAnalytics = (): ReactNode => {
    return hasLocalesLoaded ? <AnalyticsRouter /> : null;
  };

  const renderSponsoredAnalytics = () => {
    return hasLocalesLoaded ? <SponsoredAnalyticsRouter /> : null;
  };

  const { right } = findItemsMenuOptions(location.pathname);

  const exactRoutes: Record<string, Function> = {
    [DASHBOARD_ROUTE]: renderCustomDashboard,
    [CAMPAIGNS_ROUTE]: () => <Bidding />,
    [HISTORY_ROUTE]: () => <History />,
    [REPORTS_ROUTE]: () => <NewReportsTab />,
    [REPORTS_HISTORY_ROUTE]: () => <ReportHistory />,
    [SPONSORED_CAMPAIGNS_ROUTE]: () => <SponsoredCampaigns />,
  };

  const nonExactRoutes: Record<string, Function> = {
    [ANALYTICS_ROUTE]: renderAnalytics,
    [SPONSORED_ANALYTICS_ROUTE]: renderSponsoredAnalytics,
  };

  return (
    <>
      <HeaderPage />
      <div style={{ minHeight: 'calc(100vh - 362px)' }}>
        <ManagementRightsGuard user={user} rights={right}>
          <Routes>
            {/* Exact Routes */}
            {Object.entries(exactRoutes).map(([path, component]) => (
              <Route key={path} path={path} element={withErrorBoundary(component())} />
            ))}

            {/* Non-exact Routes */}
            {Object.entries(nonExactRoutes).map(([path, component]) => (
              <Route key={path} path={`${path}/*`} element={withErrorBoundary(component())} />
            ))}

            {/* Admin Route */}
            {user.isAdmin && <Route path={ADMIN_ROUTE} element={withErrorBoundary(<Admin />)} />}

            {/* Redirect to Dashboard */}
            <Route path={DASHBOARD_ROUTE} element={<Navigate to={DASHBOARD_ROUTE} replace />} />
          </Routes>
        </ManagementRightsGuard>
      </div>
      <PageFooter />
      <SnackbarDisplayContainer />
    </>
  );
};
