import { FC, PropsWithChildren } from 'react';
import './circle.css';
import { Props } from './circle.types';

export const Circle: FC<Props & PropsWithChildren> = ({ radius, color, children }) => {
  return (
    <i
      className="c-circle"
      style={{ height: 2 * radius, width: 2 * radius, backgroundColor: color }}
    >
      {children}
    </i>
  );
};
