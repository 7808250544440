import { FC } from 'react';
import { DashboardHeaderProps } from './dashboard-header.types';
import styles from './dashboard-header.module.css';
import { PeriodPicker } from '../../../components/period-picker';
import { AddWidgetDropdown } from '../add-widget-dropdown';
import { WidgetMenu } from '../widget/widget-menu';
import { translate } from '../../../helpers/utils';
import { DashboardHeaderName } from './dashboard-header-name';
import iconCreate from './icn-create.svg';
import iconRevert from './icn-revert.svg';
import { useModal } from 'react-modal-hook';
import { MenuItemRemove } from '../../../components/menu/menu-item-remove';
import { DeleteDashboardModal } from './delete-dashboard-modal';
import { DeleteLastDashboardModal } from './delete-last-dashboard-modal';
import { ResetDefaultDashboardModal } from '../reset-default-dashboard-modal';

export const DashboardHeader: FC<DashboardHeaderProps> = ({
  selectedDashboard,
  dashboards,
  timeOptionSelected,
  onSelectTimeOption,
  onSelectAddWidget,
  onChangeName,
  onCreateDashboard,
  onSelectDashboard,
  onReset,
  onRemove,
}) => {
  const dashboardName = selectedDashboard ? selectedDashboard.name : 'Name Unknown';
  const [showDeleteModal, closeDeleteModal] = useModal(() => {
    return (
      <DeleteDashboardModal name={dashboardName} onClose={closeDeleteModal} onApply={onRemove} />
    );
  }, [dashboardName]);

  const [showLastDashboardModal, closeLastDashboardModal] = useModal(() => {
    return <DeleteLastDashboardModal name={dashboardName} onClose={closeLastDashboardModal} />;
  });

  const [showResetDashboardModal, closeResetDashboardModal] = useModal(() => {
    return <ResetDefaultDashboardModal onApply={onReset} onClose={closeResetDashboardModal} />;
  });

  const selectMenuItem = (index: number) => MENU_OPTIONS[index].action();

  const deleteModal = () => {
    if (dashboards.length === 1) {
      showLastDashboardModal();
    } else {
      showDeleteModal();
    }
  };

  const MENU_OPTIONS = [
    {
      html: (
        <div key="menu-option-reset-dashboard" className={styles.menuItem}>
          <img
            src={iconRevert}
            className={styles.menuItemIcon}
            alt="Icon showing revert to default dashboard"
          />
          {translate('dashboard_reset_to_default')}
        </div>
      ),
      action: showResetDashboardModal,
    },
    {
      html: (
        <div key="menu-option-create-dashboard" className={styles.menuItem}>
          <img
            src={iconCreate}
            className={styles.menuItemIcon}
            alt="Icon showing create a new dashboard"
          />
          {translate('dashboard_header_create_dashboard')}
        </div>
      ),
      action: onCreateDashboard,
    },
    {
      html: <MenuItemRemove>{translate('dashboard_header_delete_dashboard')}</MenuItemRemove>,
      action: deleteModal,
    },
  ];

  return (
    <div className={styles.dashboardHeader}>
      <DashboardHeaderName
        selectedDashboard={selectedDashboard}
        dashboards={dashboards}
        onChangeName={onChangeName}
        onCreateDashboard={onCreateDashboard}
        onSelectDashboard={onSelectDashboard}
      />
      <div className="u-margin-left--auto u-margin-right--gutter">
        <PeriodPicker
          timeOptionSelected={timeOptionSelected}
          onSelectTimeOption={onSelectTimeOption}
        />
      </div>
      <AddWidgetDropdown onSelectWidget={onSelectAddWidget} />

      <div className={styles.menu}>
        <WidgetMenu
          menuItems={MENU_OPTIONS.map(item => item.html)}
          onSelectMenuItem={selectMenuItem}
          isDarker
        />
      </div>
    </div>
  );
};
