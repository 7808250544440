import { FC, PropsWithChildren } from 'react';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';
import { Props } from './menu-item-remove.types';

export const MenuItemRemove: FC<Props & PropsWithChildren> = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className="c-menu__item-remove-button">
      <DeleteLineDarkIcon className="u-color--red u-margin-right--t-small" />
      {children}
    </div>
  );
};
