import './user-menu.css';
import { Color } from '../../../../constants';
import { UserPopover } from './user-popover';
import ApiAuthHelper, { getLogoutUrl } from '../../../../helpers/api/api-auth-helper';
import { Circle } from '../../../../components/circle';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { createRef, FC, useContext, useState } from 'react';
import { AppContext } from '../../../context/app-context';
import { getProfileFromLocalStorage } from '../../../../helpers/local-storage-helper';
import { clearProfile } from '../../../../services/profile-service';

export const UserMenu: FC = () => {
  const {
    user: { firstName, lastName, email },
  } = useContext(AppContext);

  const ref = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  const onClickLogout = async () => {
    const profile = getProfileFromLocalStorage();
    if (profile) {
      try {
        await ApiAuthHelper.logout(profile.token.accessToken);
        clearProfile();
        window.location.href = getLogoutUrl();
      } catch (e) {
        console.error(e);
        // TODO not implemented yet (show notification)
      }
    }
  };

  const names = (() => {
    const accountName: string[] = [];
    if (firstName !== undefined) {
      accountName.push(firstName);
      if (lastName !== undefined) {
        accountName.push(lastName);
      }
    } else {
      accountName.push(email);
    }

    return accountName;
  })();

  const accountNameInitials = names.map(nameTmp => nameTmp[0]).join('');
  const emailInitials = email[0];
  const accountName = names.reduce((name, nameTmp) => name + ' ' + nameTmp, '');

  return (
    <div ref={ref} className="c-user-menu u-display--flex u-cursor--pointer has-popover">
      <div onClick={() => setIsOpen(!isOpen)}>
        <Circle radius={18} color={Color.Blue400}>
          <div className="c-user-menu__account u-position--relative u-font-weight--bold u-color--white">
            {accountNameInitials !== ''
              ? accountNameInitials.toUpperCase()
              : emailInitials.toUpperCase()}
          </div>
        </Circle>
      </div>
      {isOpen && (
        <UserPopover
          onClickLogout={onClickLogout}
          name={accountName !== '  ' ? accountName : email}
        />
      )}
    </div>
  );
};
