import { MenuOption } from '../models';
import { CLICK_SPONSORED_NAME } from '../services/tracker/customer-actions';

export const ADMIN_RIGHT = 332;
export const DEV_RIGHT = 300;
export const TRV_EMPLOYEE_RIGHT = 200;
export const USER_RIGHT = 0;

export const HOTEL_DETAILS_ROUTE_PARTNER_REF_PARAM = ':partnerRef';
export const HOTEL_DETAILS_PARTIAL_ROUTE = `property/${HOTEL_DETAILS_ROUTE_PARTNER_REF_PARAM}`;

export const OAUTH_CHECK_ROUTE = '/oauth-check';
export const LOGIN_ROUTE = '/login';
export const UNAUTHORIZED_ACCOUNT_ROUTE = '/inactive-account';
export const DASHBOARD_ROUTE = '/dashboard';
// export const SUGGESTIONS_ROUTE = '/opportunities';
export const ANALYTICS_ROUTE = '/analytics';
export const HOTEL_DETAILS_ROUTE = `${ANALYTICS_ROUTE}/${HOTEL_DETAILS_PARTIAL_ROUTE}`;
export const CAMPAIGNS_ROUTE = '/campaigns';
export const HISTORY_ROUTE = '/history';
export const REPORTS_ROUTE = '/reports';
export const REPORTS_HISTORY_ROUTE = '/reports/:reportName';
export const SPONSORED_ROUTE = '/sponsored';
export const ADMIN_ROUTE = '/admin';
export const SPONSORED_ANALYTICS_ROUTE = '/sponsored-analytics';
export const SPONSORED_HOTEL_DETAILS_ROUTE = `${SPONSORED_ANALYTICS_ROUTE}/${HOTEL_DETAILS_PARTIAL_ROUTE}`;
export const SPONSORED_CAMPAIGNS_ROUTE = '/sponsored-campaigns';
export const HOME_ROUTE = '/';
export const DEFAULT_ROUTE = DASHBOARD_ROUTE;

export enum FeaturesRights {
  ANALYTICS = USER_RIGHT,
  CUSTOM_DASHBOARD = USER_RIGHT,
  CAMPAIGNS = USER_RIGHT,
  HISTORY = USER_RIGHT,
  SPONSORED = USER_RIGHT,
  SPONSORED_ANALYTICS = USER_RIGHT,
  SPONSORED_CAMPAIGNS = USER_RIGHT,
  SUGGESTIONS = USER_RIGHT,
  REPORTS = USER_RIGHT,
  ADMIN = ADMIN_RIGHT,
}

export const MENU: MenuOption[] = [
  {
    name: 'menu_link_name_dashboard',
    link: DASHBOARD_ROUTE,
    right: FeaturesRights.CUSTOM_DASHBOARD,
    id: 'dashboard',
    selectorBar: {
      allowMultipleBrandSelection: true,
      allowPosSelection: true,
      allowDateSelection: true,
    },
  },
  {
    name: 'menu_link_name_analytics',
    link: ANALYTICS_ROUTE,
    right: FeaturesRights.ANALYTICS,
    id: 'analytics',
    selectorBar: {
      allowMultipleBrandSelection: true,
      allowPosSelection: true,
      allowDateSelection: true,
    },
  },
  {
    name: 'menu_link_name_campaigns',
    link: CAMPAIGNS_ROUTE,
    right: FeaturesRights.CAMPAIGNS,
    id: 'campaigns',
    selectorBar: {
      allowMultipleBrandSelection: false,
      allowPosSelection: false,
      allowDateSelection: false,
    },
  },
  // {
  //   name: 'menu_link_name_bidding_suggestions',
  //   link: SUGGESTIONS_ROUTE,
  //   right: FeaturesRights.SUGGESTIONS,
  //   id: 'bidding-suggestions',
  //   selectorBar: {
  //     allowMultipleBrandSelection: false,
  //     allowPosSelection: false,
  //     allowDateSelection: false,
  //   },
  // },
  {
    name: 'menu_link_name_history',
    link: HISTORY_ROUTE,
    right: FeaturesRights.HISTORY,
    id: 'history',
    selectorBar: {
      allowMultipleBrandSelection: false,
      allowPosSelection: false,
      allowDateSelection: false,
    },
  },
  {
    name: 'menu_link_name_sponsored',
    right: FeaturesRights.SPONSORED,
    id: 'sponsored',
    dropdown: [
      {
        name: 'menu_link_name_sponsored_analytics',
        link: SPONSORED_ANALYTICS_ROUTE,
        right: FeaturesRights.SPONSORED_ANALYTICS,
        id: 'sponsored_analytics',
        selectorBar: {
          allowMultipleBrandSelection: true,
          allowPosSelection: true,
          allowDateSelection: true,
        },
      },
      {
        name: 'menu_link_name_sponsored_campaigns',
        link: SPONSORED_CAMPAIGNS_ROUTE,
        right: FeaturesRights.SPONSORED_CAMPAIGNS,
        id: 'sponsored_campaigns',
        selectorBar: {
          allowMultipleBrandSelection: false,
          allowPosSelection: false,
          allowDateSelection: false,
        },
      },
    ],
    customerAction: CLICK_SPONSORED_NAME,
  },
  {
    name: 'menu_link_name_reports',
    link: REPORTS_ROUTE,
    right: FeaturesRights.REPORTS,
    id: 'reports',
    selectorBar: {
      allowMultipleBrandSelection: false,
      allowPosSelection: false,
      allowDateSelection: false,
    },
  },
  {
    name: 'menu_link_name_admin',
    link: ADMIN_ROUTE,
    right: FeaturesRights.ADMIN,
    id: 'admin',
    selectorBar: {
      allowMultipleBrandSelection: false,
      allowPosSelection: false,
      allowDateSelection: false,
    },
  },
];
