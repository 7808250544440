import cn from 'classnames';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  useCompareDateRangePeriods,
  useDateRangePeriod,
  useIsComparing,
} from '../../../components/date-picker/date-picker.state';
import { Grid, Row, SortDirection, StyleRow } from '../../../components/grid';
import { getComparedRows } from '../../../components/grid/utils/grid-utils';
import { HotelViewControlPanel } from '../../../components/hotel-control-panel';
import { ResultsNotFound } from '../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../components/shown-metrics-selector';
import { Toolbar } from '../../../components/toolbar';
import { SearchTextFilter } from '../../../components/toolbar/search-text-filter';
import { ViewExporter } from '../../../components/view-exporter';
import {
  COLUMN_KEY_CPA_GROUP,
  COLUMN_KEY_I2P_ID,
  COLUMN_KEY_NAME2,
  COLUMN_KEY_PARTNER_REFERENCE,
  COLUMN_KEY_STATUS,
  COLUMN_KEY_URL,
  COLUMN_VALUE_STATUS_PENDING,
  GRID_VIEW_CONFIGURATION,
  HOTEL_BID_OPTION_DROPDOWN,
  HOTEL_DETAILS_ROUTE,
  HOTEL_DETAILS_ROUTE_PARTNER_REF_PARAM,
  HOTEL_VIEW_OPEN_DETAILS,
  HOTEL_VIEW_PREFIX,
  NotificationLevel,
  VIEW_NAME,
} from '../../../constants';
import { ONBOARDING_TOURS } from '../../../constants/onboarding-targets';
import { Alert } from '../../../core-ui/components/alert/alert';
import { BidFormContext } from '../../../features/bid-form/bid-form-context';
import { BidFormUnit, EURO } from '../../../features/bid-form/bid-form.types';
import { useBidForm } from '../../../features/bid-form/use-bid-form';
import { AppContext } from '../../../global/context/app-context';
import { ClickableCellProps, formatColumnsAndRows } from '../../../helpers/grid-formatter';
import { row2Hotel } from '../../../helpers/transformers';
import { getNoOfCalendarDays, isNumber, translate } from '../../../helpers/utils';
import { useHotelViews } from '../../../hooks/use-hotel-views/use-hotel-views';
import { useMaxCPCBidCap } from '../../../hooks/use-max-CPC-cap';
import { useNestedRows } from '../../../hooks/use-nested-rows/use-nested-rows';
import { useShownMetricsSelector } from '../../../hooks/use-shown-metrics-selector';
import { useSortParams } from '../../../hooks/use-sort-params';
import { useViewTable } from '../../../hooks/use-view-table';
import {
  Aggregation,
  BaseNotification,
  BiddingActionType,
  DateRangePeriod,
  FileFormat,
} from '../../../models';
import TrackerService from '../../../services/tracker/tracker-service';
import { AbsoluteRelativeToggle } from '../absolute-relative-toggle';
import { getAverageRows, getAverageRowsForNestedRows } from '../analytics-utils';
import { AverageDataToggle } from '../average-data-toggle';
import styles from '../common-view-styles.module.css';
import { useAbsoluteOrRelativeComparison } from '../use-absolute-or-relative-comparison';
import { useAverageData } from '../use-average-data';
import { useTrackRowExpanded } from '../use-track-row-expanded';
import { withSortTracking } from '../with-sort-tracking';
import hotelViewStyles from './hotel-view.module.css';
import { IncludeNonPerformingDataToggle } from './include-non-performing-data-toggle';
import { useIncludeNonPerformingData } from './use-include-non-performing-data';

export const HotelView: FC = () => {
  const [isComparing] = useIsComparing();
  const { selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();
  const [notification, setNotification] = useState<BaseNotification>();

  const { hotelView, hotelViewWithoutImpressions } = useHotelViews();
  const hotelAggregation = Aggregation.HOTEL;
  const columnId = COLUMN_KEY_I2P_ID;
  const numOfStickyColumns = 3;
  const { activeTrend } = useAbsoluteOrRelativeComparison();
  const { isAverageDataActive, setAverageDataActive } = useAverageData();
  const [globalCompareDateRangePeriods] = useCompareDateRangePeriods();
  const {
    columnKey: sortBy,
    direction: orderBy,
    setSortParams,
  } = useSortParams(['sortBy', 'orderBy']);

  const [bidFormValue, setBidFormValue] = useState('');
  const [bidFormUnit, setBidFormUnit] = useState<BidFormUnit>(EURO);
  const { maxCPCBidCap } = useMaxCPCBidCap();

  const requestPayload = {
    locales: selectedLocales,
    from,
    to,
  };

  const { includeNonPerformingData, setIncludeNonPerformingData } = useIncludeNonPerformingData();

  const {
    table: { columns, data, total },
    resetData,
    isLoading: isLoadingParentRows,
    retry,
    setSearch,
    pagination: { pagination, setPrevPage, setNextPage, setNumItemsPerPage },
    selection: {
      selectedPages,
      setSelectedPages,
      selectedRowsById,
      setSelectedRowsById,
      selectRow,
    },
    filters: { allActiveFilters, hasGlobalFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useViewTable(hotelView, hotelAggregation, requestPayload, {
    columnId,
    biddingTypeId: COLUMN_KEY_CPA_GROUP,
    combineDatePeriods: true,
    includeNonPerformingData,
  });

  const [selectedItem, setSelectedItem] = useState<Row>();
  const clickHotelName = (row: Row) => {
    setSelectedItem(row);
    const { trvId, partnerRef } = row2Hotel(row);
    TrackerService.track(HOTEL_VIEW_OPEN_DETAILS, { hotel: { partnerRef, trvId } });
  };

  const columnCellClickActions = () => {
    const columnCellActionsMap: { [columnKey: string]: ClickableCellProps } = {
      [COLUMN_KEY_URL]: {
        action: (row: Row) => {
          window.open(row[COLUMN_KEY_URL], '_blank');
        },
        displayValue: () => translate('analytics_hotel_url_link_text'),
      },
    };

    columnCellActionsMap[COLUMN_KEY_NAME2] = {
      action: clickHotelName,
      displayValue: (row: Row) => row[COLUMN_KEY_NAME2] || '-',
    };
    return columnCellActionsMap;
  };

  const { nestedRows: rows, isLoading: isLoadingNestedRows } = useNestedRows({
    view: hotelView,
    aggregation: hotelAggregation,
    payload: requestPayload,
    filters: allActiveFilters,
    parentRows: data.rows,
    columnId,
  });

  const isLoading = isLoadingParentRows || isLoadingNestedRows;

  let dataRows = rows;

  if (!isComparing && isAverageDataActive) {
    dataRows = getAverageRows(dataRows, getNoOfCalendarDays(from, to));
  }

  if (isComparing && isAverageDataActive) {
    const allDateRanges: Omit<DateRangePeriod, 'period'>[] = [
      { from, to },
      ...globalCompareDateRangePeriods,
    ];

    dataRows = dataRows.map(row => ({
      ...row,
      nested: getAverageRowsForNestedRows(row.nested, allDateRanges),
    }));
  }

  if (isComparing && dataRows[0]?.nested?.length === 2) {
    dataRows = dataRows.map(row => ({
      ...row,
      nested: getComparedRows(row.nested, columns, activeTrend),
    }));
  }

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: dataRows,
    total,
    id: columnId,
    isShowingCheckbox: true,
    customColumns: {
      columnCellClickActions: columnCellClickActions(),
      liveBid: {
        key: 'bid_cpc',
        children: (value: number, row: Row) => <LiveBidCpcCell value={value} row={row} />,
      },
    },
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(
      hotelView,
      numOfStickyColumns,
      columns,
      formattedColumns,
      isAverageDataActive,
    );

  const isHotelPending = (row: Row) => {
    const status = row[COLUMN_KEY_STATUS];
    if (!status) return false;
    return status.toLowerCase().indexOf(COLUMN_VALUE_STATUS_PENDING) !== -1;
  };

  const selectedHotelRows = useMemo(
    () => Array.from(selectedRowsById.values()).map(row2Hotel),
    [selectedRowsById],
  );

  useEffect(() => {
    if (bidFormUnit === EURO) {
      if (selectedHotelRows.length === 1) {
        setBidFormValue(selectedHotelRows[0].bidCPC?.toString());
      } else {
        setBidFormValue('');
      }
    }
  }, [selectedHotelRows, bidFormUnit]);

  const viewExporter = () => (
    <ViewExporter
      key="key-view-exporter"
      title={translate('analytics_home_export_report')}
      exportOptions={[
        {
          text: translate('analytics_toolbar_export_performance_grid'),
          fileFormat: FileFormat.CSV,
        },
        {
          text: translate('analytics_toolbar_export_performance_grid_excel'),
          fileFormat: FileFormat.EXCEL,
        },
        {
          text: translate('analytics_toolbar_export_without_impressions_performance_grid'),
          fileFormat: FileFormat.CSV,
          payload: {
            view: hotelViewWithoutImpressions,
            aggregation: Aggregation.HOTEL_WITHOUT_IMPRESSIONS,
            filters: allActiveFilters,
          },
        },
        {
          text: translate('analytics_toolbar_export_without_impressions_performance_grid_excel'),
          fileFormat: FileFormat.EXCEL,
          payload: {
            view: hotelViewWithoutImpressions,
            aggregation: Aggregation.HOTEL_WITHOUT_IMPRESSIONS,
            filters: allActiveFilters,
          },
        },
      ]}
      view={hotelView}
      columns={columns}
      aggregation={hotelAggregation}
      filters={allActiveFilters}
      sort={sort}
    />
  );

  const trackRowExpanded = useTrackRowExpanded('Hotel');

  const filters = [
    <SearchTextFilter
      key="key-search-text-filters"
      placeholder={translate('analytics_home_search_item_hotels')}
      onSearch={filterTmp => setSearch(filterTmp !== undefined ? [filterTmp] : [])}
      searchableColumnNames={[COLUMN_KEY_NAME2, COLUMN_KEY_PARTNER_REFERENCE]}
    />,
  ];

  if (isComparing)
    filters.push(<AbsoluteRelativeToggle key="absolute-relative-toggle" viewName="Hotel" />);

  filters.push(
    <span data-tour={ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.STEP_4}>
      <AverageDataToggle
        key="average-data-toggle"
        value={isAverageDataActive}
        setValue={setAverageDataActive}
        tooltip={translate('average_data_tooltip')}
      />
    </span>,
  );

  const handleBidFormUnitChange = (unit: BidFormUnit) => {
    setBidFormUnit(unit);
    TrackerService.track(HOTEL_BID_OPTION_DROPDOWN, {
      option: translate(
        unit === EURO ? 'bid_form_cpc_set_to' : 'bid_form_cpc_change_by_percentage',
      ),
    });
  };

  const sortAndOrderByParams = sortBy && orderBy ? `?sortBy=${sortBy}&orderBy=${orderBy}` : '';

  return (
    <>
      {selectedItem !== undefined && (
        <Redirect
          to={{
            pathname: HOTEL_DETAILS_ROUTE.replace(
              HOTEL_DETAILS_ROUTE_PARTNER_REF_PARAM,
              selectedItem.pid,
            ),
            search: sortAndOrderByParams,
          }}
        />
      )}
      <div className={styles.toolbarWrapper}>
        <Toolbar
          filters={filters}
          actions={[
            <IncludeNonPerformingDataToggle
              value={includeNonPerformingData}
              setValue={setIncludeNonPerformingData}
              tooltip={translate('include_non_performing_data_tooltip_hotel_view')}
            />,
            <ShownMetricsSelector
              key="key-shown-metrics-filter"
              options={availableMetrics}
              selectedByDefaultOptions={shownMetrics}
              view={hotelView}
              onApply={setShownMetrics}
            />,
            viewExporter(),
          ]}
        />
        <BidFormContext.Provider
          value={{
            value: bidFormValue,
            setValue: setBidFormValue,
            unit: bidFormUnit,
            setUnit: handleBidFormUnitChange,
            bidItems: selectedHotelRows.map(hotel => ({
              baseValue: hotel.bidCPC,
              biddingType: 0,
              capValue: maxCPCBidCap ?? 6,
              minValue: 0,
              name: hotel.name,
              id: hotel.partnerRef,
            })),
          }}
        >
          {selectedRowsById.size > 0 && (
            <HotelViewControlPanel
              hotels={selectedHotelRows}
              onUpdateTable={() => {
                resetData();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              onShowNotification={(newNotification: BaseNotification) => {
                setNotification(newNotification);
              }}
              onClickLeading={() => {
                setSelectedRowsById(new Map());
                setSelectedPages(new Set());
              }}
              trackingPrefix={HOTEL_VIEW_PREFIX}
              biddingActionType={BiddingActionType.CustomEditCpc}
            />
          )}
          {renderAlertFor(notification, () => setNotification(undefined))}
          <div className={styles.gridWrapper}>
            <Grid
              numStickyColumns={numOfStickyColumns}
              columns={visibleFormattedColumns}
              rows={formattedRows}
              footerRows={formattedFooterRows}
              isLoading={isLoading}
              configuration={GRID_VIEW_CONFIGURATION}
              initialData={{
                initialSelectedRowsId: new Set(selectedRowsById.keys()),
                initialSelectedPages: selectedPages,
              }}
              selectableRow={{
                onSelectPage: selectedPagesTmp => setSelectedPages(selectedPagesTmp),
                onSelectRow: selectRow,
              }}
              onSort={withSortTracking((columnName: string, direction: SortDirection) => {
                setSort({ columnName, direction });
                setSortParams({ sortBy: columnName, orderBy: direction });
              }, VIEW_NAME[hotelView])}
              pagination={{
                page: pagination,
                totalItems: data.count,
                onPreviousPage: setPrevPage,
                onNextPage: setNextPage,
                onUpdateRowsPerPage: setNumItemsPerPage,
              }}
              rowStyles={[
                {
                  style: StyleRow.BOLD,
                  condition: isHotelPending,
                },
              ]}
              resultsNotFound={
                <ResultsNotFound
                  isButtonEnabled={hasGlobalFilters && !isLoading}
                  onClick={clearGlobalFilters}
                  retry={retry}
                />
              }
              isAccordionTable={isComparing}
              onExpandRow={isExpanded => {
                if (isExpanded) trackRowExpanded();
              }}
            />
          </div>
        </BidFormContext.Provider>
      </div>
    </>
  );
};

const LiveBidCpcCell: FC<{ value: number; row: Row }> = ({ value, row }) => {
  const { newValue, hasWarning } = useBidChangeFor(row.pid);

  if (newValue === undefined) {
    return (
      <span>
        {value} {EURO}
      </span>
    );
  }
  return (
    <span>
      {value} {EURO} to{' '}
      <b className={cn({ [hotelViewStyles.cpaInvalidValue]: hasWarning })}>
        {newValue.toFixed(2)} {EURO}
      </b>
    </span>
  );
};

function useBidChangeFor(id: string | number): {
  newValue: number | undefined;
  hasWarning: boolean;
} {
  const { adjustedBidItems, isFormInvalid } = useBidForm(false);

  const item = adjustedBidItems.find(it => it.id === id.toString());
  const hasWarning = item ? item.isBelow || item.isExceeded : false;
  const isValueInvalid =
    !item || (!isNumber(item.acceptedValue) && (isFormInvalid || !item.acceptedValue));
  if (isValueInvalid) {
    return { newValue: undefined, hasWarning };
  }
  return { newValue: item!.acceptedValue, hasWarning };
}

function alertVariantOf(level: NotificationLevel) {
  switch (level) {
    case NotificationLevel.ERROR:
      return 'error';
    case NotificationLevel.WARNING:
      return 'warning';
    default:
      return 'success';
  }
}

function renderAlertFor(notification: BaseNotification | undefined, onClose: () => void) {
  if (!notification) return null;

  const errorMessage =
    notification.level === NotificationLevel.ERROR ? notification.message : undefined;
  const variant = alertVariantOf(notification.level);

  return (
    <Alert
      variant={variant}
      message={errorMessage as string}
      onClose={onClose}
      style={{ marginTop: 12 }}
    >
      {variant === 'success' || variant === 'warning' ? (
        <>
          <b>{notification.message[0]}</b>
          <p>
            {notification.message[1]} <Link to="history">History</Link>
          </p>
        </>
      ) : null}
    </Alert>
  );
}
