import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { translate } from '../../helpers/utils';

type PreventNavigationPromptProps = {
  when?: boolean;
  message?: string;
};

export const PreventNavigationPrompt = ({
  when = true,
  message = translate('prevent_navigation_prompt_message'),
}: PreventNavigationPromptProps) => {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (!when) return;

    const preventUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [when, message]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const proceed = window.confirm(message);
      if (proceed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker, message]);

  return null;
};
