/* eslint-disable @typescript-eslint/no-empty-function */
import cloneDeep from 'lodash.clonedeep';
import { FC, useContext, useEffect, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { useModal } from 'react-modal-hook';
import { Loading } from '../../components/loading';
import { DASHBOARD_WELCOME_BANNER, NotificationLevel, TimeOption } from '../../constants';
import { useSnackbar } from '../../global/app/snackbar/use-snackbar';
import { AppContext } from '../../global/context/app-context';
import { useFilters } from '../../global/context/filter-context';
import ApiDashboardHelper from '../../helpers/api/dashboard/api-dashboard-helper';
import { getDimensionFiltersFromLocalStorage } from '../../helpers/local-storage-helper';
import { layouts2widgets, widgets2layouts } from '../../helpers/transformers';
import { translate } from '../../helpers/utils';
import { useWindowDimensions } from '../../hooks/use-window-dimensions';
import {
  DashBoard,
  DashBoardType,
  DashboardWidget,
  DashboardWidgetProps,
  TimelineWidget,
  TimelineWidgetProps,
  WidgetType,
} from '../../models';
import * as bannerService from '../../services/banner-service';
import {
  addNewWidget,
  createDashboard,
  MAX_NUMBER_COLUMNS,
  saveDashboard,
} from '../../services/dashboard-service';
import { showNotification } from '../../services/notification-service';
import { ChartBuilder } from './chart-builder';
import styles from './custom-dashboard.module.css';
import { DashboardCreateModal } from './dashboard-create-modal';
import { DashboardHeader } from './dashboard-header';
import { EmptyDashboard } from './empty-dashboard';
import { GlobalInventoryWidget } from './global-inventory-widget';
import { MbluWidget } from './mblu-widget';
import { PosOverviewWidget } from './pos-overview-widget';
import { TimeLineWidget } from './time-line-widget';
import { useDashboards } from './use-dashboards';
import { useSelectedDashboard } from './use-selected-dashboard';
import { Widget } from './widget';
import { YesterdayOverviewWidget } from './yesterday-overview-widget';
import { OnboardingTour } from '../../core-ui/components/onboarding-tour';

export const CustomDashboard: FC = () => {
  const { partner } = useContext(AppContext);
  const { isLoading, data: dashboards = [], refetch: refetchDashboards } = useDashboards();
  const [dashboard, setDashboard] = useSelectedDashboard(dashboards);
  const [timeOptionSelected, setTimeOptionSelected] = useState(TimeOption.DAY);
  const [fullScreenWidget, setFullScreenWidget] = useState<string | null>(null);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [chartBuilder, setChartBuilder] = useState<{
    widgetType?: WidgetType;
    currentWidget?: DashboardWidget;
  } | null>(null);
  const [showDashboardCreateModal, closeDashboardCreateModal] = useModal(() => {
    return (
      <DashboardCreateModal
        onClose={closeDashboardCreateModal}
        onCreate={create}
        partner={partner}
      />
    );
  });
  const { setFilters, setColumns } = useFilters();

  useEffect(() => {
    setColumns([]);
    setFilters(getDimensionFiltersFromLocalStorage());
  }, [setColumns, setFilters]);

  useEffect(() => {
    if (fullScreenWidget) {
      setScrollHeight(window.scrollY);
      window.scrollTo({ top: 0 });
    } else {
      setTimeout(() => window.scrollTo({ top: scrollHeight }), 300);
    }
  }, [fullScreenWidget, scrollHeight]);

  useEffect(() => {
    setFullScreenWidget(null);
  }, [dashboard]);

  useEffect(() => {
    const bannerSetup = () => {
      if (!bannerService.isDefined(DASHBOARD_WELCOME_BANNER)) {
        bannerService.set(DASHBOARD_WELCOME_BANNER);
      }
    };
    bannerSetup();
  }, []);

  useEffect(() => {
    if (fullScreenWidget) {
      setScrollHeight(window.scrollY);
      window.scrollTo({ top: 0 });
    } else {
      setTimeout(() => window.scrollTo({ top: scrollHeight }), 300);
    }
  }, [fullScreenWidget, scrollHeight]);

  let currentWidgets = dashboard?.widgets || [];

  if (fullScreenWidget) {
    currentWidgets = currentWidgets.filter(widget => widget.uuid === fullScreenWidget);
  }

  const create = async (name: string, type: DashBoardType) => {
    await createDashboard(partner.partnerId, name, type);
    await refetchDashboards();
  };

  const editDashboard = async (
    newWidgets: DashboardWidget[],
    checkIsEqual: boolean = true,
    customDashboard?: DashBoard,
  ) => {
    if (!dashboard) return;
    try {
      const dashboardToEdit = customDashboard || dashboard;
      const savedDashboard = await saveDashboard(
        partner.partnerId,
        dashboardToEdit,
        newWidgets,
        checkIsEqual,
      );
      await refetchDashboards();
      setDashboard(savedDashboard);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const onEditWidget = async (widgetUuid: string, props: any) => {
    const dashboardTmp = cloneDeep(dashboard!);
    const widgetToEditIndex = dashboardTmp!.widgets.findIndex(
      currentWidgetTmp => currentWidgetTmp.uuid === widgetUuid,
    );

    if (widgetToEditIndex === -1) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate('dashboard_edit_widget_error'),
      });
    }

    dashboardTmp.widgets[widgetToEditIndex].props = {
      ...dashboardTmp.widgets[widgetToEditIndex].props,
      ...props,
    };

    if (chartBuilder) {
      setChartBuilder(prev => ({
        ...prev,
        currentWidget: dashboardTmp.widgets[widgetToEditIndex],
      }));
    }
    return await editDashboard(dashboardTmp.widgets, false);
  };

  const onAddWidget = async (
    widgetType: WidgetType,
    skipScroll?: boolean,
    widgetProps?: DashboardWidgetProps,
    otherDashboard?: DashBoard,
  ) => {
    try {
      setFullScreenWidget(null);
      const widgets = otherDashboard?.widgets || dashboard?.widgets || [];
      const widgetToPersist = addNewWidget(widgets, widgetType, widgetProps);
      !skipScroll && window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      await editDashboard(widgetToPersist, true, otherDashboard);

      widgetType === WidgetType.CHART_BUILDER &&
        setChartBuilder(prev => ({
          ...prev,
          currentWidget: widgetToPersist.find(
            widget =>
              (widget as TimelineWidget).props.name === (widgetProps as TimelineWidgetProps)?.name,
          ),
        }));
      return true;
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
      return false;
    }
  };

  const { showSnackbarMessage } = useSnackbar();
  const onRemoveWidget = async (uuid: string, name?: string) => {
    setFullScreenWidget(null);
    const widgets = dashboard?.widgets || [];
    const isWidgetRemoved = await editDashboard(widgets.filter(w => w.uuid !== uuid)).then(
      () => true,
    );
    isWidgetRemoved &&
      showSnackbarMessage({
        id: `delete-success-${uuid}`,
        message: name ? `${name} deleted` : 'Widget deleted',
        type: 'success',
      });
  };
  const { width } = useWindowDimensions();

  const removeDashboard = async (dashboardId: number) => {
    try {
      await ApiDashboardHelper.delete(partner.partnerId, dashboardId);
      await refetchDashboards();
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const resetDashboard = async () => {
    try {
      await ApiDashboardHelper.reset(partner.partnerId, dashboard!.id);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const onEditName = async (name: string) => {
    try {
      const dashboardTmp = cloneDeep(dashboard!);

      dashboardTmp.name = name;

      const savedDashboard = await saveDashboard(
        partner.partnerId,
        dashboardTmp,
        dashboardTmp.widgets,
        false,
      );

      await refetchDashboards();
      setDashboard(savedDashboard);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const deleteChartBuilderWidget = (widgetName: string) => {
    const widgetToDelete = dashboard?.widgets.find(
      widget =>
        widget.type === WidgetType.CHART_BUILDER &&
        'name' in widget.props &&
        widget.props.name === widgetName,
    );
    void onRemoveWidget(widgetToDelete!.uuid, widgetName);
    setChartBuilder(null);
  };

  if (chartBuilder) {
    return (
      <div className={styles.customDashboard}>
        <ChartBuilder
          currentDashboard={dashboard}
          currentWidget={chartBuilder?.currentWidget}
          setDashboard={setDashboard}
          onClose={() => setChartBuilder(null)}
          save={async (widgetProps?: TimelineWidgetProps, otherDashboard?: DashBoard) =>
            await onAddWidget(
              chartBuilder.widgetType || WidgetType.CHART_BUILDER,
              true,
              widgetProps,
              otherDashboard,
            )
          }
          edit={(widgetProps: TimelineWidgetProps) => {
            return onEditWidget(chartBuilder?.currentWidget?.uuid || '', cloneDeep(widgetProps));
          }}
          deleteChartWidget={deleteChartBuilderWidget}
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.customDashboard}>
        <Loading isWhite={false} message={translate('dashboard_loading_data')} />
      </div>
    );
  }

  return (
    <div className={styles.customDashboard}>
      {dashboard !== undefined && (
        <DashboardHeader
          selectedDashboard={dashboard}
          dashboards={dashboards}
          timeOptionSelected={timeOptionSelected}
          onSelectTimeOption={option => setTimeOptionSelected(option)}
          onSelectAddWidget={async widgetType => {
            if (widgetType === WidgetType.CHART_BUILDER)
              setChartBuilder({ widgetType: WidgetType.CHART_BUILDER });
            else await onAddWidget(widgetType);
          }}
          onReset={resetDashboard}
          onChangeName={onEditName}
          onCreateDashboard={showDashboardCreateModal}
          onRemove={() => removeDashboard(dashboard.id)}
          onSelectDashboard={setDashboard}
        />
      )}
      {dashboard && currentWidgets.length > 0 ? (
        <ReactGridLayout
          className={styles.gridLayout}
          layout={widgets2layouts(currentWidgets, fullScreenWidget ? MAX_NUMBER_COLUMNS : null)}
          cols={MAX_NUMBER_COLUMNS}
          rowHeight={fullScreenWidget ? 700 : 365}
          margin={[16, 16]}
          width={width}
          onLayoutChange={layouts =>
            !fullScreenWidget && editDashboard(layouts2widgets(layouts, currentWidgets))
          }
        >
          {currentWidgets.map(widget => {
            return widget.type === WidgetType.TIMELINE ||
              widget.type === WidgetType.CHART_BUILDER ? (
              <div key={widget.uuid}>
                <TimeLineWidget
                  timeOption={timeOptionSelected}
                  onEditWidget={widgetProps => {
                    void onEditWidget(widget.uuid, cloneDeep(widgetProps));
                  }}
                  onRemoveWidget={() => onRemoveWidget(widget.uuid)}
                  onFullScreen={() => setFullScreenWidget(fullScreenWidget ? null : widget.uuid)}
                  isFullScreen={!!fullScreenWidget}
                  onExploreChart={() =>
                    setChartBuilder(prev => ({
                      ...prev,
                      currentWidget: widget,
                    }))
                  }
                  widgetInfo={widget as TimelineWidget}
                  onTitleEdit={async text => onEditWidget(widget.uuid, { name: text })}
                />
              </div>
            ) : widget.type === WidgetType.YESTERDAY_OVERVIEW ? (
              <div key={widget.uuid}>
                <YesterdayOverviewWidget onRemoveWidget={() => onRemoveWidget(widget.uuid)} />
              </div>
            ) : widget.type === WidgetType.GLOBAL_INVENTORY ? (
              <div key={widget.uuid}>
                <GlobalInventoryWidget onRemoveWidget={() => onRemoveWidget(widget.uuid)} />
              </div>
            ) : widget.type === WidgetType.POS_OVERVIEW ? (
              <div key={widget.uuid}>
                <PosOverviewWidget onRemoveWidget={() => onRemoveWidget(widget.uuid)} />
              </div>
            ) : widget.type === WidgetType.MBLU ? (
              <div key={widget.uuid}>
                <MbluWidget
                  timeOption={timeOptionSelected}
                  onRemoveWidget={() => onRemoveWidget(widget.uuid)}
                  onFullScreen={() => setFullScreenWidget(fullScreenWidget ? null : widget.uuid)}
                  isFullScreen={!!fullScreenWidget}
                />
              </div>
            ) : (
              <div key={widget.uuid}>
                <Widget
                  key={widget.uuid}
                  title={widget.uuid}
                  menuItems={[]}
                  onSelectMenuItem={() => {}}
                >
                  {widget.type}
                </Widget>
              </div>
            );
          })}
        </ReactGridLayout>
      ) : (
        <EmptyDashboard />
      )}
    </div>
  );
};
