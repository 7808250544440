import { CSSProperties, FC, PropsWithChildren } from 'react';
import { Loading } from './loading';

interface LoadingOverlayProps {
  isLoading: boolean;
  style?: CSSProperties;
  className?: string;
}

export const LoadingOverlay: FC<LoadingOverlayProps & PropsWithChildren> = props => {
  const { isLoading, className = '', style = {}, children } = props;

  return (
    <div className={className} style={{ ...style, position: 'relative' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,0.65)',
            zIndex: 1,
          }}
        >
          <Loading isWhite={false} />
        </div>
      )}
      {children}
    </div>
  );
};
