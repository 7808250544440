import { FC, useContext, useEffect, useState } from 'react';
import { ExclamationCircleFillDarkIcon } from 'tcp-react-icons';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';
import { ColumnOnboardingTooltip, Grid, SortDirection, StyleRow } from '../../../components/grid';
import { ConditionalStyledRow } from '../../../components/grid/grid-row';
import { DropdownOption } from '../../../components/multi-select-dropdown';
import { PopoverDirection } from '../../../components/popover';
import { ResultsNotFound } from '../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../components/shown-metrics-selector';
import { Toolbar } from '../../../components/toolbar';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { ViewExporter } from '../../../components/view-exporter';
import {
  BM_VIEW_DIMENSION,
  COLUMN_KEY_CPA_GROUP,
  COLUMN_MODIFIER_PENDING_VALUE,
  GRID_VIEW_CONFIGURATION,
  VIEW_NAME,
} from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { useFilters } from '../../../global/context/filter-context';
import { formatColumnsAndRows } from '../../../helpers/grid-formatter';
import { translate } from '../../../helpers/utils';
import { usePropertyGroups } from '../../../hooks/use-property-groups';
import { useShownMetricsSelector } from '../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../hooks/use-view-table';
import {
  Aggregation,
  BidModifier,
  BidModifierNameColumns,
  EventType,
  FileFormat,
  FilterColumn,
  MAX_BID_MODIFIER,
  MIN_BID_MODIFIER,
  Row,
  View,
} from '../../../models';
import { propertyGroups2Filter } from '../../../services/analytics-service';
import {
  areMultipleBrandsSelected,
  areMultiplePOSorGlobalSelected,
} from '../../../services/app-service';
import { useRemainingPushes } from '../../history/history-toolbar/use-remaining-pushes';
import { BmControlPanel } from '../bm-control-panel';
import styles from '../common-view-styles.module.css';
import { PropertyGroupsFilter } from '../property-groups-view/filters/property-groups-filter';
import { withSortTracking } from '../with-sort-tracking';
import { useSortParams } from '../../../hooks/use-sort-params';

type Props = {
  view:
    | View.BM_POS_LENGTH_OF_STAY
    | View.BM_POS_TIME_TO_TRAVEL
    | View.BM_POS_COMBINED_MODIFIERS
    | View.BM_POS_DEFAULT_DATE
    | View.BM_POS_GROUP_SIZE;
  aggregation: Aggregation;
  columnsInfo?: BidModifierNameColumns;
  viewTranslationKey: string;
};

export const BmPOSView: FC<Props> = ({ view, aggregation, columnsInfo }) => {
  const { subPartners, selectedLocales, partner } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();
  const propertyGroups = usePropertyGroups();
  const { selectedBiddingTypes } = useFilters();
  const { setSortParams } = useSortParams(['sortBy', 'orderBy']);

  const isManualBiddingTypeSelected = selectedBiddingTypes.includes('Manual');

  const propertyGroupOptions: DropdownOption[] = propertyGroups.map(pg => ({
    key: pg.name,
    value: pg.id,
  }));

  const [selectedPropertyGroupOptions, setSelectedPropertyGroupOptions] =
    useState<DropdownOption[]>(propertyGroupOptions);
  const columnId = columnsInfo !== undefined ? columnsInfo.id : 'id';

  const defaultFilters: FilterColumn[] = isManualBiddingTypeSelected
    ? [propertyGroups2Filter(selectedPropertyGroupOptions.map(option => option.value as number))]
    : [];

  const {
    table: { columns, data, total },
    resetData,
    isLoading,
    retry,
    pagination,
    selection: {
      selectedPages,
      setSelectedPages,
      selectedRowsById,
      setSelectedRowsById,
      selectRow,
    },
    filters: { allActiveFilters, hasGlobalFilters, setCustomFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useViewTable(
    view,
    aggregation,
    {
      locales: selectedLocales,
      from,
      to,
      filters: defaultFilters,
    },
    {
      columnId,
      biddingTypeId: COLUMN_KEY_CPA_GROUP,
    },
  );

  useEffect(() => {
    setSelectedPropertyGroupOptions(propertyGroupOptions);
  }, [selectedLocales]); // eslint-disable-line react-hooks/exhaustive-deps

  const bmPosViews = [
    View.BM_POS_LENGTH_OF_STAY,
    View.BM_POS_TIME_TO_TRAVEL,
    View.BM_POS_DEFAULT_DATE,
    View.BM_POS_GROUP_SIZE,
  ];

  const getNumberOfStickyColumns = () => {
    return view === View.BM_POS_COMBINED_MODIFIERS ? 6 : 4;
  };

  const mapRowToBidModifier = (): BidModifier[] => {
    if (!columnsInfo) {
      return [];
    }
    const cleanField = (text: string) => {
      const pos = text.indexOf('(');
      return pos !== -1 ? text.slice(0, pos) : text;
    };

    return Array.from(selectedRowsById.keys()).map<BidModifier>(id => {
      const row = selectedRowsById.get(id)!;
      return {
        breakoutId: row[columnsInfo.id],
        dimensionId: BM_VIEW_DIMENSION[view],
        value: row[columnsInfo.value],
        field: cleanField(row[columnsInfo.description]),
        pendingStatus: row[columnsInfo.pending] === COLUMN_MODIFIER_PENDING_VALUE,
      };
    });
  };

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: data.rows,
    total,
    id: columnId,
    isShowingCheckbox: bmPosViews.includes(view),
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, getNumberOfStickyColumns(), columns, formattedColumns);

  const isPropertyGroupsDropdownDisabled = () =>
    areMultiplePOSorGlobalSelected(selectedLocales) ||
    areMultipleBrandsSelected(subPartners) ||
    !isManualBiddingTypeSelected;

  const {
    isLoading: isLoadingPushes,
    error,
    data: remainingPushesData,
  } = useRemainingPushes(partner.partnerId);
  const bmRemainingPushes = remainingPushesData ? remainingPushesData[EventType.PUSH_BM] : 0;

  const getColumnOnboardingTooltips = () => {
    const columnOnboardingTooltips: ColumnOnboardingTooltip[] = [];
    if ([View.BM_POS_TIME_TO_TRAVEL, View.BM_POS_COMBINED_MODIFIERS].includes(view)) {
      columnOnboardingTooltips.push({
        text: translate('bm_control_panel_ttt_1_onboarding_tooltip'),
        column: formattedColumns.find(column => column.key === 'bm_ttt_breakout_view')!!,
        position: PopoverDirection.TRAILING_TOP,
      });
    }
    return columnOnboardingTooltips;
  };

  const rowStyleForPendingModifiers: ConditionalStyledRow = {
    style: StyleRow.BOLD,
    condition: (row: Row) =>
      columnsInfo !== undefined &&
      row[columnsInfo.pending] !== undefined &&
      row[columnsInfo.pending] === COLUMN_MODIFIER_PENDING_VALUE,
  };

  return (
    <div className={styles.toolbarWrapper}>
      {selectedRowsById.size > 0 &&
        (view === View.BM_POS_LENGTH_OF_STAY ||
          view === View.BM_POS_TIME_TO_TRAVEL ||
          view === View.BM_POS_DEFAULT_DATE ||
          view === View.BM_POS_GROUP_SIZE) &&
        !isLoadingPushes &&
        !error && (
          <BmControlPanel
            view={view}
            bidModifiers={mapRowToBidModifier()}
            propertyGroups={selectedPropertyGroupOptions.map(option => option.value as number)}
            validations={{
              maxValue: MAX_BID_MODIFIER,
              minValue: MIN_BID_MODIFIER,
            }}
            onBidModifiersApplied={resetData}
            onClose={() => {
              setSelectedRowsById(new Map());
              setSelectedPages(new Set());
            }}
            bmRemainingPushes={bmRemainingPushes}
          />
        )}
      <Toolbar
        filters={[
          <div key="filter-property-groups" style={{ display: 'flex' }}>
            <PropertyGroupsFilter
              selectedIds={selectedPropertyGroupOptions.map(o => Number(o.value))}
              onApply={ids => {
                setSelectedPropertyGroupOptions(
                  propertyGroupOptions.filter(o => ids.includes(o.value as number)),
                );
                setCustomFilters([propertyGroups2Filter(ids)]);
                resetData();
              }}
              disabledMessage={
                isPropertyGroupsDropdownDisabled()
                  ? translate('analytics_property_group_dropdown_disabled_tooltip')
                  : undefined
              }
            />
            <TooltipWrapper
              position={TooltipPosition.TOP_LEADING}
              noTinyPaddingTop={true}
              message={translate('analytics_property_group_dropdown_information_tooltip')}
            >
              <ExclamationCircleFillDarkIcon
                className="u-color--juri-very-light u-margin-left--small u-margin-top--medium u-margin-right--large"
                width={14}
                height={14}
              />
            </TooltipWrapper>
          </div>,
        ]}
        actions={[
          <ShownMetricsSelector
            key="key-metrics-selector"
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
          />,
          <ViewExporter
            key="key-view-exporter"
            title={translate('analytics_home_export_report')}
            exportOptions={[
              {
                text: translate('analytics_toolbar_export_performance_grid'),
                fileFormat: FileFormat.CSV,
              },
              {
                text: translate('analytics_toolbar_export_performance_grid_excel'),
                fileFormat: FileFormat.EXCEL,
              },
            ]}
            view={view}
            columns={columns}
            aggregation={aggregation}
            filters={allActiveFilters}
            sort={sort}
          />,
        ]}
      />
      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={getNumberOfStickyColumns()}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          initialData={{
            initialSelectedRowsId: new Set(selectedRowsById.keys()),
            initialSelectedPages: selectedPages,
          }}
          selectableRow={{
            onSelectPage: selectedPagesTmp => setSelectedPages(selectedPagesTmp),
            onSelectRow: selectRow,
          }}
          onSort={withSortTracking((columnName: string, direction: SortDirection) => {
            setSort({ columnName, direction });
            setSortParams({ sortBy: columnName, orderBy: direction });
          }, VIEW_NAME[view])}
          pagination={{
            page: pagination.pagination,
            totalItems: data.count,
            onPreviousPage: pagination.setPrevPage,
            onNextPage: pagination.setNextPage,
            onUpdateRowsPerPage: pagination.setNumItemsPerPage,
          }}
          rowStyles={[rowStyleForPendingModifiers]}
          resultsNotFound={
            <ResultsNotFound
              isButtonEnabled={hasGlobalFilters && !isLoading}
              onClick={clearGlobalFilters}
              retry={retry}
            />
          }
          columnOnboardingTooltips={getColumnOnboardingTooltips()}
        />
      </div>
    </div>
  );
};
