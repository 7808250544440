import { Props } from './sponsored-campaigns.types';
import './sponsored-campaigns.css';
import { Card } from '../../../components/card';
import { Toolbar } from '../../../components/toolbar';
import { filterListWithPosBySearchText, translate } from '../../../helpers/utils';
import { ButtonDropdownFileUploader } from '../../../components/button-dropdown-file-uploader';
import { hasUserBiddingRights } from '../../../services/app-service';
import { AppContext } from '../../../global/context/app-context';
import { Aggregation, View } from '../../../models';
import { useContext, useState, FC } from 'react';
import {
  COLUMN_SPONSORED_CAMPAIGNS_ID,
  COLUMN_SPONSORED_CAMPAIGNS_LOCALE_CODE,
  COLUMN_SPONSORED_POS,
  GRID_VIEW_CONFIGURATION,
  POS_GLOBAL_VALUE,
} from '../../../constants';
import { useSimpleViewTable } from '../../../hooks/use-simple-view-table';
import { InputIcon } from '../../../components/input-icon';
import { CloseIcon, SearchLineDarkIcon } from 'tcp-react-icons';
import { ColumnBase, Grid } from '../../../components/grid';
import { ResultsNotFound } from '../../../components/results-not-found';
import { formatColumnsAndRows } from '../../../helpers/grid-formatter';
import { LocaleColumn } from '../../../components/locale-column';
import { SponsoredRevert } from './sponsored-revert';
import { useSlBidFileUploaderOptions } from './use-sl-bid-file-uploader-options';

export const SponsoredCampaigns: FC<Props> = () => {
  const { user, locales } = useContext(AppContext);
  const [search, setSearch] = useState('');

  const {
    table: {
      columns,
      data: { rows },
    },
    pagination: { pagination },
    isLoading,
    retry,
  } = useSimpleViewTable(
    View.SPONSORED_CAMPAIGNS,
    {
      aggregation: Aggregation.POS,
      localeCodes: [POS_GLOBAL_VALUE],
    },
    {
      columnId: COLUMN_SPONSORED_CAMPAIGNS_ID,
      isUsingPagination: false,
    },
  );

  let { formattedColumns, formattedRows } = formatColumnsAndRows({
    columns,
    rows,
    total: undefined,
    id: COLUMN_SPONSORED_CAMPAIGNS_ID,
    customColumns: {
      posLocale: {
        key: COLUMN_SPONSORED_CAMPAIGNS_LOCALE_CODE,
        // eslint-disable-next-line react/display-name
        children: (data: { localeCode: string; name: string }) => {
          return (
            <LocaleColumn
              pos={{
                localeCode: data.localeCode,
                name: data.name,
              }}
              styles={{
                font: 'u-color--juri u-font-size--tiny',
              }}
            />
          );
        },
      },
    },
  });

  formattedColumns = formattedColumns.map(columnTmp => {
    (columnTmp as ColumnBase).isSortable = false;
    return columnTmp;
  });
  formattedRows = formattedRows.map(rowTmp => {
    const locale = locales.find(
      ({ localeCode }) => localeCode === rowTmp[COLUMN_SPONSORED_CAMPAIGNS_LOCALE_CODE],
    );
    const localeCode = rowTmp[COLUMN_SPONSORED_CAMPAIGNS_LOCALE_CODE];
    rowTmp[COLUMN_SPONSORED_POS] = {
      localeCode: localeCode,
      name: locale !== undefined ? locale.name : localeCode,
    };
    rowTmp[COLUMN_SPONSORED_CAMPAIGNS_LOCALE_CODE] = {
      localeCode: localeCode,
      name: locale !== undefined ? locale.name : localeCode,
    };
    return rowTmp;
  });

  const filteredRows = (formattedRows as any[]).filter(filterListWithPosBySearchText(search));

  const slBidFileUploaderOptions = useSlBidFileUploaderOptions();

  return (
    <Card className="c-sponsored-campaigns u-padding-left--gutter u-padding-right--gutter u-padding-top--gutter">
      <h2 className="u-padding-left--gutter u-padding-right--gutter u-padding-bottom--gutter">
        {translate('sponsored_campaigns__title')}
      </h2>
      <div>
        <Toolbar
          filters={[
            <InputIcon
              key="express-booking-input-icon"
              icon={search.length === 0 ? SearchLineDarkIcon : CloseIcon}
              className="c-input u-border-radius u-width--100 u-font-family-arial c-campaigns__input"
              placeholder={translate('campaign_dashboard__search_placeholder')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onClickIcon={() => {
                if (search.length !== 0) {
                  setSearch('');
                }
              }}
              value={search}
            />,
          ]}
          actions={[
            <SponsoredRevert key="sponsored-revert" />,
            hasUserBiddingRights(user) ? (
              <ButtonDropdownFileUploader
                key="upload-bids-key"
                options={slBidFileUploaderOptions}
                classNameButton="c-sponsored-campaigns__button-background c-sponsored-campaigns__button-color"
              />
            ) : null,
          ]}
        />
      </div>
      <div className="u-padding-left--gutter u-padding-right--gutter c-sponsored-campaigns">
        <Grid
          columns={formattedColumns}
          numColumnsLeftAlignment={3}
          rows={filteredRows}
          pagination={{
            page: pagination,
            isHidden: true,
          }}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          resultsNotFound={<ResultsNotFound retry={retry} />}
        />
      </div>
    </Card>
  );
};
