import { FC } from 'react';
import { ButtonDropdown, ButtonDropdownOption } from '../button-dropdown';
import styles from './tabs.module.css';
import cn from 'classnames';
import { Tab, TabWithContent, TabsNavigationProps, TabsProps } from './tabs.types';
import { OnboardingTooltipContainer } from '../onboarding-tooltip/onboarding-tooltip-container';
import { Tooltip } from '../../core-ui/components/tooltip/tooltip';
import { translate } from '../../helpers/utils';
import { View } from '../../models';
import { useIsComparing } from '../date-picker/date-picker.state';

export const Tabs: FC<TabsProps> = props => {
  const { tabs, leading, onTabChange, activeTabKey } = props;
  const tabsWithContent = mapOfTabsWithContent(tabs);

  const changeTab = (newTabKey: string) => onTabChange(tabsWithContent[newTabKey]);

  const content = tabsWithContent[activeTabKey]?.content;

  if (!content) {
    console.warn(`Content for ${activeTabKey} is undefined!`);
  }

  return (
    <div className={styles.tabs}>
      <TabsNavigation
        tabs={tabs}
        activeTabKey={activeTabKey}
        changeTab={changeTab}
        leading={leading}
      />
      <section className={styles.content}>{content}</section>
    </div>
  );
};

const TabsNavigation: FC<TabsNavigationProps> = ({ tabs, activeTabKey, changeTab, leading }) => {
  const [isComparing] = useIsComparing();

  const enabledTabs = [View.HOTEL, View.POS, View.TIME];

  const shouldHideTooltip = (key: string) =>
    !isComparing || (isComparing && enabledTabs.includes(Number(key)));

  return (
    <ul className={styles.tabsNavigation}>
      {leading ? <li className={styles.leading}>{leading}</li> : null}
      {tabs.map(tab => (
        <Tooltip
          key={tab.key}
          title={translate('analytics_date_compare_disabled_tooltip')}
          position={'bottomCenter'}
          isHidden={shouldHideTooltip(tab.key)}
        >
          <li key={tab.key}>
            <TabLabel tab={tab} activeTabKey={activeTabKey} changeTab={changeTab} />
            {tab.onboarding && (
              <OnboardingTooltipContainer
                onboardingKey={tab.onboarding?.key}
                onboardingText={tab.onboarding?.text}
              />
            )}
          </li>
        </Tooltip>
      ))}
    </ul>
  );
};

const TabLabel: FC<{ tab: Tab; activeTabKey: string; changeTab: (key: string) => void }> = ({
  tab,
  activeTabKey,
  changeTab,
}) => {
  if ('group' in tab) {
    const activeTab = tab.group.find(t => t.key === activeTabKey);
    return (
      <ButtonDropdown
        disabled={tab.isDisabled}
        title={activeTab?.label ?? tab.label}
        options={tab.group.map(toOption)}
        onSelectOption={key => changeTab(String(key))}
        classNameButton={cn(styles.tabGroupButton, styles.tab, {
          [styles.active]: !!activeTab,
          [styles.disabled]: tab.isDisabled,
        })}
      />
    );
  }
  return (
    <p
      onClick={() => changeTab(tab.key)}
      className={cn(styles.tab, {
        [styles.active]: activeTabKey === tab.key,
        [styles.disabled]: tab.isDisabled,
      })}
    >
      {tab.label}
    </p>
  );
};

const toOption = (tab: Tab): ButtonDropdownOption => ({
  text: tab.label,
  value: tab.key,
});

function mapOfTabsWithContent(tabs: Tab[]): Record<string, TabWithContent> {
  const result: Record<string, TabWithContent> = {};
  for (const tab of tabs) {
    if ('content' in tab) result[tab.key] = tab;
    if ('group' in tab) Object.assign(result, mapOfTabsWithContent(tab.group));
  }
  return result;
}
