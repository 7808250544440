import { WidgetRowProps } from './widget-row.types';
import './widget-row.css';
import { Tooltip } from '../../../../core-ui/components/tooltip/tooltip';
import ExclamationCircleFillDarkIcon from 'tcp-react-icons/lib/ExclamationCircleFillDarkIcon';
import { TranslationKey, translate } from '../../../../helpers/utils';
import { FC } from 'react';
import { GridCellContextMenu } from '../../../../core-ui/components/grid-cell-context-menu/grid-cell-context-menu';
import styles from './widget-row.module.css';
import cn from 'classnames';
import sharedStyles from '../../../../core-ui/components/grid-cell-context-menu/shared.module.css';

export const WidgetRow: FC<WidgetRowProps> = ({
  field: { text, tooltip },
  children,
  contextMenu,
  applyContextMenu,
  handleContextMenuClick,
}) => {
  const isTooltipAvailable = tooltip !== undefined && tooltip !== '';

  return (
    <tr className={cn('c-widget-row', styles.contextMenuTable, sharedStyles.contextGridRow)}>
      <td className={styles.textCell}>
        <span>{text}</span>
        {isTooltipAvailable && (
          <Tooltip title={translate(tooltip as TranslationKey)} position="rightCenter">
            <ExclamationCircleFillDarkIcon
              className="u-color--juri-very-light"
              width={14}
              height={14}
            />
          </Tooltip>
        )}
      </td>
      {children}
      <td>
        {contextMenu && (
          <div className={(styles.contextMenu, styles.showContextMenu)}>
            <GridCellContextMenu
              gridCell={''}
              menuItems={contextMenu.menuItems}
              rowId={0}
              tooltipTitle="Examine by"
              menuTitle={`Examine performance by:`}
              value={text}
              applyMenuValue={(_value, menu) => {
                if (menu?.id === undefined || !applyContextMenu) return;
                applyContextMenu(menu?.id);
              }}
              tooltipPosition="leftCenter"
              handleMenuClick={handleContextMenuClick}
            />
          </div>
        )}
      </td>
    </tr>
  );
};
