import React from 'react';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { useQueryParam } from '../../../hooks/use-query-param';
import { useReportArtifacts } from '../../../hooks/use-report-artifacts';
import { DateRange } from '../../../models';
import { useErrorNotificationEffect } from '../../../hooks/use-error-notification-effect';
import { Loading } from '../../../components/loading';
import { translate, translateHtml } from '../../../helpers/utils';
import { TeapotAnimation } from '../../../components/teapot-animation';
import { ReportArtifactRow } from './report-artifact-row';

const getIsoDate = (date: Date): string => date.toISOString().substring(0, 10);

export const ReportArtifacts: React.FC<{ reportName: string; dateRange: DateRange }> = ({
  reportName,
  dateRange,
}) => {
  const partnerId = useSelectedPartner().partnerId;
  const { value: reportDefinitionId } = useQueryParam('reportDefinitionId');
  const { value: isCombined } = useQueryParam('isCombined');

  const { artifacts, isLoading, error } = useReportArtifacts({
    reportName,
    partnerId,
    fromDate: getIsoDate(dateRange.from),
    toDate: getIsoDate(dateRange.to),
    reportDefinitionId: reportDefinitionId ? Number(reportDefinitionId) : undefined,
    isCombined: isCombined ? isCombined === 'true' : undefined,
  });

  useErrorNotificationEffect(error);

  if (isLoading) {
    return <Loading isWhite={false} message={translateHtml('common_loading_message')} />;
  }

  if (artifacts.length === 0) {
    return <TeapotAnimation message={translate('bidding_table_no_data_result_message')} />;
  }

  return (
    <table>
      <tbody>
        {artifacts.map((reportArtifact, index) => (
          <ReportArtifactRow key={index} reportArtifact={reportArtifact} />
        ))}
      </tbody>
    </table>
  );
};
