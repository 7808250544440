import { RevertType } from '../constants';
import { apiBiddingHelper } from '../helpers/api/bidding/api-bidding-helper';
import { BiddingResponse } from '../helpers/api/bidding/types';
import {
  Bid,
  BiddingActionType,
  BidModifier,
  Hotel,
  PerformedBids,
  VIEWS_BM_EDIT_BID_MODIFIER,
} from '../models';

export async function applyBids(
  partnerId: number,
  bids: Bid[],
  actionType: BiddingActionType,
): Promise<BiddingResponse> {
  return apiBiddingHelper.applyBids(bids, partnerId, actionType);
}

export async function applyBidModifiers(
  bidModifiers: BidModifier[],
  propertyGroups: number[],
  view: VIEWS_BM_EDIT_BID_MODIFIER,
  partnerId: number,
  localeCode: string,
  value: number,
  url: string,
): Promise<PerformedBids> {
  return apiBiddingHelper.applyBidModifiers(
    bidModifiers,
    propertyGroups,
    view,
    partnerId,
    localeCode,
    value,
    url,
  );
}

export function applyRevert(partnerId: number, type: RevertType) {
  return apiBiddingHelper.applyRevert(partnerId, type);
}

/* Private methods */

export function transformToBid(hotels: Hotel[], cpc: number, localeCode: string): Bid[] {
  return hotels.map(({ partnerRef }) => {
    return {
      partnerReference: partnerRef,
      localeCode,
      cpc: cpc.toString(),
    };
  });
}
