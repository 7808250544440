import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { NotificationWebsocket } from '../../../helpers/websockets/notification-websocket';
import {
  onConnectionClosed,
  onConnectionError,
  onOpenConnection,
  onReceiveMessage,
} from '../../../services/notification-service';
import { AppContext } from '../../context/app-context';
import { Props } from './app-web-socket.types';

export const AppWebSocket: FC<Props & PropsWithChildren> = ({ children }) => {
  const { partner, subPartners } = useContext(AppContext);

  useEffect(() => {
    if (partner) {
      const initNotificationWebSocketConnection = () => {
        const onMessage = (e: MessageEvent) => onReceiveMessage(e, partner, [...subPartners]);
        const ws = NotificationWebsocket.getInstance();
        ws.onOpen(onOpenConnection);
        ws.onMessage(onMessage);
        ws.onError(onConnectionError);
        ws.onClose(onConnectionClosed);
      };
      initNotificationWebSocketConnection();
    }
  }, [partner, subPartners]);

  return <> {children} </>;
};
