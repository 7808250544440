import { FC, PropsWithChildren } from 'react';
import { Anchor } from '../anchor';
import { Button } from '../button/button';
import { ButtonProps } from '../button/button.types';
import styles from './popover.module.css';

export type PopoverProps = {
  onClose: () => void;
  anchor: HTMLElement;
  buttons?: {
    start?: ButtonProps[];
    end?: ButtonProps[];
  };
};

export const Popover: FC<PopoverProps & PropsWithChildren> = ({
  onClose,
  anchor,
  children,
  buttons,
}) => {
  return (
    <Anchor anchor={anchor} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.main}>{children}</div>
        <footer className={styles.footer}>
          {buttons?.start ? (
            <section>
              {buttons.start?.map((button, index) => <Button key={index} {...button} />)}
            </section>
          ) : null}
          {buttons?.end ? (
            <section className={styles.footerEnd}>
              {buttons.end?.map((button, index) => <Button key={index} {...button} />)}
            </section>
          ) : null}
        </footer>
      </div>
    </Anchor>
  );
};
