import { Partner, Locale, User } from '../models';
import { POS_GLOBAL_VALUE, MENU, PARTNERLEVEL_BIDDING } from '../constants';

export const areMultiplePOSorGlobalSelected = (POSlocales: ReadonlyArray<string>): boolean => {
  return (POSlocales.length === 1 && POSlocales[0] === POS_GLOBAL_VALUE) || POSlocales.length > 1;
};

export const areMultipleBrandsSelected = (subbrands: ReadonlyArray<Partner>): boolean =>
  subbrands.length > 1;

export const hasUserBiddingRights = (user: User): boolean =>
  user.partnerLevel >= PARTNERLEVEL_BIDDING;

export const getPartner = (partner: Partner, subbrands: ReadonlyArray<Partner>): Partner => {
  return subbrands.length > 0 ? subbrands[0] : partner;
};

export const getPartnersId = (partner: Partner, subbrands: ReadonlyArray<Partner>): number[] => {
  return subbrands.length > 0 && subbrands.length <= partner.subpartners.length
    ? subbrands.map(subbrandTmp => subbrandTmp.partnerId)
    : [partner.partnerId];
};

export const getPartners = (partner: Partner, subbrands: ReadonlyArray<Partner>): Partner[] => {
  return subbrands.length > 0 && subbrands.length <= partner.subpartners.length
    ? subbrands.map(subbrandTmp => subbrandTmp)
    : [partner];
};

export const getLocale = (locales: ReadonlyArray<Locale>, localeCode: string): Locale =>
  locales.find(localeTmp => localeTmp.localeCode === localeCode)!;

export const findMenuOptions = (pathname: string) => {
  const pathNameSplit = pathname.split('/');
  const pathnameTmp = '/' + (pathNameSplit.length > 1 ? pathNameSplit[1] : '');

  return MENU.find(menuTmp => {
    if ('link' in menuTmp) {
      return menuTmp.link === pathnameTmp;
    }
    return menuTmp.dropdown.find(({ link: linkTmp }) => {
      return linkTmp === pathnameTmp;
    });
  })!;
};

export const findItemsMenuOptions = (pathname: string) => {
  const pathNameSplit = pathname.split('/');
  const pathnameTmp = '/' + (pathNameSplit.length > 1 ? pathNameSplit[1] : '');

  return findMenuOptionsTmp(pathnameTmp)!;
};

const findMenuOptionsTmp = (pathname: string) => {
  for (const menuTmp of MENU) {
    if ('link' in menuTmp) {
      if (menuTmp.link === pathname) {
        return menuTmp;
      }
    } else {
      for (const menuItemTmp of menuTmp.dropdown) {
        if (typeof menuItemTmp.link === 'string' && menuItemTmp.link === pathname) {
          return menuItemTmp;
        }
      }
    }
  }

  return undefined;
};
