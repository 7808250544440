import cs from 'classnames';
import { FC, PropsWithChildren } from 'react';
import styles from './popover.module.css';
import { POPOVER_DIRECTION, PopoverDirection, TooltipType } from './popover.types';

export const Popover: FC<
  {
    tooltipType?: TooltipType;
    direction?: PopoverDirection;
  } & PropsWithChildren
> = ({ children, tooltipType = 'column', direction = PopoverDirection.TRAILING_TOP }) => {
  const { horizontal, vertical } = POPOVER_DIRECTION[direction];
  return (
    <div className={cs(styles.popoverWrapper, styles[horizontal], styles[vertical])}>
      <div className={cs(styles.popover, styles[tooltipType])} role="tooltip">
        <div className={styles.popoverBody}>{children}</div>
      </div>
    </div>
  );
};
