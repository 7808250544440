import React, { FC, useContext } from 'react';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';
import { Grid, SortDirection } from '../../../components/grid';
import { ResultsNotFound } from '../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../components/shown-metrics-selector';
import { Toolbar } from '../../../components/toolbar';
import { SearchTextFilter } from '../../../components/toolbar/search-text-filter';
import { ViewExporter } from '../../../components/view-exporter';
import {
  COLUMN_KEY_CPA_GROUP,
  COLUMN_KEY_PARTNER_REFERENCE,
  GRID_VIEW_CONFIGURATION,
  VIEW_NAME,
} from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../helpers/grid-formatter';
import { TranslationKey, translate } from '../../../helpers/utils';
import { useShownMetricsSelector } from '../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../hooks/use-view-table';
import { Aggregation, FileFormat, SearchData, View } from '../../../models';
import styles from '../common-view-styles.module.css';
import { withSortTracking } from '../with-sort-tracking';
import { useSortParams } from '../../../hooks/use-sort-params';

type Props = {
  view: View;
  aggregation: Aggregation;
  searchData?: SearchData;
};

const numOfStickyColumns = 1;

export const DimensionsView: FC<Props> = ({ view, aggregation, searchData }) => {
  const { selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();
  const { setSortParams } = useSortParams(['sortBy', 'orderBy']);

  const {
    table: { columns, data, total },
    isLoading,
    retry,
    setSearch,
    pagination,
    filters: { allActiveFilters, hasGlobalFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useViewTable(
    view,
    aggregation,
    {
      locales: selectedLocales,
      from,
      to,
    },
    {
      columnId: COLUMN_KEY_PARTNER_REFERENCE,
      biddingTypeId: COLUMN_KEY_CPA_GROUP,
    },
  );

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: data.rows,
    total,
    id: 'id',
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, numOfStickyColumns, columns, formattedColumns);
  const buildFilterToolbar = () => {
    const filterComponents: React.ReactNode[] = [];
    if (searchData !== undefined) {
      filterComponents.push(
        <SearchTextFilter
          key="key-custom-filters"
          placeholder={translate(searchData.placeholder as TranslationKey)}
          onSearch={filterTmp => setSearch(filterTmp !== undefined ? [filterTmp] : [])}
          searchableColumnNames={searchData.filterColumns}
        />,
      );
    }

    return filterComponents;
  };

  return (
    <div className={styles.toolbarWrapper}>
      <Toolbar
        filters={buildFilterToolbar()}
        actions={[
          <ShownMetricsSelector
            key="key-metrics-selector"
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
          />,
          <ViewExporter
            key="key-view-exporter"
            title={translate('analytics_home_export_report')}
            exportOptions={[
              {
                text: translate('analytics_toolbar_export_performance_grid'),
                fileFormat: FileFormat.CSV,
              },
              {
                text: translate('analytics_toolbar_export_performance_grid_excel'),
                fileFormat: FileFormat.EXCEL,
              },
            ]}
            view={view}
            columns={columns}
            aggregation={aggregation}
            filters={allActiveFilters}
            sort={sort}
          />,
        ]}
      />
      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={numOfStickyColumns}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          onSort={withSortTracking((columnName: string, direction: SortDirection) => {
            setSort({ columnName, direction });
            setSortParams({ sortBy: columnName, orderBy: direction });
          }, VIEW_NAME[view])}
          pagination={{
            page: pagination.pagination,
            totalItems: data.count,
            onPreviousPage: pagination.setPrevPage,
            onNextPage: pagination.setNextPage,
            onUpdateRowsPerPage: pagination.setNumItemsPerPage,
          }}
          resultsNotFound={
            <ResultsNotFound
              isButtonEnabled={hasGlobalFilters && !isLoading}
              onClick={clearGlobalFilters}
              retry={retry}
            />
          }
        />
      </div>
    </div>
  );
};
