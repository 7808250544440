import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HotelNotFound } from '../../../components/hotel-not-found';
import { ANALYTICS_ROUTE, NotificationLevel } from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { RequestHelper } from '../../../helpers/api/request-helper';
import { areArraysEqual, translate } from '../../../helpers/utils';
import { Hotel } from '../../../models';
import { getHotel } from '../../../services/analytics-service';
import { getPartnersId } from '../../../services/app-service';
import { showNotification } from '../../../services/notification-service';
import { GeneralInformationPanel } from './general-information-panel';
import { HotelDetail } from './hotel-detail';
import styles from './hotel-detail-view.module.css';

export const HotelDetailView: FC = () => {
  const { partnerRef } = useParams();
  const [hotel, setHotel] = useState<Hotel | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { partner, selectedLocales, subPartners } = useContext(AppContext);

  const previousPartnersId = useRef<number[]>([]);
  const previousSelectedLocales = useRef<string[]>([]);
  const isPartnerChange = useRef(false);
  const isSelectedLocalesChange = useRef(false);
  const hasFetchedHotelData = useRef(false);

  const onUpdate = useCallback(async () => {
    const fetchHotelData = async () =>
      getHotel(
        getPartnersId(partner, Array.from(subPartners)),
        partnerRef!,
        Array.from(selectedLocales),
      );

    let hotelTmp: Hotel | undefined;
    try {
      if (!partnerRef) throw new Error('common_fail');
      setLoading(true);

      if (!hasFetchedHotelData.current) {
        hotelTmp = await fetchHotelData();
        setHotel(hotelTmp);
        setIsError(!hotelTmp);
        hasFetchedHotelData.current = true;
      }
    } catch (e) {
      const error = e as Error;
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
      if (error.message !== RequestHelper.cancelMessage) {
        setIsError(true);
      }
    } finally {
      setLoading(false);
    }
  }, [partner, partnerRef, selectedLocales, subPartners]);

  useEffect(() => {
    onUpdate();
  }, [selectedLocales, partner, subPartners, onUpdate]);

  useEffect(() => {
    isPartnerChange.current =
      previousPartnersId.current.length !== 0 &&
      !areArraysEqual(
        getPartnersId(partner, subPartners),
        previousPartnersId.current,
        (t1, t2) => t1 === t2,
      );
    isSelectedLocalesChange.current =
      previousSelectedLocales.current.length !== 0 &&
      !areArraysEqual(
        Array.from(selectedLocales),
        previousSelectedLocales.current,
        (t1, t2) => t1 === t2,
      );

    previousPartnersId.current = getPartnersId(partner, subPartners);
    previousSelectedLocales.current = Array.from(selectedLocales);
  }, [partner, subPartners, selectedLocales]);

  const getHotelNotFoundReason = () => {
    if (isPartnerChange.current) {
      return 'analytics_hotel_not_found_partner_change';
    } else if (isSelectedLocalesChange.current) {
      return 'analytics_hotel_not_found_pos_change';
    }
    return 'analytics_hotel_not_found_unknown';
  };

  if (!partnerRef) return null;
  if (isError) {
    return (
      <HotelNotFound
        partnerRef={partnerRef}
        reason={getHotelNotFoundReason()}
        redirectRoute={ANALYTICS_ROUTE}
      />
    );
  }
  return (
    <div className={styles.hotelDetails}>
      <GeneralInformationPanel hotel={hotel} isLoading={isLoading} onUpdate={onUpdate} />
      <HotelDetail partnerRef={partnerRef} />
    </div>
  );
};
