import { useQueryParam } from '../../../hooks/use-query-param';

export function useMembersFilters() {
  const { value: includeTrivagoEmployees, setQueryParam: setIncludeTrivagoEmployees } =
    useQueryParam('includeTrivagoEmployees');
  const { value: partnerId, setQueryParam: setPartnerId } = useQueryParam('partnerId');
  const { value: email, setQueryParam: setEmail } = useQueryParam('email');

  return {
    includeTrivagoEmployees: includeTrivagoEmployees === 'true',
    setIncludeTrivagoEmployees: (value: boolean) =>
      setIncludeTrivagoEmployees(value ? 'true' : 'false'),
    partnerId: partnerId ? Number(partnerId) : 0,
    setPartnerId: (value: number) => setPartnerId(String(value)),
    email: email || '',
    setEmail,
  };
}
