import React, { FC } from 'react';
import { Switch } from '../switch';

type ToggleProps = {
  isActive: boolean;
  label: string;
  onChange: (value: boolean) => void;
  style?: React.CSSProperties;
};

export const Toggle: FC<ToggleProps> = ({ label, isActive, onChange, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 24,
        borderTop: 'solid 1px var(--grey-200)',
        ...style,
      }}
    >
      <h4>{label}</h4>
      <Switch isActive={isActive} onChange={onChange} variant="circle" />
    </div>
  );
};
