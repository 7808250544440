import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { VIEW_NAME } from '../../constants';
import { RequestQueueMap, View } from '../../models';
import { removeProfile } from '../../services/profile-service';
import { generateRequestKey } from '../utils';

const HEADER_X_ENABLE_CACHE = 'X-Enable-Cache';
const viewsWithCacheEnabled = [
  VIEW_NAME[View.POS],
  VIEW_NAME[View.TIMELINE],
  VIEW_NAME[View.TIMELINE_WITH_SL],
  VIEW_NAME[View.POS_OVERVIEW],
  VIEW_NAME[View.YESTERDAY_OVERVIEW],
  VIEW_NAME[View.YESTERDAY_OVERVIEW_WITH_SL],
];

/* configure interceptor for UNAUTHORIZED response */
// https://github.com/axios/axios/issues/1290
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      userSignOut();
    }
    return Promise.reject(error);
  },
);

/* configure interceptor for enabling cache for specific views */
axios.interceptors.request.use(
  request => {
    if (
      request.url &&
      viewsWithCacheEnabled.filter(view => request.url && request.url.includes(view)).length
    ) {
      const headers: AxiosRequestHeaders = request.headers || {};
      headers[HEADER_X_ENABLE_CACHE] = 'true';
      request.headers = headers;
    }

    return request;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// TODO place this function in a proper place
function userSignOut() {
  removeProfile();
  document.location.reload();
}

const baseURL = process.env.REACT_APP_URL;
// @see https://github.com/axios/axios/blob/master/COOKBOOK.md#promiseprototypefinally
// @see https://github.com/axios/axios#cancellation
const CancelToken = axios.CancelToken;

export class RequestHelper {
  public static replaceUrlParams(
    url: string,
    params: Array<{ key: string; value: string | number }>,
  ): string {
    return params.reduce(
      (accumulatedUrl, { key, value }) => accumulatedUrl.replace(`{${key}}`, String(value)),
      url,
    );
  }

  public static cancelMessage = 'cancel request';

  protected queue: RequestQueueMap;

  constructor() {
    this.queue = new Map();
  }

  protected getRequest(
    url: string,
    options?: Pick<AxiosRequestConfig, 'headers' | 'params' | 'responseType' | 'paramsSerializer'>,
  ) {
    const uuid = generateRequestKey(url);
    return axios
      .get(url, {
        cancelToken: new CancelToken(token => this.queue.set(uuid, { canceler: token, path: url })),
        baseURL,
        ...options,
      })
      .finally(() => this.queue.delete(uuid));
  }

  protected postRequest(
    url: string,
    body: any,
    options?: Pick<AxiosRequestConfig, 'headers' | 'responseType'>,
  ) {
    const uuid = generateRequestKey(url, body);
    return axios
      .post(url, body, {
        cancelToken: new CancelToken(token => this.queue.set(uuid, { canceler: token, path: url })),
        baseURL,
        ...options,
      })
      .finally(() => this.queue.delete(uuid));
  }

  protected putRequest(url: string, body: any) {
    const uuid = generateRequestKey(url, body);
    return axios
      .put(url, body, {
        cancelToken: new CancelToken(token => this.queue.set(uuid, { canceler: token, path: url })),
        baseURL,
      })
      .finally(() => this.queue.delete(uuid));
  }

  protected deleteRequest(url: string, body: any) {
    const uuid = generateRequestKey(url, body);
    return axios
      .delete(url, {
        data: body,
        cancelToken: new CancelToken(token => this.queue.set(uuid, { canceler: token, path: url })),
        baseURL,
      })
      .finally(() => this.queue.delete(uuid));
  }

  public cancelAllRequests() {
    this.queue.forEach(({ canceler }) => canceler(RequestHelper.cancelMessage));
    // Clear the queue after we cancel every request
    this.queue.clear();
  }

  protected handleError(error: any, errorMessage?: string) {
    if (axios.isCancel(error)) {
      // TODO this case is never treated in the application. It should be discussed and then implemented.
    } else if (error instanceof AxiosError && error.response && error.response.status === 401) {
      removeProfile();
      document.location.reload();
    } else {
      if (error instanceof AxiosError) throw error;
      throw Error(errorMessage);
    }
  }
}
