export const POINT_OF_SALE = 'analytics_home_select_country';
export const REGIONS = 'analytics_home_select_region';
export const POS_GLOBAL = 'analytics_home_pos_global';
export const POS_GLOBAL_VALUE = '-1';
export const BRANDS_SUBGROUP = 'Brands';
export const BRANDS_ALLSUBBRANDS = 'allSubbrands';

// SELECTED KEY
export const SELECTED_OPTION = 'analytics_home_selector_subMenu_selected';

// localStorage keys.
export const EMAIL_KEY = 'email';
export const DATE_RANGE_PERIOD_KEY = 'date_range_period';
export const DATE_PICKER_COMPARE_TOGGLE_KEY = 'isCompareToggleEnabled';
export const CAMPAIGN_TYPES_KEY = 'campaign_types';
export const HIDDEN_COLUMNS_KEY_PREFIX = 'hidden_columns';
export const HIDDEN_CHART_LINES_KEY_PREFIX = 'hidden_lines';

export enum FilterStorageKey {
  DEFAULT = 'global_dimension_filters',
  SPONSORED_LISTING = 'dimension_filters',
}

export const SL_ACTIVE_FILTER_SET = 'active_filter_set';
export const GLOBAL_ACTIVE_FILTER_SET = 'global_active_filter_set';
export const PROFILE_KEY = 'profile';
export const ERROR_RETRY_KEY = 'error_retry';
export const PROFILE_SETTINGS = 'profile_settings';
export const TI_TRANSLATIONS_BK = 'ti_translation_bk';
export const TRANSLATIONS_DEACTIVATED = 'translationsDeactivated';
export const CURRENT_PARTNER_KEY = 'current_partner';
export const ANALYTICS_PROPERTY_GROUPS_DROPDOWN = 'isAnalyticsPropertyGroupsDropdownShown';
export const QUICK_DATA_EXPLORATION_ON_BOARDING = 'quickDataExplorationOnboarding';
export const CHANGE_BID_ON_BOARDING = 'changeBidOnboarding';
// Dates
export const DATE_FORMAT_SHORT = 'D MMM';
export const DATE_FORMAT_SHORT_YEAR = 'DD/MM/YY';
export const DATE_FORMAT_LONG_YEAR = 'Do MMMM YYYY';
export const DATE_FORMAT_LONG_COMA = 'D MMM, YYYY';
export const DATE_FORMAT_LONG = 'D MMM YYYY';
export const DATE_FORMAT_STANDARD = 'YYYYMMDD';
export const DATE_FORMAT_TIMELINE = 'DD, MMM YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';
export const DATE_FORMAT_ISO_8601 = 'YYYY-MM-DD';
export const DAYS_IN_LAST_12_MONTHS = 365;

// Event handlers
export const EVENT_MOUSEDOWN = 'mousedown';
export const EVENT_KEYDOWN = 'keydown';

// Keyboard handlers
export const KEYBOARD_EVENT_ESCAPE = 'Escape';
export const KEYBOARD_EVENT_ENTER = 'Enter';
export const KEYBOARD_ARROW_DOWN = 'ArrowDown';
export const KEYBOARD_ARROW_UP = 'ArrowUp';

export const PARTNERLEVEL_DEVELOPER = 200;
export const LEVEL_TRIVAGO_EMPLOYEE = 200;
export const PARTNERLEVEL_BIDDING = 25;

export const BID_SAMPLE_URL = 'sample_import_file.csv';
export const BID_MODIFIER_SAMPLE_URL = 'bid_modifier_sample.csv';
export const CPA_BID_MODIFIER_SAMPLE_URL = 'cpa_bid_sample.csv';
export const PROPERTY_GROUP_SAMPLE_URL = 'propertygroup_sample.csv';
export const CAMPAIGN_ALLOCATION_SAMPLE_URL = 'campaign_allocation_sample.csv';
export const CPA_SAMPLE_URL = 'CPA_values_sample.csv';
export const INVENTORY_SAMPLE_URL = 'sample_inventory_upload_file.csv';
export const SL_BID_SAMPLE_URL = 'sl_bid_sample.csv';
export const SL_BID_MODIFIER_SAMPLE_URL = 'sl_bid_modifier_sample.csv';
export const SL_BUDGET_CAP_SAMPLE_URL = 'sl_budget_cap_sample.csv';

export const NO_OF_NARROW_TABLE_COLUMNS = 7;
export const REACT_QUERY_STALE_TIME = 1000 * 60 * 3;
export const REACT_QUERY_RETRY_PREDICATE = (failureCount: number) => failureCount < 3;
export const QUERY_PARAM_ACTIVE_TAB = 'tab';

export const SL_BID_CAP = 6;
