import { useNavigate, useLocation } from 'react-router-dom';

export function useQueryParam(key: string): {
  value: string | null;
  setQueryParam: (value: string) => void;
  allParams: [string, string][];
} {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  function setQueryParam(value: string) {
    const params = new URLSearchParams(search);
    params.set(key, value);
    navigate(
      {
        pathname,
        search: params.toString(),
      },
      { replace: true },
    );
  }

  const queryParams = new URLSearchParams(search);
  const allParams = Array.from(queryParams.entries());
  return { value: queryParams.get(key), setQueryParam, allParams };
}
