import { useCallback, useContext, useEffect } from 'react';
import { OnboardingTourContext } from '../../core-ui/components/onboarding-tour';
import {
  OnboardingTourState,
  TourStep,
} from '../../core-ui/components/onboarding-tour/onboarding-tour.types';
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { ONBOARDING_TOURS } from '../../constants/onboarding-targets';
import {
  ANALYTICS_ROUTE,
  CAMPAIGNS_ROUTE,
  CHANGE_BID_ON_BOARDING,
  QUICK_DATA_EXPLORATION_ON_BOARDING,
  SPONSORED_ANALYTICS_ROUTE,
} from '../../constants';
import { useHistory } from 'react-router-dom';
import trackerService from '../../services/tracker/tracker-service';

export type UseOnboardingTourProps = {
  steps: TourStep[];
  tourName: string;
  callback?: (data: CallBackProps) => void;
};

export const useOnboardingTour = ({
  steps,
  tourName,
}: Omit<UseOnboardingTourProps, 'callback'>) => {
  const { setTourState, startTour: providerStartTour } = useContext(OnboardingTourContext);
  const history = useHistory();

  const handleTourCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, status, type, step } = data;
      const featureName = 'trackingFeatureName' in step ? step.trackingFeatureName ?? '' : '';

      if (type === EVENTS.TOOLTIP && status === STATUS.RUNNING) {
        document.documentElement.style.overflow = 'hidden';
      }

      if (index === 0 && action === ACTIONS.NEXT) {
        trackerService.track('Onboarding flow starts', { feature_name: featureName });
      }
      if (action === ACTIONS.CLOSE) {
        trackerService.track('Onboarding flow close', { feature_name: featureName });
      }

      if (
        status === STATUS.FINISHED ||
        status === STATUS.SKIPPED ||
        action === ACTIONS.CLOSE ||
        action === ACTIONS.STOP
      ) {
        setTourState(prev => ({ ...prev, stepIndex: 0, tour: {} }));
        localStorage.setItem(tourName, 'true');
        document.documentElement.style.overflow = '';
        return;
      }

      if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
        tourMap[tourName]?.(index, setTourState, history);
      }
    },
    [history, tourName, setTourState],
  );

  const startTour = useCallback(() => {
    providerStartTour(tourName, steps, handleTourCallback);
  }, [providerStartTour, tourName, steps, handleTourCallback]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const tourElements = document.querySelectorAll('[data-tour]');

      const areElementsReady = Array.from(tourElements).every(element => {
        return (element as HTMLElement).offsetParent !== null;
      });

      if (tourElements.length > 0 && areElementsReady) {
        startTour();
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['data-tour'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return { startTour };
};

const getQuickDataExplorationTour = async (
  index: number,
  setTourState: (
    state: OnboardingTourState | ((prevState: OnboardingTourState) => OnboardingTourState),
  ) => void,
  history: any,
) => {
  switch (index) {
    case 0: {
      const button = document.querySelector(
        `[data-tour=${ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.STEP_1}]`,
      ) as HTMLElement;
      if (button) {
        button.click();

        await new Promise(resolve => setTimeout(resolve, 200));
        const observer = new MutationObserver((_mutations, obs) => {
          const menu = document.querySelector(
            `[data-tour=${ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.STEP_2}]`,
          ) as HTMLElement;
          if (menu) {
            const menuList = menu.children[1] as HTMLElement;
            const menuItem = menuList.children[0] as HTMLElement;
            if (menuItem) {
              const mouseEvent = new MouseEvent('mouseover', {
                view: window,
                bubbles: true,
                cancelable: true,
              });
              menuItem.dispatchEvent(mouseEvent);
            }
            obs.disconnect();
          }
        });
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }

      break;
    }
    case 1: {
      const params = new URLSearchParams();
      params.append('tab', '0');
      params.append('sortBy', 'cost');
      params.append('orderBy', 'asc');
      history.push({ pathname: ANALYTICS_ROUTE, search: params.toString() });

      window.scrollTo({ top: 0, behavior: 'smooth' });

      break;
    }
  }

  await new Promise(resolve => setTimeout(resolve, 500));
  setTourState(prev => ({ ...prev, stepIndex: index + 1 }));
};

const getBidChangeTour = async (
  index: number,
  setTourState: (
    state: OnboardingTourState | ((prevState: OnboardingTourState) => OnboardingTourState),
  ) => void,
  history: any,
) => {
  const location = history.location.pathname;
  switch (index) {
    case 0: {
      const button = document.querySelector(
        `[data-tour=${ONBOARDING_TOURS.CHANGE_BID.STEP_1}]`,
      ) as HTMLElement;
      if (button) {
        button.click();
      }
      break;
    }
    case 1: {
      const dropdown = document.querySelector(
        `[data-tour=${ONBOARDING_TOURS.CHANGE_BID.STEP_2}]`,
      ) as HTMLElement;

      if (dropdown?.children[0]?.children[1]) {
        const changeByMenuItem = dropdown.children[0].children[1] as HTMLElement;
        changeByMenuItem.click();
      }

      await new Promise(resolve => setTimeout(resolve, 300));

      const inputField = document.querySelector(
        `input.${CHANGE_BID_ON_BOARDING}`,
      ) as HTMLInputElement;
      if (inputField) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        nativeInputValueSetter?.call(inputField, '10');
        const event = new Event('input', { bubbles: true });
        inputField.dispatchEvent(event);
      }

      const selectedRow = document.querySelector(`tr.${CHANGE_BID_ON_BOARDING}`) as HTMLElement;

      if (selectedRow) {
        handleRowSelection(location, selectedRow);
      }

      break;
    }
  }
  await new Promise(resolve => setTimeout(resolve, 500));
  setTourState(prev => ({ ...prev, stepIndex: index + 1 }));
};

const tourMap: {
  [key: string]: (
    index: number,
    setTourState: (
      state: OnboardingTourState | ((prevState: OnboardingTourState) => OnboardingTourState),
    ) => void,
    history: unknown,
  ) => Promise<void>;
} = {
  [QUICK_DATA_EXPLORATION_ON_BOARDING]: getQuickDataExplorationTour,
  [CHANGE_BID_ON_BOARDING]: getBidChangeTour,
};

const handleRowSelection = (location: string, selectedRow: Element) => {
  const qaAttributeMap = {
    [CAMPAIGNS_ROUTE]: 'campaigns_cpa_value',
    [ANALYTICS_ROUTE]: 'bid_cpc',
    [SPONSORED_ANALYTICS_ROUTE]: 'sl_bid_cpc',
  } as const;

  const bidCol = Array.from(selectedRow.children).find(child =>
    child
      .getAttribute('data-qa')
      ?.includes(qaAttributeMap[location as keyof typeof qaAttributeMap]),
  );

  if (bidCol) {
    bidCol.setAttribute('data-tour', ONBOARDING_TOURS.CHANGE_BID.STEP_3);
  }
};
