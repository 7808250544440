import cs from 'classnames';
import { FC, PropsWithChildren } from 'react';
import './card.css';
import { Props } from './card.types';

export const Card: FC<Props & PropsWithChildren> = ({ children, className = '' }) => {
  return (
    <div className={cs('u-background--white u-box-shadow u-border-radius', className)}>
      {children}
    </div>
  );
};
