import { FC, PropsWithChildren, useState } from 'react';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { Color } from '../../constants';
import { Popover, PopoverDirection } from '../popover';
import styles from './onboarding-tooltip.module.css';
import { Props } from './onboarding-tooltip.types';

// This is a recovered component that already had been removed
export const OnboardingTooltip: FC<Props & PropsWithChildren> = ({
  children,
  keyStorage,
  direction = PopoverDirection.TRAILING_TOP,
}) => {
  const initialValue = JSON.parse(localStorage.getItem(keyStorage) || 'false');
  const [isHaveBeenShown, setIsHaveBeenShown] = useState(initialValue);

  const onCloseOnboardingTooltip = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    localStorage.setItem(keyStorage, 'true');
    setIsHaveBeenShown(true);
  };

  if (isHaveBeenShown) return null;

  return (
    <Popover tooltipType="onboarding" direction={direction}>
      <div className={styles.contentWrapper}>{children}</div>
      <a onClick={onCloseOnboardingTooltip} href="">
        <CloseIcon className={styles.closeIcon} width="18" height="18" fill={Color.White} />
      </a>
    </Popover>
  );
};
