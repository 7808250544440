import { useContext } from 'react';
import { isNumber, translate, TranslationKey } from '../../helpers/utils';
import { BidFormContext } from './bid-form-context';
import { AdjustedBidItem, BidFormUnit, BidItem, EURO, PERCENTAGE } from './bid-form.types';

const MAXIMUM_PERCENTAGE = 100;

export const useBidForm = (isCPC: boolean = true) => {
  const { value, setValue, unit, setUnit, bidItems } = useContext(BidFormContext);

  const finalValues: AdjustedBidItem[] = bidItems.map(item => {
    const adjustedValue = calculateAdjustedValue(value, unit, item);
    const isExceeded = adjustedValue > item.capValue;
    const isBelow = adjustedValue < item.minValue;
    const acceptedValue = isExceeded ? item.capValue : isBelow ? item.minValue : adjustedValue;
    return {
      ...item,
      adjustedValue,
      isExceeded,
      isBelow,
      acceptedValue: value === '' ? item.baseValue : acceptedValue,
    };
  });

  const isAllItemsExceeded = finalValues.every(item => item.isExceeded);
  const isAllItemsNotChanged = finalValues.every(item => item.adjustedValue === item.baseValue);
  const isCPAChangeByPercentageDisabled =
    !isCPC && unit === PERCENTAGE && finalValues.some(item => !isNumber(item.baseValue));

  function getWarningMessage(): string {
    if (value === '') return '';

    const capValue = isCPC ? finalValues[0].capValue : MAXIMUM_PERCENTAGE;

    const errorType = isCPC ? 'cpc' : 'cpa';
    const valueType = unit === EURO ? 'absolute' : 'percentage';
    const exceededError: TranslationKey = `${errorType}_bid_${valueType}_exceeded_error`;
    const belowError: TranslationKey = `${errorType}_bid_${valueType}_below_error`;

    const isMultiple = bidItems.length > 1;
    const isExceeded = finalValues.some(item => item.isExceeded);
    const isBelowIndex = finalValues.findIndex(item => item.isBelow);
    const isBelow = isBelowIndex !== -1;

    if (isExceeded) return translate(exceededError, capValue.toString());
    if (isBelow) return translate(belowError, finalValues[isBelowIndex].minValue.toString());
    if (isCPC && isMultiple && isExceeded && !isAllItemsExceeded) {
      return translate('cpc_bid_percentage_multiple_partial_exceeded_error', capValue.toString());
    }

    return '';
  }

  return {
    value,
    setValue,
    unit,
    setUnit,
    warningMessage: getWarningMessage(),
    adjustedBidItems: finalValues,
    isFormInvalid:
      value === '' || !isNumber(value) || isAllItemsNotChanged || isCPAChangeByPercentageDisabled,
  };
};

const calculateFinalValuePercentage = (baseValue: BidItem['baseValue'], val: number): number =>
  Number(((1 + val / 100) * baseValue).toFixed(2));

const calculateAdjustedValue = (value: string, unit: BidFormUnit, bid: BidItem): number => {
  const val = Number(value);

  if (value) {
    if (unit === EURO) {
      return val;
    }
    return calculateFinalValuePercentage(bid.baseValue, val);
  }
  return bid.baseValue;
};
