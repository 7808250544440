import cs from 'classnames';
import { FC, useState } from 'react';
import MinusThinDarkIcon from 'tcp-react-icons/lib/MinusThinDarkIcon';
import PlusThinDarkIcon from 'tcp-react-icons/lib/PlusThinDarkIcon';
import { useBidForm } from '../../../../../features/bid-form/use-bid-form';
import { translate } from '../../../../../helpers/utils';
import { SponsoredHotelDetailInformationColumn } from '../sponsored-hotel-detail-information-column';
import './sponsored-hotel-detail-information.css';
import { Props } from './sponsored-hotel-detail-information.types';

export const SponsoredHotelDetailInformation: FC<Props> = ({ hotel }) => {
  const [isShowingDetail, setIsShowingDetail] = useState(true);
  const { adjustedBidItems } = useBidForm(true);

  const bidCPCValue = () => {
    if (!hotel.slBidCpc) return translate('common_not_available');

    return (
      <>
        {hotel.slBidCpc} € to <b>{adjustedBidItems[0].acceptedValue} €</b>
      </>
    );
  };

  const getStarsKeyTranslations = () =>
    hotel.stars > 1
      ? translate('analytics_hotel_detail_stars')
      : translate('analytics_hotel_detail_star');

  return (
    <div
      className={cs(
        'u-display--flex u-flex-direction--column u-padding--gutter c-sl-hotel-detail-information',
        {
          'u-border-bottom--juri-very-light': isShowingDetail,
        },
      )}
    >
      <div className="u-display--flex u-font-weight-bold u-align-items--center">
        {isShowingDetail ? (
          <MinusThinDarkIcon
            className="u-cursor--pointer"
            onClick={() => {
              setIsShowingDetail(false);
            }}
          />
        ) : (
          <PlusThinDarkIcon
            className="u-cursor--pointer"
            onClick={() => {
              setIsShowingDetail(true);
            }}
          />
        )}
        <span className="u-margin-left--gutter--small">
          {translate('analytics_hotel_detail_details')}
        </span>
      </div>
      {isShowingDetail && (
        <div className="u-display--flex u-margin-top--gutter u-margin-left--huge">
          <SponsoredHotelDetailInformationColumn
            fields={[
              {
                key: translate('analytics_hotel_detail_country'),
                value: hotel.country,
              },
              {
                key: translate('analytics_hotel_detail_region'),
                value: hotel.region,
              },
              {
                key: translate('analytics_hotel_detail_city'),
                value: hotel.city,
              },
              {
                key: translate('analytics_hotel_detail_stars_field'),
                value: `${hotel.stars} ${getStarsKeyTranslations()}`,
              },
            ]}
            extraClassesValues="c-sl-hotel-detail-information__column"
          />
          <SponsoredHotelDetailInformationColumn
            fields={[
              {
                key: translate('analytics_hotel_detail_rating'),
                value: hotel.rating,
              },
              {
                key: translate('analytics_hotel_detail_trivago_link'),
                value: (
                  <a href={hotel.trvLink} rel="noopener noreferrer" target="_blank">
                    {hotel.trvLink}
                  </a>
                ),
              },
              {
                key: translate('analytics_hotel_detail_partner_ref'),
                value: hotel.partnerRef,
              },
              {
                key: translate('analytics_hotel_detail_trivago_id'),
                value: hotel.trvId,
              },
            ]}
          />
          <SponsoredHotelDetailInformationColumn
            fields={[
              {
                key: translate('analytics_hotel_detail_sl_bid_cpc'),
                value: bidCPCValue(),
              },
            ]}
            extraClassesKeys="u-margin-left--auto"
          />
        </div>
      )}
    </div>
  );
};
