import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../constants';
import { isDeveloper, isFeatureEnabledForUser } from '../../helpers/utils';
import { Props } from './management-rights-guard.types';

export const ManagementRightsGuard: FC<Props & PropsWithChildren> = ({
  user,
  rights,
  children,
}) => {
  const isEnabledForUser = isFeatureEnabledForUser(rights, user) || isDeveloper(user.partnerLevel);

  if (isEnabledForUser === null) {
    return null;
  }

  if (!isEnabledForUser) {
    return <Navigate to={DEFAULT_ROUTE} />;
  }

  return <>{children}</>;
};
