import cn from 'classnames';
import isEqual from 'lodash.isequal';
import { FC, memo, ReactNode } from 'react';
import { ArrowIcon } from 'tcp-react-icons';
import { GridCellContextMenu } from '../../../core-ui/components/grid-cell-context-menu/grid-cell-context-menu';
import { isNumber } from '../../../helpers/utils';
import { useGridContextMenu } from '../../../hooks/use-grid-context-menu';
import { useIsComparing } from '../../date-picker/date-picker.state';
import { ColumnType } from '../index';
import {
  DATE_COLUMNS,
  formatNumericalValues,
  getFormattedDate,
  isColumnType,
} from '../utils/grid-utils';
import styles from './grid-cell.module.css';
import { GridCellProps } from './grid-cell.types';

const _GridCell: FC<GridCellProps> = (props: GridCellProps) => {
  const { value, row, column, onClickCell, configuration, isAccordion, isExpanded } = props;

  const [isComparing] = useIsComparing();
  const gridContextMenu = useGridContextMenu();

  const isEmpty = value === undefined || value === '';

  const formatValue = () => {
    if (isEmpty && !isColumnType(column, ColumnType.CHECKBOX)) return <>{' - '}</>;
    if (isColumnType(column, ColumnType.CUSTOM) && 'children' in column)
      return <>{column.children(value, row, props.nestingLevel) as ReactNode}</>;

    if (isNumber(value) && typeof value === 'number')
      return <>{formatNumericalValues(value as number, column, configuration)}</>;

    if (isColumnType(column, ColumnType.CHECKBOX)) return checkboxGridCell(value, onClickCell);

    if ('isClickable' in column && column.isClickable && onClickCell) {
      return linkGridCell(String(value), undefined, onClickCell);
    }

    if (DATE_COLUMNS.includes(column.key)) return <span>{getFormattedDate(value as string)}</span>;

    return <span>{String(value)}</span>;
  };

  if (isAccordion) return accordionGridCell(formatValue(), isExpanded);

  if (gridContextMenu.menuCol === column.key && !!row.id && !isComparing) {
    return (
      <GridCellContextMenu
        gridCell={formatValue()}
        menuItems={gridContextMenu.menuItems}
        rowId={row.id}
        tooltipTitle="Examine by"
        menuTitle="Examine by:"
        value={row[gridContextMenu?.valueCol]}
        applyMenuValue={gridContextMenu?.applyMenuValue}
      />
    );
  }

  return formatValue();
};

const accordionGridCell = (value: any, isExpanded?: boolean) => (
  <span>
    <ArrowIcon
      className={cn(styles.accordionArrowIcon, {
        [styles.expandedRow]: isExpanded,
      })}
      width={18}
      height={18}
    />
    <span className={styles.accordionGridCellContent}>{value}</span>
  </span>
);

const checkboxGridCell = (value: any, onClick?: VoidFunction) => {
  if (value === undefined) return null;
  return (
    <label className={styles.checkboxGridCell} onClick={e => e.stopPropagation()}>
      <input
        data-testid="row-selector"
        type="checkbox"
        checked={Boolean(value)}
        onChange={onClick}
      />
    </label>
  );
};

const linkGridCell = (text: string, href?: string, onClick?: VoidFunction) => (
  <a
    href={href}
    className={styles.linkGridCell}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
  >
    {text}
  </a>
);

export const GridCell = memo(_GridCell, (prevState, currState) => isEqual(prevState, currState));

GridCell.displayName = 'GridCell';
