import { FC, PropsWithChildren } from 'react';
import { Button } from '../../../../core-ui/components/button/button';
import { translate } from '../../../../helpers/utils';
import './selector-area.css';
import { Props } from './selector-area.types';

export const SelectorArea: FC<Props & PropsWithChildren> = ({
  qaId,
  onCancel,
  onSave,
  children,
}) => {
  return (
    <section className="u-background--whitesmoke u-border-radius">
      <div className="u-display--flex" data-qa={qaId} data-testid={qaId}>
        {children}
      </div>
      <div
        className={`
          u-display--flex
          u-align-items--center
          u-border--juri-very-light
          u-border-vertical
          u-padding--medium
        `}
        style={{ gap: 12 }}
      >
        <Button
          onClick={onSave}
          data-qa="selector-bar-apply"
          text={translate('analytics_home_apply')}
        />
        <Button
          variant="flat"
          text={translate('analytics_home_cancel')}
          onClick={onCancel}
          data-qa="selector-bar-cancel"
        />
      </div>
    </section>
  );
};
