import cn from 'classnames';
import { ComponentProps, FC, ChangeEvent, KeyboardEvent } from 'react';
import styles from './input.module.css';

export type InputProps = {
  type?: 'string' | 'numeric';
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  decoration?: string;
} & ComponentProps<'input'>;

export const InputField: FC<InputProps> = ({
  type = 'string',
  value,
  onInputChange,
  className,
  decoration,
  placeholder,
  ...props
}) => {
  if (type === 'numeric')
    return (
      <NumberInput
        className={cn(styles.input, className)}
        value={value as number}
        onInputChange={onInputChange}
        decoration={decoration}
        placeholder={placeholder}
      />
    );

  return (
    <input
      {...props}
      className={cn(styles.input, className)}
      onChange={onInputChange}
      type="text"
      value={value}
      autoFocus
    />
  );
};

type NumberInputProps = {
  value: number;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  decoration?: string;
} & ComponentProps<'input'>;

const NumberInput: FC<NumberInputProps> = ({
  value,
  onInputChange,
  className,
  decoration,
  ...props
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    const { value: val, selectionStart } = event.currentTarget;

    const isNumeric = !isNaN(Number(event.key));

    const isDot = event.key === '.';
    const isComma = event.key === ',';
    const isPlus = event.key === '+';
    const isMinus = event.key === '-';

    const hasDot = val.includes('.');
    const hasComma = val.includes(',');
    const hasPlus = val.includes('+');
    const hasMinus = val.includes('-');

    const isSignOnlyAtTheBeginning = (isPlus || isMinus) && selectionStart !== 0;

    const isOnlyOneSignOrDecimal =
      (isComma && hasComma) || (isDot && hasDot) || (isPlus && hasPlus) || (isMinus && hasMinus);

    const doesDecimalPointExist = (isDot || isComma) && (hasDot || hasComma);

    const isInvalidInput =
      isOnlyOneSignOrDecimal ||
      !(isComma || isDot || isPlus || isMinus || isNumeric) ||
      isSignOnlyAtTheBeginning ||
      doesDecimalPointExist;

    if (isInvalidInput && event.key !== 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <input
        type="text"
        value={value}
        onChange={onInputChange}
        {...props}
        onKeyPress={handleKeyPress}
        className={cn(styles.input, className)}
        onKeyUp={event => {
          event.currentTarget.value = event.currentTarget.value.replace(',', '.');
        }}
        autoFocus
      />
      {decoration && (
        <div className={styles.decoration}>
          <span>{decoration}</span>
        </div>
      )}
    </>
  );
};
