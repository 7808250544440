import { FC, useCallback, useEffect, useState } from 'react';
import { useIsComparing } from '../../components/date-picker/date-picker.state';
import { Tab, Tabs } from '../../components/tabs';
import {
  COLUMN_BREAKOUT_DD,
  COLUMN_BREAKOUT_DD_ID,
  COLUMN_BREAKOUT_GS,
  COLUMN_BREAKOUT_GS_ID,
  COLUMN_BREAKOUT_LOS,
  COLUMN_BREAKOUT_LOS_ID,
  COLUMN_BREAKOUT_TTT,
  COLUMN_BREAKOUT_TTT_ID,
  COLUMN_MODIFIER_PENDING_STATUS,
  COLUMN_MODIFIER_VALUE_DD,
  COLUMN_MODIFIER_VALUE_GS,
  COLUMN_MODIFIER_VALUE_LOS,
  COLUMN_MODIFIER_VALUE_TTT,
  COLUMN_NAME_CITY,
  COLUMN_NAME_COUNTRY,
  COLUMN_NAME_REGION,
  QUERY_PARAM_ACTIVE_TAB,
  TRACK_NAME_ANALYTICS_TABS,
} from '../../constants';
import { TranslationKey, translate } from '../../helpers/utils';
import { useQueryParam } from '../../hooks/use-query-param';
import { Aggregation, AnalyticsBMView, AnalyticsView, View } from '../../models';
import TrackerService from '../../services/tracker/tracker-service';
import styles from './analytics.module.css';
import { AnalyticsProps } from './analytics.types';
import { BiddingTypeView } from './bidding-type-view';
import { BmPOSView } from './bm-pos-view';
import { DimensionsView } from './dimensions-view';
import { HotelView } from './hotel-view';
import { PosView } from './pos-view';
import { PropertyGroupsView } from './property-groups-view/property-groups-view';
import { TimeView } from './time-view';
import {
  AbsoluteOrRelative,
  AbsoluteOrRelativeComparisonContext,
} from './use-absolute-or-relative-comparison';
import { AverageDataContext } from './use-average-data';
import { useSortParams } from '../../hooks/use-sort-params';

export const DEFAULT_ANALYTICS_TAB_KEY = String(View.HOTEL);

const isTabSelected = (tab: Tab, selectedTab: string): boolean => {
  if (tab.key === selectedTab) return true;
  return 'group' in tab && tab.group.some(subTab => isTabSelected(subTab, selectedTab));
};

const viewSupportsComparing = (tabs: Tab[], selectedTab: string) =>
  !tabs.find(tab => isTabSelected(tab, selectedTab))?.isDisabled;
const TAB_LABELS = {
  [String(View.HOTEL)]: translate('analytics_home_hotel_view'),
  [String(View.POS)]: translate('analytics_home_pos_view'),
  [String(View.TIME)]: translate('analytics_home_time_view'),
  [String(View.BIDDING_TYPE)]: translate('analytics_home_bidding_type_view'),
  [String(View.BM_POS_LENGTH_OF_STAY)]: translate('analytics_home_bm_pos_length_of_stay'),
  [String(View.BM_POS_TIME_TO_TRAVEL)]: translate('analytics_home_bm_pos_time_to_travel'),
  [String(View.BM_POS_DEFAULT_DATE)]: translate('analytics_home_bm_pos_default_date'),
  [String(View.BM_POS_GROUP_SIZE)]: translate('analytics_home_bm_pos_group_size'),
  [String(View.BM_POS_COMBINED_MODIFIERS)]: translate('analytics_home_bm_pos_combined_modifiers'),
  [String(View.CITY)]: translate('analytics_home_others_city_dimension'),
  [String(View.RATING)]: translate('analytics_home_others_rating_dimension'),
  [String(View.COUNTRY)]: translate('analytics_home_others_country_dimension'),
  [String(View.ACCOMMODATION_TYPE)]: translate(
    'analytics_home_others_accommodation_type_dimension',
  ),
  [String(View.STARS)]: translate('analytics_home_others_stars_dimension'),
  [String(View.REGION)]: translate('analytics_home_others_region_dimension'),
  [String(View.PROPERTY_GROUPS)]: 'Property Groups',
};
const otherDimensionsViews: AnalyticsView[] = [
  {
    view: View.CITY,
    aggregation: Aggregation.CITY,
    translationKey: 'analytics_home_others_city_dimension',
    search: {
      placeholder: 'analytics_home_others_search_city',
      filterColumns: [COLUMN_NAME_CITY, 'city'],
    },
    id: 'city',
  },
  {
    view: View.RATING,
    aggregation: Aggregation.RATING,
    translationKey: 'analytics_home_others_rating_dimension',
    id: 'rating',
  },
  {
    view: View.COUNTRY,
    aggregation: Aggregation.COUNTRY,
    translationKey: 'analytics_home_others_country_dimension',
    search: {
      placeholder: 'analytics_home_others_search_country',
      filterColumns: [COLUMN_NAME_COUNTRY],
    },
    id: 'country',
  },
  {
    view: View.ACCOMMODATION_TYPE,
    aggregation: Aggregation.ACCOMMODATION_TYPE,
    translationKey: 'analytics_home_others_accommodation_type_dimension',
    id: 'accommodation',
  },
  {
    view: View.STARS,
    aggregation: Aggregation.STARS,
    translationKey: 'analytics_home_others_stars_dimension',
    id: 'stars',
  },
  {
    view: View.REGION,
    aggregation: Aggregation.REGION,
    translationKey: 'analytics_home_others_region_dimension',
    search: {
      placeholder: 'analytics_home_others_search_region',
      filterColumns: [COLUMN_NAME_REGION],
    },
    id: 'region',
  },
];

export const Analytics: FC<AnalyticsProps> = ({
  selectedPartner,
  subBrands,
  posLocales,
  from,
  to,
}) => {
  const { value: selectedTab, setQueryParam: setSelectedTab } =
    useQueryParam(QUERY_PARAM_ACTIVE_TAB);
  const [isComparing] = useIsComparing();

  const { isDateComparisonActive, period } = useSortParams(['isDateComparisonEnabled', 'period']);

  useEffect(() => {
    const groupName = TRACK_NAME_ANALYTICS_TABS;
    const tab = selectedTab ?? DEFAULT_ANALYTICS_TAB_KEY;

    const props =
      isDateComparisonActive || period !== undefined
        ? { triggered_by: `yesterday's overview on dashboard` }
        : {};

    TrackerService.trackTabChange({ key: tab, label: TAB_LABELS[tab] } as Tab, groupName, props);
  }, [selectedTab]);

  const cpcBiddingModifierTabs = useCallback((): Tab[] => {
    const result: Tab[] = CPCbiddingModifierViews.map(
      ({ translationKey, view, aggregation, columnsInfo }) => ({
        key: String(view),
        label: translate(translationKey as TranslationKey),
        content: (
          <BmPOSView
            key={view}
            view={view}
            aggregation={aggregation}
            columnsInfo={columnsInfo}
            viewTranslationKey={translationKey}
          />
        ),
      }),
    );

    result.unshift({
      key: String(View.PROPERTY_GROUPS),
      label: 'Property Groups',
      content: <PropertyGroupsView />,
    });

    return result;
  }, []);

  const tabs = useCallback((): Tab[] => {
    const result: Tab[] = [
      {
        key: String(View.HOTEL),
        label: translate('analytics_home_hotel_view'),
        content: <HotelView />,
      },
      {
        key: String(View.POS),
        label: translate('analytics_home_pos_view'),
        content: (
          <PosView
            partner={selectedPartner!!}
            subPartners={subBrands}
            locales={posLocales}
            from={from}
            to={to}
          />
        ),
      },
      {
        key: String(View.TIME),
        label: translate('analytics_home_time_view'),
        content: (
          <TimeView
            partner={selectedPartner!!}
            subPartners={subBrands}
            locales={posLocales}
            from={from}
            to={to}
          />
        ),
      },
    ];

    return [
      ...result,
      {
        key: String(View.BIDDING_TYPE),
        label: translate('analytics_home_bidding_type_view'),
        content: (
          <BiddingTypeView
            partner={selectedPartner!!}
            subPartners={subBrands}
            locales={posLocales}
            from={from}
            to={to}
          />
        ),
        isDisabled: isComparing,
      },
      {
        key: 'cpc-bid-modifiers',
        label: translate('analytics_bidding_modifiers'),
        group: cpcBiddingModifierTabs(),
        isDisabled: isComparing,
      },
      {
        key: 'others',
        label: translate('analytics_home_others'),
        group: otherDimensionsViews.map(({ translationKey, view, aggregation, search }) => ({
          key: String(view),
          label: translate(translationKey as TranslationKey),
          content: (
            <DimensionsView key={view} view={view} aggregation={aggregation} searchData={search} />
          ),
        })),
        isDisabled: isComparing,
      },
    ];
  }, [selectedPartner, subBrands, posLocales, from, to, isComparing, cpcBiddingModifierTabs]);

  const [absoluteOrRelative, setAbsoluteOrRelative] = useState<AbsoluteOrRelative>('relative');

  const [isAverageDataActive, setAverageDataActive] = useState(
    isDateComparisonActive || period !== undefined,
  );

  useEffect(() => {
    if (!isComparing) {
      setAbsoluteOrRelative('relative');
    }
    if (!isComparing || viewSupportsComparing(tabs(), selectedTab ?? DEFAULT_ANALYTICS_TAB_KEY)) {
      return;
    }
    setSelectedTab(DEFAULT_ANALYTICS_TAB_KEY);
  }, [isComparing, selectedTab, setSelectedTab, tabs]);

  return (
    <main className={styles.main}>
      <AbsoluteOrRelativeComparisonContext.Provider
        value={{ activeTrend: absoluteOrRelative, setActiveTrend: setAbsoluteOrRelative }}
      >
        <AverageDataContext.Provider value={{ isAverageDataActive, setAverageDataActive }}>
          <Tabs
            activeTabKey={selectedTab ?? View.HOTEL.toString()}
            onTabChange={tab => {
              setSelectedTab(tab.key);
            }}
            tabs={tabs()}
          />
        </AverageDataContext.Provider>
      </AbsoluteOrRelativeComparisonContext.Provider>
    </main>
  );
};

const CPCbiddingModifierViews: AnalyticsBMView[] = [
  {
    view: View.BM_POS_LENGTH_OF_STAY,
    aggregation: Aggregation.BM_POS_LENGTH_OF_STAY,
    translationKey: 'analytics_home_bm_pos_length_of_stay',
    columnsInfo: {
      description: COLUMN_BREAKOUT_LOS,
      value: COLUMN_MODIFIER_VALUE_LOS,
      pending: COLUMN_MODIFIER_PENDING_STATUS,
      id: COLUMN_BREAKOUT_LOS_ID,
    },
    id: 'bm-pos-length-of-stay',
  },
  {
    view: View.BM_POS_TIME_TO_TRAVEL,
    aggregation: Aggregation.BM_POS_TIME_TO_TRAVEL,
    translationKey: 'analytics_home_bm_pos_time_to_travel',
    columnsInfo: {
      description: COLUMN_BREAKOUT_TTT,
      value: COLUMN_MODIFIER_VALUE_TTT,
      pending: COLUMN_MODIFIER_PENDING_STATUS,
      id: COLUMN_BREAKOUT_TTT_ID,
    }, // TODO add correct property
    id: 'bm-pos-time-to-travel',
  },
  {
    view: View.BM_POS_DEFAULT_DATE,
    aggregation: Aggregation.BM_POS_DEFAULT_DATE,
    translationKey: 'analytics_home_bm_pos_default_date',
    columnsInfo: {
      description: COLUMN_BREAKOUT_DD,
      value: COLUMN_MODIFIER_VALUE_DD,
      pending: COLUMN_MODIFIER_PENDING_STATUS,
      id: COLUMN_BREAKOUT_DD_ID,
    },
    id: 'bm-pos-default-date',
  },
  {
    view: View.BM_POS_GROUP_SIZE,
    aggregation: Aggregation.BM_POS_GROUP_SIZE,
    translationKey: 'analytics_home_bm_pos_group_size',
    columnsInfo: {
      description: COLUMN_BREAKOUT_GS,
      value: COLUMN_MODIFIER_VALUE_GS,
      pending: COLUMN_MODIFIER_PENDING_STATUS,
      id: COLUMN_BREAKOUT_GS_ID,
    },
    id: 'bm-pos-group-size',
  },
  {
    view: View.BM_POS_COMBINED_MODIFIERS,
    aggregation: Aggregation.BM_POS_COMBINED_MODIFIERS,
    translationKey: 'analytics_home_bm_pos_combined_modifiers',
    id: 'bm-combined',
  },
];
