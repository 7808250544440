import { Link } from 'react-router-dom';
import { NotificationLevel } from '../../../../constants';
import { Alert } from '../../../../core-ui/components/alert/alert';
import { BaseNotification } from '../../../../models';

function alertVariantOf(level: NotificationLevel) {
  switch (level) {
    case NotificationLevel.ERROR:
      return 'error';
    case NotificationLevel.WARNING:
      return 'warning';
    default:
      return 'success';
  }
}

export const renderAlertNotification = (
  notification: BaseNotification | undefined,
  onClose: () => void,
) => {
  if (!notification) return null;

  const errorMessage =
    notification.level === NotificationLevel.ERROR ? notification.message : undefined;
  const variant = alertVariantOf(notification.level);

  return (
    <Alert
      variant={variant}
      message={errorMessage as string}
      onClose={onClose}
      style={{ marginTop: 12 }}
    >
      {variant === 'success' || variant === 'warning' ? (
        <>
          <b>{notification.message[0]}</b>
          <p>
            {notification.message[1]} <Link to="history">History</Link>
          </p>
        </>
      ) : null}
    </Alert>
  );
};
