import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { HotelViewControlPanel } from '../../../../components/hotel-control-panel';
import { Loading } from '../../../../components/loading';
import { Notification } from '../../../../components/notification';
import {
  ANALYTICS_ROUTE,
  DETAILED_HOTEL_VIEW_PREFIX,
  HOTEL_DETAILS_BID_OPTION_DROPDOWN,
  TIMEOUT_NOTIFICATION,
} from '../../../../constants';
import { BidFormContext } from '../../../../features/bid-form/bid-form-context';
import { BidFormUnit, EURO } from '../../../../features/bid-form/bid-form.types';
import { translate } from '../../../../helpers/utils';
import { useMaxCPCBidCap } from '../../../../hooks/use-max-CPC-cap';
import { useSortParams } from '../../../../hooks/use-sort-params';
import { BaseNotification, BiddingActionType } from '../../../../models';
import TrackerService from '../../../../services/tracker/tracker-service';
import { HotelDetailInformation } from '../hotel-detail-information';
import './general-information-panel.css';
import { GeneralInformationPanelProps } from './general-information-panel.types';

export const GeneralInformationPanel: FC<GeneralInformationPanelProps> = ({
  hotel,
  isLoading,
  onUpdate,
}) => {
  const [notification, setNotification] = useState<BaseNotification | undefined>(undefined);
  const [isRedirectActive, setRedirectActive] = useState(false);
  const location = useLocation();

  const [bidFormValue, setBidFormValue] = useState('');
  const [bidFormUnit, setBidFormUnit] = useState<BidFormUnit>(EURO);
  const { maxCPCBidCap } = useMaxCPCBidCap();

  const onShowNotification = (notificationTmp: BaseNotification) => {
    setNotification(notificationTmp);
    setTimeout(onCloseNotification, TIMEOUT_NOTIFICATION);
  };
  const onCloseNotification = () => setNotification(undefined);
  const { columnKey: sortBy, direction: orderBy } = useSortParams(['sortBy', 'orderBy']);

  const sortAndOrderByParams = sortBy && orderBy ? `?sortBy=${sortBy}&orderBy=${orderBy}` : '';

  const handleBidFormUnitChange = (unit: BidFormUnit) => {
    setBidFormUnit(unit);
    TrackerService.track(HOTEL_DETAILS_BID_OPTION_DROPDOWN, {
      option: translate(
        unit === EURO ? 'bid_form_cpc_set_to' : 'bid_form_cpc_change_by_percentage',
      ),
    });
  };

  useEffect(() => {
    setBidFormValue(bidFormUnit === EURO && hotel ? hotel.bidCPC?.toString() : '');
  }, [hotel, bidFormUnit]);

  return (
    <>
      {isRedirectActive && (
        <Redirect
          to={{
            pathname: ANALYTICS_ROUTE,
            state: { from: location.pathname },
            search: sortAndOrderByParams,
          }}
        />
      )}
      {isLoading ? (
        <div className="u-background--blue-very-light">
          <Loading isWhite={true} className="u-padding--gutter" />
        </div>
      ) : (
        <section>
          <BidFormContext.Provider
            value={{
              value: bidFormValue,
              setValue: setBidFormValue,
              unit: bidFormUnit,
              setUnit: handleBidFormUnitChange,
              bidItems: [
                {
                  baseValue: hotel?.bidCPC as number,
                  biddingType: 0,
                  capValue: maxCPCBidCap ?? 6,
                  minValue: 0,
                  name: hotel?.name as string,
                  id: hotel?.partnerRef as string,
                },
              ],
            }}
          >
            <HotelViewControlPanel
              hotels={hotel !== undefined ? [hotel] : []}
              onUpdateTable={onUpdate}
              onShowNotification={onShowNotification}
              onClickLeading={() => setRedirectActive(true)}
              isShowingDetail={true}
              trackingPrefix={DETAILED_HOTEL_VIEW_PREFIX}
              biddingActionType={BiddingActionType.CustomEditCpc}
            />
            {hotel !== undefined && <HotelDetailInformation hotel={hotel} />}
            {notification && (
              <Notification notification={notification} onClose={onCloseNotification} />
            )}
          </BidFormContext.Provider>
        </section>
      )}
    </>
  );
};
