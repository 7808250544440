import { FC, useEffect } from 'react';
import { AdjustedBidItem } from 'src/features/bid-form/bid-form.types';
import iconLockLineDark from '../../../components/hotel-control-panel/icn-lock-line-dark.svg';
import {
  CAMPAIGNS_VIEW_MULTIPLE_CPA_VALUE_APPLIED,
  CAMPAIGNS_VIEW_SINGLE_CPA_VALUE_APPLIED,
  CHANGE_BID_ON_BOARDING,
  NotificationLevel,
} from '../../../constants';
import { ONBOARDING_TARGETS } from '../../../core-ui/components/onboarding-tour/onboarding-tour-helper';
import { TourStep } from '../../../core-ui/components/onboarding-tour/onboarding-tour.types';
import { BidForm } from '../../../features/bid-form/bid-form';
import { apiBiddingHelper } from '../../../helpers/api/bidding/api-bidding-helper';
import { translate } from '../../../helpers/utils';
import { useOnboardingTour } from '../../../hooks/use-onboarding-tour';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { EventType } from '../../../models';
import { showNotification } from '../../../services/notification-service';
import TrackerService from '../../../services/tracker/tracker-service';
import { useRemainingPushes } from '../../history/history-toolbar/use-remaining-pushes';
import styles from './control-panel.module.css';
import { Props } from './control-panel.types';
import { MultiSelectionInfo } from './multi-selection-info';
import { SingleSelectionInfo } from './single-selection-info';

export const ControlPanel: FC<Props> = ({ campaigns, onApply, onClose }) => {
  const { partnerId } = useSelectedPartner();
  const { data } = useRemainingPushes(partnerId);

  function isDailyPushLimitReached(): boolean {
    const remainingPushes = data || {};
    const remainingPushesCpaValue = remainingPushes[EventType.PUSH_CPA_VALUE] || 0;
    return remainingPushesCpaValue === 0;
  }

  const apply = async (adjustedRows: (AdjustedBidItem & { localeCode: string })[]) => {
    try {
      const CPABids = adjustedRows.map(row => ({
        biddingType: row.biddingType,
        localeCode: row.localeCode,
        value: row.acceptedValue.toString(),
      }));

      const result = await apiBiddingHelper.applyCpaValueBids(partnerId, CPABids);

      if (CPABids.length === 1) TrackerService.track(CAMPAIGNS_VIEW_SINGLE_CPA_VALUE_APPLIED);
      else TrackerService.track(CAMPAIGNS_VIEW_MULTIPLE_CPA_VALUE_APPLIED);

      onApply(result);
      onClose();
    } catch {
      showNotification({
        message: `${translate('common_fail')} ${translate('analytics_perform_bids_error')}`,
        level: NotificationLevel.ERROR,
      });
    }
  };
  const steps = Object.values(ONBOARDING_TARGETS.CHANGE_BID) as unknown as TourStep[];
  const { startTour } = useOnboardingTour({
    steps: steps,
    tourName: CHANGE_BID_ON_BOARDING,
  });

  useEffect(() => {
    startTour();
  }, []);
  function body() {
    if (isDailyPushLimitReached()) {
      return (
        <div className="u-display--flex u-margin-left--huge u-align-items--center">
          <img
            src={iconLockLineDark}
            className="c-panel-control__icn u-margin-right--tiny u-color--juri"
            alt="Lock icon showing bidding is not possible"
          />
          <span
            className="u-color--juri c-panel-control__lock"
            data-testid="disabled-bidding-message"
          >
            {translate('campaigns_control_panel_locked_limit_reached')}
          </span>
        </div>
      );
    }

    return (
      <>
        <div className="u-display--flex u-padding-left--gutter">
          {campaigns.length > 1 ? (
            <MultiSelectionInfo numberOfCampaigns={campaigns.length} onClose={onClose} />
          ) : (
            <SingleSelectionInfo campaign={campaigns[0]} />
          )}
        </div>
        <BidForm type="CPA" label="CPA value" onSubmit={apply} />
      </>
    );
  }

  return <section className={styles.controlPanel}>{body()}</section>;
};
