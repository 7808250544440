export enum BidFormTypesEnum {
  CPC = 'CPC',
  CPA = 'CPA',
}

export type BidFormTypes = `${BidFormTypesEnum}`;

export type BidFormProps = {
  type: BidFormTypes;
  label: string;
  onSubmit: (rows: AdjustedBidItem[]) => void;
};

export type BidItem = {
  id: string;
  name: string;
  baseValue: number;
  capValue: number;
  minValue: number;
  biddingType: number;
};

export type AdjustedBidItem = BidItem & {
  adjustedValue: number;
  acceptedValue: number;
  isExceeded: boolean;
  isBelow: boolean;
};

export const EURO = '€';
export const PERCENTAGE = '%';

export type BidFormUnit = typeof EURO | typeof PERCENTAGE;
