import { FC, useRef, useState } from 'react';
import { MenuItem } from '../../core-ui/components/menu/menu';
import { translate } from '../../helpers/utils';
import styles from './bid-form.module.css';
import { BidFormUnit, EURO, PERCENTAGE } from './bid-form.types';
import { Button } from '../../core-ui/components/button/button';
import { AnchoredMenu } from '../../core-ui/components/menu/anchored-menu';
import { ONBOARDING_TOURS } from '../../constants/onboarding-targets';

type BidFormLabelProps = {
  label: string;
  unit: BidFormUnit;
  onUnitChange: (unit: BidFormUnit) => void;
};

export const BidFormLabel: FC<BidFormLabelProps> = ({ label, unit, onUnitChange }) => {
  const [showSetToPopover, setShowSetToPopover] = useState(false);
  const setToElementRef = useRef<HTMLButtonElement>(null);

  const items: MenuItem[] = [
    {
      id: EURO,
      label: translate('bid_form_cpc_set_to'),
      onClick: () => {
        onUnitChange(EURO);
        handlePopoverClose();
      },
    },
    {
      id: PERCENTAGE,
      label: `${translate('bid_form_cpc_change_by')} ${PERCENTAGE}`,
      onClick: () => {
        onUnitChange(PERCENTAGE);
        handlePopoverClose();
      },
    },
  ];

  const handleSetToToggle = () => {
    setShowSetToPopover(!showSetToPopover);
  };

  const handlePopoverClose = () => {
    setShowSetToPopover(false);
  };

  return (
    <div className={styles.formHeaderContainer}>
      <span className={styles.formHeaderLabel}>{label}</span>
      <span ref={setToElementRef}>
        <Button
          data-tour={ONBOARDING_TOURS.CHANGE_BID.STEP_1}
          className={styles.setToButton}
          onClick={handleSetToToggle}
          variant="flat"
          text={
            unit === EURO
              ? translate('bid_form_cpc_set_to')
              : `${translate('bid_form_cpc_change_by')} ${PERCENTAGE}`
          }
        />
      </span>
      {showSetToPopover && (
        <AnchoredMenu
          anchor={setToElementRef.current as HTMLElement}
          items={items}
          onClose={handlePopoverClose}
          className={styles.setToMenu}
          activeId={unit}
          data-tour={ONBOARDING_TOURS.CHANGE_BID.STEP_2}
        />
      )}
    </div>
  );
};
