import cn from 'classnames';
import { FC } from 'react';
import { AdjustedBidItem } from 'src/features/bid-form/bid-form.types';
import { BiddingResponse } from 'src/helpers/api/bidding/types';
import {
  MANUAL_APPLY_EXCEED_LIMIT_CPC,
  MANUAL_APPLY_MULTIPLE_PROPERTIES,
  MANUAL_APPLY_SINGLE_PROPERTIES,
  MANUAL_APPLY_SUCCESSFULLY_UPDATED,
  NotificationLevel,
} from '../../constants';
import { Icon } from '../../core-ui/components/icon';
import { translate } from '../../helpers/utils';
import { useMaxCPCBidCap } from '../../hooks/use-max-CPC-cap';
import { useSelectedLocales } from '../../hooks/use-selected-locales/use-selected-locales';
import { useSelectedPartner, useSelectedSubPartners } from '../../hooks/use-selected-partner';
import { BaseNotification, Bid, BiddingActionType, EventType } from '../../models';
import {
  areMultipleBrandsSelected,
  areMultiplePOSorGlobalSelected,
} from '../../services/app-service';
import { applyBids } from '../../services/bidding-service';
import TrackerService from '../../services/tracker/tracker-service';
import { useRemainingPushes } from '../../views/history/history-toolbar/use-remaining-pushes';
import { Loading } from '../loading';
import { UpdateHotelBidCPCForm } from './change-hotel-bid-cpc';
import './hotel-control-panel.css';
import styles from './hotel-control-panel.module.css';
import { HotelControlPanelProps } from './hotel-control-panel.types';
import iconLockLineDark from './icn-lock-line-dark.svg';

export const HotelControlPanel: FC<HotelControlPanelProps> = ({
  hotels,
  onUpdateTable,
  onShowNotification,
  onClickLeading,
  isShowingDetail,
  trackingPrefix,
  biddingActionType,
  maxCPCBidCap,
}) => {
  const selectedLocales = useSelectedLocales();
  const partner = useSelectedPartner();
  const subPartners = useSelectedSubPartners();

  const partnerId = partner.partnerId;

  const resolveBidValueName = () => {
    switch (biddingActionType) {
      case BiddingActionType.CustomEditCpc:
        return translate('analytics_control_panel_current_bid_cpc');
      case BiddingActionType.SlBid:
        return translate('sl_analytics_control_panel_current_sl_bid_cpc');
      default:
        return 'value';
    }
  };

  const resolvePushType = () => {
    switch (biddingActionType) {
      case BiddingActionType.CustomEditCpc:
        return EventType.PUSH;
      case BiddingActionType.SlBid:
        return EventType.PUSH_SL_BID;
      default:
        throw new Error('Unsupported');
    }
  };

  const nameSelection = () => {
    return (
      <>
        {(isShowingDetail || hotels.length) && (
          <Icon
            name={isShowingDetail ? 'arrowDown' : 'close'}
            onClick={onClickLeading}
            className={styles.nameSelectionIcon}
          />
        )}
        {hotels.length === 1 ? (
          <span className="u-font-weight-bold">{hotels[0].name}</span>
        ) : hotels.length > 1 ? (
          <span className="u-font-weight-bold">
            {hotels.length} {translate('analytics_control_panel_properties_selected')}
          </span>
        ) : (
          <></>
        )}
      </>
    );
  };

  const { data } = useRemainingPushes(partnerId);
  const remainingPushes = data || {};
  const dailyLimitReached = remainingPushes[resolvePushType()] === 0;

  const isBlocked = () =>
    dailyLimitReached ||
    areMultipleBrandsSelected(subPartners) ||
    areMultiplePOSorGlobalSelected(selectedLocales) ||
    maxCPCBidCap === undefined;

  const track = (eventName: string) => {
    return TrackerService.track(`${trackingPrefix}: ${eventName}`);
  };

  const sendTracker = () => {
    if (hotels.length === 1) {
      track(MANUAL_APPLY_SINGLE_PROPERTIES);
    } else {
      track(MANUAL_APPLY_MULTIPLE_PROPERTIES);
    }
  };

  const applyCPC = async (adjustedRows: AdjustedBidItem[]) => {
    const isCPCOverLimit = adjustedRows.some(
      (bid: AdjustedBidItem) => bid.adjustedValue > maxCPCBidCap!,
    );

    sendTracker();
    if (isCPCOverLimit) {
      track(MANUAL_APPLY_EXCEED_LIMIT_CPC);
    }
    try {
      const body: Bid[] = adjustedRows.map((bid: AdjustedBidItem) => ({
        cpc: (bid.acceptedValue * 100).toFixed(0).toString(),
        partnerReference: bid.id,
        localeCode: selectedLocales[0],
      }));

      const biddingResponse = await applyBids(partnerId, body, biddingActionType);
      showNotificationFor(biddingResponse);
      onUpdateTable();
    } catch {
      const notification: BaseNotification = {
        level: NotificationLevel.ERROR,
        message: `${translate('analytics_perform_bids_error')}`,
      };
      onShowNotification(notification);
    }
  };

  function showNotificationFor(biddingResponse: BiddingResponse) {
    const notification: BaseNotification = {
      level: NotificationLevel.SUCCESS,
      message: '',
    };
    if (biddingResponse.updatedBids === 0) {
      notification.level = NotificationLevel.ERROR;
      notification.message = translate('analytics_perform_bids_error');
    } else if (biddingResponse.updatedBids === biddingResponse.totalBids) {
      notification.level = NotificationLevel.SUCCESS;
      const keyProperty = biddingResponse.totalBids === 1 ? 'common_property' : 'common_properties';
      notification.message = [
        `${translate('analytics_perform_bids_success')} ${biddingResponse.totalBids} ${translate(keyProperty)}.`,
        `${translate('analytics_perform_bids_success_extra_info')}`,
      ];
      track(MANUAL_APPLY_SUCCESSFULLY_UPDATED);
    } else {
      notification.level = NotificationLevel.WARNING;
      notification.message = [
        `${translate('analytics_perform_bids_partial_success')} ${biddingResponse.updatedBids} 
        ${translate('common_of_the')} ${biddingResponse.totalBids} ${translate('common_selected_properties')}`,
        `${translate('analytics_check_status')}`,
      ];
    }

    onShowNotification(notification);
  }

  //TODO change so that would react to several selected bidding types
  const isCPA = hotels.some(hotelTmp => hotelTmp.biddingType.toLowerCase().indexOf('cpa') !== -1);

  const blockedMessage = () => {
    if (dailyLimitReached) {
      return translate('analytics_control_panel_locked_limit_reached', resolveBidValueName());
    }

    if (areMultipleBrandsSelected(subPartners) || areMultiplePOSorGlobalSelected(selectedLocales)) {
      return translate(
        'analytics_control_panel_locked_multiple_locales_or_brands',
        resolveBidValueName(),
      );
    }

    if (maxCPCBidCap === undefined) return translate('max_cpc_bids_cap_error');

    return translate('analytics_control_panel_locked');
  };

  return (
    <>
      <div
        className={cn(`${styles.panelControl}`, {
          [styles.panelControlHotelListing]: !isShowingDetail,
        })}
      >
        <div className="u-display--flex c-panel-control__selection-information u-margin-left--gutter u-align-items--center">
          {nameSelection()}
          <div className="u-display--flex u-flex-direction--column">
            {isBlocked() && (
              <div className="u-display--flex u-margin-left--huge">
                <img
                  src={iconLockLineDark}
                  className="c-panel-control__icn u-margin-right--tiny u-color--juri"
                  alt="Lock icon showing bidding is not possible"
                />
                <span
                  className="u-color--juri c-panel-control__lock"
                  data-testid="disabled-bidding-message"
                >
                  {blockedMessage()}
                </span>
                {isShowingDetail && hotels.length === 1 && <br />}
              </div>
            )}
          </div>
        </div>

        {!isBlocked() && (
          <div className={styles.bidFormContainer}>
            <UpdateHotelBidCPCForm label={resolveBidValueName()} onSubmit={applyCPC} />
          </div>
        )}
      </div>
      {!isBlocked() && isCPA && (
        <div className="u-padding-h--small c-panel-control__information u-padding-left--large">
          {translate('analytics_control_panel_cpa')}
        </div>
      )}
    </>
  );
};

export const HotelViewControlPanel: FC<Omit<HotelControlPanelProps, 'maxCPCBidCap'>> = props => {
  const { maxCPCBidCap, isLoading } = useMaxCPCBidCap();

  if (isLoading) return <Loading />;

  return <HotelControlPanel {...props} maxCPCBidCap={maxCPCBidCap} />;
};
