import { YesterdayOverviewWidgetProps } from './yesterday-overview-widget.types';
import { translate, getLastXDays, dateFormatterLongYear } from '../../../helpers/utils';
import { Widget } from '../widget';
import { WidgetRow } from '../widget/widget-row';
import { WidgetTable } from '../widget/widget-table';
import { AppContext } from '../../../global/context/app-context';
import { getPartnersId } from '../../../services/app-service';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { WidgetNoData } from '../widget/widget-no-data';
import { MetricTrend } from './metric-trend';
import { ButtonRetry } from '../../../components/button-retry';
import { useYesterdayOverviewData } from './hooks/use-yesterday-overview-data';
import { DatePeriod, View } from '../../../models';
import { FC, useContext, useEffect } from 'react';
import { ANALYTICS_ROUTE, QUICK_DATA_EXPLORATION_ON_BOARDING } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import trackerService from '../../../services/tracker/tracker-service';
import { TourStep } from '../../../core-ui/components/onboarding-tour/onboarding-tour.types';
import { useOnboardingTour } from '../../../hooks/use-onboarding-tour/';
import { ONBOARDING_TARGETS } from '../../../core-ui/components/onboarding-tour/onboarding-tour-helper';

type MenuItem = {
  menu: string;
  id: number;
  viewId?: View;
  nestedMenu?: MenuItem[];
  parentMenu?: string;
  period?: DatePeriod;
};

export const YesterdayOverviewWidget: FC<YesterdayOverviewWidgetProps> = ({ onRemoveWidget }) => {
  const { selectedLocales, partner, subPartners } = useContext(AppContext);
  const { columns, data, isFetching, refetch, isError } = useYesterdayOverviewData(
    {
      partnersId: getPartnersId(partner, subPartners),
      localeCodes: [...selectedLocales],
    },
    [selectedLocales, subPartners],
  );
  const navigate = useNavigate();

  // TODO: remove unknown
  const steps = Object.values(ONBOARDING_TARGETS.QUICK_DATA_EXPLORATION) as unknown as TourStep[];
  const { startTour } = useOnboardingTour({
    steps: steps,
    tourName: QUICK_DATA_EXPLORATION_ON_BOARDING,
  });

  useEffect(() => {
    startTour();
  }, []);

  const handleNavigation = (menuId: number, key: string, contextMenu: MenuItem[], name: string) => {
    const menu = findMenuById(contextMenu, menuId);
    if (!menu) return;

    trackerService.track(`Examine by: Yesterday's overview widget clicked`, {
      examine_by: menu.parentMenu,
      examine_metric: name,
      menu_clicked: menu.menu,
    });

    navigate({ pathname: ANALYTICS_ROUTE, search: getParams(menu, key) });
  };

  return (
    <Widget
      menuItems={[<WidgetRemoveMenuItem key="1" />]}
      onSelectMenuItem={onRemoveWidget}
      isLoading={isFetching}
      title={
        <span className="u-font-weight--bold">
          {translate(
            'dashboard_yesterday_overview_widget_title',
            dateFormatterLongYear(getLastXDays(1)),
          )}
        </span>
      }
    >
      <div className="u-display--flex u-justify-content--center u-align-items--center u-height--100 ">
        {isError ? (
          <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />
        ) : data?.size === 0 ? (
          <WidgetNoData />
        ) : (
          <WidgetTable>
            {columns?.map(({ key, name, tooltip, wrapper }, index) => {
              const metricData = data?.get(key);
              return (
                <WidgetRow
                  key={index}
                  field={{ text: name, tooltip }}
                  contextMenu={{ menuItems: getContextMenu(name) }}
                  applyContextMenu={menuId =>
                    handleNavigation(menuId, key, getContextMenu(name), name)
                  }
                  handleContextMenuClick={() =>
                    trackerService.track(`Examine by: kebab button clicked`)
                  }
                >
                  <>
                    {metricData !== undefined ? (
                      <MetricTrend wrapper={wrapper} metric={metricData} />
                    ) : (
                      <td colSpan={3}>{translate('common_not_available')}</td>
                    )}
                  </>
                </WidgetRow>
              );
            })}
          </WidgetTable>
        )}
      </div>
    </Widget>
  );
};

const getContextMenu = (menuName: string) => [
  {
    id: 0,
    menu: `Highest ${menuName}`,
    nestedMenu: [
      { id: 1, menu: translate('analytics_home_hotel_view'), viewId: View.HOTEL },
      {
        id: 2,
        menu: translate('analytics_home_pos_selector'),
        viewId: View.POS,
      },
    ],
  },
  {
    id: 1,
    menu: `Lowest ${menuName}`,
    nestedMenu: [
      { id: 3, menu: translate('analytics_home_hotel_view'), viewId: View.HOTEL },
      {
        id: 4,
        menu: translate('analytics_home_pos_selector'),
        viewId: View.POS,
      },
    ],
  },
  {
    id: 2,
    menu: translate('analytics_calendar_time_period'),
    nestedMenu: [
      {
        id: 5,
        menu: translate('analytics_calendar_last_7_days'),
        viewId: View.TIME,
        period: DatePeriod.LAST_7,
      },
      {
        id: 6,
        menu: translate('analytics_calendar_last_30_days'),
        viewId: View.TIME,
        period: DatePeriod.LAST_30,
      },
      {
        id: 7,
        menu: translate('analytics_calendar_this_week'),
        viewId: View.TIME,
        period: DatePeriod.THIS_WEEK,
      },
      {
        id: 8,
        menu: translate('analytics_calendar_this_month'),
        viewId: View.TIME,
        period: DatePeriod.THIS_MONTH,
      },
      {
        id: 9,
        menu: translate('analytics_calendar_last_month'),
        viewId: View.TIME,
        period: DatePeriod.LAST_MONTH,
      },
    ],
  },
];

const findMenuById = (menuItems: MenuItem[], id: number): MenuItem | void =>
  menuItems
    .flatMap(
      menuItem =>
        menuItem.nestedMenu?.map(nestedMenuItem => ({
          ...nestedMenuItem,
          parentMenu: menuItem.menu,
        })) || [],
    )
    .find(nestedMenuItem => nestedMenuItem.id === id);

const getParams = (menu: MenuItem, key: string): string => {
  const params = new URLSearchParams();

  params.append('tab', String(menu.viewId));

  if (menu.period) {
    params.append('period', menu.period);
    return params.toString();
  }

  let orderBy = '';
  if (menu.parentMenu?.toLowerCase().includes('highest')) {
    orderBy = 'desc';
  }
  if (menu.parentMenu?.toLowerCase().includes('lowest')) {
    orderBy = 'asc';
  }

  const sortBy = orderBy === '' ? '' : key;
  params.append('sortBy', sortBy);
  params.append('orderBy', orderBy);
  params.append('isDateComparisonEnabled', 'true');

  return params.toString();
};
