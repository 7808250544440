import { POS } from './pos';

export type PerformedBids = {
  updatedBids: number;
  totalBids: number;
};

export interface BiddingCampaign {
  id?: string;
  cpaValue?: number;
  minCpaValue: number;
  type: CampaignType;
  pos: POS;
}

export type CampaignType = {
  code: string;
  value: number;
};

const ALL_CAMPAIGN_TYPES: readonly CampaignType[] = [
  { value: 0, code: 'Manual' },
  { value: 1, code: 'CPA 01' },
  { value: 2, code: 'CPA 02' },
  { value: 3, code: 'CPA 03' },
  { value: 4, code: 'CPA 04' },
  { value: 5, code: 'CPA 05' },
  { value: 6, code: 'CPA 06' },
  { value: 7, code: 'CPA 07' },
  { value: 8, code: 'CPA 08' },
  { value: 9, code: 'CPA 09' },
  { value: 10, code: 'CPA 10' },
  { value: 11, code: 'CPA 11' },
  { value: 12, code: 'CPA 12' },
  { value: 14, code: 'CPA 99_beta' },
] as const;

export function getAllCampaignTypes() {
  return ALL_CAMPAIGN_TYPES;
}

export const codesToValues = (codes: string[]): number[] =>
  codes
    .map(code => ALL_CAMPAIGN_TYPES.find(t => t.code === code)?.value)
    .filter(v => v !== undefined) as number[];

export const valuesToCodes = (values: number[]): string[] =>
  values
    .map(type => ALL_CAMPAIGN_TYPES.find(t => t.value === type)?.code)
    .filter(v => v !== undefined) as string[];

const ALL_CAMPAIGN_TYPE_CODES = ALL_CAMPAIGN_TYPES.map(({ code }) => code);

export type CampaignTypeCode = (typeof ALL_CAMPAIGN_TYPE_CODES)[number];

export const CPC_CAMPAIGN_CODE: CampaignTypeCode = 'Manual';
