import cn from 'classnames';
import { createRef, FC, useCallback, useEffect, useState } from 'react';
import FilterLineDarkIcon from 'tcp-react-icons/lib/FilterLineDarkIcon';
import {
  FILTERS_ADD_FILTER,
  FILTERS_APPLY_FILTER_SET,
  FILTERS_DELETE_FILTER,
  FILTERS_MODAL_OPEN,
  FilterStorageKey,
} from '../../../constants';
import { useFilters } from '../../../global/context/filter-context';
import {
  getDimensionFiltersFromLocalStorage,
  saveDimensionFiltersToLocalStorage,
} from '../../../helpers/local-storage-helper';
import { translate } from '../../../helpers/utils';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { ColumnDef, FilterColumn } from '../../../models';
import {
  getColumnKeys,
  getFiltersMatchingColumns,
  getFiltersNotMatchingColumns,
} from '../../../services/analytics-service';
import TrackerService from '../../../services/tracker/tracker-service';
import { Popover, PopoverDirection } from '../../popover';
import { VerticalDivider } from '../../vertical-divider';
import { FilterSetOption } from './filter-set-option';
import './global-custom-filters.css';
import {
  ActionButtonsProps,
  FiltersDisplayed,
  FiltersSelectionProps,
} from './global-custom-filters.types';
import { createEmptyFilter } from './helpers/create-filters';
import {
  areAllFiltersFilled,
  getFilledFilters,
  getGroupedFilters,
  getValidFilters,
  hasInvalidFilters,
  isFilterValid,
} from './helpers/validate-filters';
import iconCreate from './icn-create.svg';
import { InputFilter } from './input-filter';
import { InputFilterFromOtherDimension } from './input-filter/input-filter-from-other-dimension';

export const GlobalCustomFilters: FC<{ filterStorageKey: FilterStorageKey }> = ({
  filterStorageKey,
}) => {
  const { columns, filterTypes, filters, selectedFilter, setSelectedFilter, setFilters } =
    useFilters();
  // Modals
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  // Filters data
  const [comparableColumns, setComparableColumns] = useState<ColumnDef[]>([]);
  useEffect(() => {
    setComparableColumns(columns.filter(c => c.isComparable));
  }, [columns]);
  const [filtersCreated, setFiltersCreated] = useState<FilterColumn[]>(
    getValidFilters(filters, comparableColumns),
  );
  const [filtersInOtherDimensions, setFiltersInOtherDimensions] = useState<FilterColumn[]>([]);

  // Filters displayed

  const filtersDisplayedMerged: FilterColumn[] = [...filtersCreated, ...filtersInOtherDimensions];

  const setFiltersDisplayed = useCallback(
    ({
      filtersCreated: _filtersCreated,
      filtersInOtherDimensions: _filtersInOtherDimensions,
    }: FiltersDisplayed) => {
      setFiltersCreated(getValidFilters(_filtersCreated, comparableColumns));
      setFiltersInOtherDimensions(_filtersInOtherDimensions);
    },
    [comparableColumns],
  );

  // Read/write stored filters

  const storeDisplayedToDefaultFilterSet = () =>
    saveDimensionFiltersToLocalStorage(getFilledFilters(filtersDisplayedMerged), filterStorageKey);

  const displayStoredFilters = useCallback(() => {
    const selectedFilters = getDimensionFiltersFromLocalStorage(filterStorageKey);
    const groupedFilters = getGroupedFilters(selectedFilters, comparableColumns);
    const columnKeys = getColumnKeys(comparableColumns);
    const filtersMatchingColumns = getFiltersMatchingColumns(groupedFilters, columnKeys);
    const filtersNotMatchingColumns = getFiltersNotMatchingColumns(groupedFilters, columnKeys);

    setFiltersDisplayed({
      filtersCreated: getValidFilters(filtersMatchingColumns, comparableColumns),
      filtersInOtherDimensions: filtersNotMatchingColumns,
    });
  }, [comparableColumns, setFiltersDisplayed, filterStorageKey]);

  // CRUD filters
  const createFilter = () => {
    if (hasInvalidFilters(filtersCreated, comparableColumns)) return;
    setFiltersCreated([...filtersCreated, createEmptyFilter()]);
    TrackerService.track(FILTERS_ADD_FILTER);
  };

  const updateFilter = (nextFilter: FilterColumn, index: number) => {
    const nextFiltersCreated = filtersCreated.map((filter, i) =>
      i === index ? nextFilter : filter,
    );
    setFiltersCreated(nextFiltersCreated);
  };

  const removeFilterCreated = (index: number) => {
    const nextFiltersCreated = filtersCreated.filter((_filter, i) => i !== index);
    setFiltersCreated(getValidFilters(nextFiltersCreated, comparableColumns));
    TrackerService.track(FILTERS_DELETE_FILTER);
  };

  const removeFilterInOtherDimensions = (index: number) => {
    const nextFiltersInOtherDimensions = filtersInOtherDimensions.filter(
      (_filter, i) => i !== index,
    );
    setFiltersInOtherDimensions(nextFiltersInOtherDimensions);
  };

  // Apply filters and close modal

  const applyFilters = () => {
    storeDisplayedToDefaultFilterSet();
    closeCustomFilters();
    setFilters(getFilledFilters(filtersCreated));
    TrackerService.track(FILTERS_APPLY_FILTER_SET, { filters: filtersCreated });
  };

  const toggleFiltersPopover = () => {
    TrackerService.track(FILTERS_MODAL_OPEN);
    if (isFiltersModalOpen) closeCustomFilters();
    if (!isFiltersModalOpen) setIsFiltersModalOpen(true);
  };

  const closeCustomFilters = () => {
    displayStoredFilters();
    setIsFiltersModalOpen(false);
    setSelectedFilter(undefined);
  };

  // Click outside modal

  const containerRef = createRef<HTMLDivElement>();
  useOnClickOutside(containerRef, () => {
    closeCustomFilters();
  });

  const isApplyDisabled = !areAllFiltersFilled(filtersCreated);

  // Clicking on any active filter tag opens the filters modal
  useEffect(() => {
    if (selectedFilter !== undefined) {
      setIsFiltersModalOpen(true);
    }
  }, [selectedFilter]);

  // Update filters if they change in the parent
  useEffect(
    () => setFiltersCreated(getValidFilters(filters, comparableColumns)),
    [comparableColumns, filters],
  );

  useEffect(() => {
    displayStoredFilters();
  }, [displayStoredFilters]);

  return (
    <div ref={containerRef}>
      <button
        onClick={toggleFiltersPopover}
        className={cn(
          'e-button e-button--tertiary e-button--small u-display--flex u-align-items--center e-button--oval',
        )}
        data-qa="filters-button"
      >
        <FilterLineDarkIcon className="u-margin-right--small" />
        {translate('analytics_home_filters')}
      </button>
      {isFiltersModalOpen && (
        <div className="u-position--relative c-custom-filters__popover">
          <Popover direction={PopoverDirection.BOTTOM_LEADING}>
            <div className="u-display--flex u-flex-direction--row">
              <div className="c-custom-filters__popover-filter-set-selection u-display--flex u-flex-direction--column u-padding-left--gutter u-margin-top--gutter u-margin-bottom--gutter">
                <FilterSetOption />
              </div>
              <VerticalDivider height="stretch" />
              <div className="u-display--flex u-flex-direction--column u-margin--gutter">
                <FilterSelection
                  {...{
                    filtersCreated,
                    removeFilterCreated,
                    updateFilter,
                    columns: comparableColumns,
                    filterTypes,
                    selectedFilter,
                    filtersInOtherDimensions,
                    removeFilterInOtherDimensions,
                  }}
                />
                <ActionButtons
                  {...{
                    createFilter,
                    isApplyDisabled,
                    applyFilters,
                  }}
                />
              </div>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

const FilterSelection: FC<FiltersSelectionProps> = ({
  filtersCreated,
  removeFilterCreated,
  updateFilter,
  columns,
  filterTypes,
  selectedFilter,
  filtersInOtherDimensions,
  removeFilterInOtherDimensions,
}) => {
  const isOneFilter = filtersCreated.length === 1;

  return (
    <div className="c-custom-filters__popover-filter-selection u-margin-bottom--tiny">
      {filtersCreated.map((filterTmp, index) => (
        <InputFilter
          key={index}
          filter={filterTmp}
          onRemoveFilter={() => removeFilterCreated(index)}
          onUpdateFilter={updateFilter}
          columns={columns}
          filterTypes={filterTypes}
          isSelected={filterTmp === selectedFilter}
          isValid={isFilterValid(filterTmp, columns)}
          isOneFilter={isOneFilter}
          index={index}
        />
      ))}
      {filtersInOtherDimensions.map((filterTmp, index) => (
        <InputFilterFromOtherDimension
          key={index}
          filter={filterTmp}
          onRemoveFilter={() => removeFilterInOtherDimensions(index)}
          filterTypes={filterTypes}
          index={index}
        />
      ))}
    </div>
  );
};

const ActionButtons: FC<ActionButtonsProps> = ({ createFilter, applyFilters, isApplyDisabled }) => {
  return (
    <>
      <div className="u-display--flex u-justify-content--space-between u-margin-bottom--large">
        <img
          src={iconCreate}
          className="u-color--blue u-cursor--pointer"
          onClick={createFilter}
          data-qa="filters-create"
        />
      </div>
      <div className="u-display--flex u-align-items--center">
        <div className="u-margin-left--auto u-display--flex">
          <button
            className="e-button e-button--small e-button--primary"
            type="button"
            onClick={applyFilters}
            data-qa="filters-apply"
            disabled={isApplyDisabled}
          >
            {translate('common_apply')}
          </button>
        </div>
      </div>
    </>
  );
};
