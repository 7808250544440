import { useContext } from 'react';
import styles from './header-page.module.css';
import { Notifications } from '../notifications';
import { SelectorBar } from '../selector-bar';
import { Header } from './header/header';
import { SnackbarNotifications } from '../snackbar-notifications';
import { AppContext } from '../../context/app-context';
import { findItemsMenuOptions } from '../../../services/app-service';
import { FilterBar } from '../filter-bar';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

export const HeaderPage = () => {
  const location = useLocation();
  const { partner, regions } = useContext(AppContext);

  const { selectorBar } = findItemsMenuOptions(location.pathname);
  const showFilterBar =
    location.pathname.startsWith('/dashboard') ||
    (location.pathname.startsWith('/analytics') &&
      !location.pathname.startsWith('/analytics/property')) ||
    (location.pathname.startsWith('/sponsored-analytics') &&
      !location.pathname.startsWith('/sponsored-analytics/property'));

  return (
    <div className={cn(styles.headerPage, 'sticky-header')}>
      <Header selectedPartnerId={partner.partnerId} />
      <SelectorBar
        regions={regions}
        isBrandMultiSelectionAvailable={selectorBar.allowMultipleBrandSelection}
        isPosSelectionAvailable={selectorBar.allowPosSelection}
        isDateSelectionAvailable={selectorBar.allowDateSelection}
      />
      {showFilterBar && <FilterBar />}
      <Notifications />
      <SnackbarNotifications />
    </div>
  );
};
