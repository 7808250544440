import { FC, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { HotelControlPanel } from '../../../../../components/hotel-control-panel';
import { Loading } from '../../../../../components/loading';
import {
  SL_BID_CAP,
  SL_DETAILED_HOTEL_VIEW_PREFIX,
  SPONSORED_ANALYTICS_ROUTE,
  SPONSORED_HOTEL_DETAILS_BID_OPTION_DROPDOWN,
  TIMEOUT_NOTIFICATION,
} from '../../../../../constants';
import { BidFormContext } from '../../../../../features/bid-form/bid-form-context';
import { BidFormUnit, EURO } from '../../../../../features/bid-form/bid-form.types';
import { translate } from '../../../../../helpers/utils';
import { BaseNotification, BiddingActionType } from '../../../../../models';
import TrackerService from '../../../../../services/tracker/tracker-service';
import { renderAlertNotification } from '../../sponsored-hotel-view/sponsored-hotel-view-notification';
import { SponsoredHotelDetailInformation } from '../sponsored-hotel-detail-information';
import './sponsored-general-information-panel.css';
import { Props } from './sponsored-general-information-panel.types';

export const SponsoredGeneralInformationPanel: FC<Props> = ({ hotel, isLoading, onUpdate }) => {
  const [notification, setNotification] = useState<BaseNotification | undefined>(undefined);
  const [isRedirectActive, setRedirectActive] = useState<boolean>(false);

  const onShowNotification = (notificationTmp: BaseNotification) => {
    setNotification(notificationTmp);
    setTimeout(onCloseNotification, TIMEOUT_NOTIFICATION);
  };
  const onCloseNotification = () => setNotification(undefined);
  const activateRedirect = () => setRedirectActive(true);

  const [bidFormValue, setBidFormValue] = useState('');
  const [bidFormUnit, setBidFormUnit] = useState<BidFormUnit>(EURO);

  const handleBidFormUnitChange = (unit: BidFormUnit) => {
    setBidFormUnit(unit);
    TrackerService.track(SPONSORED_HOTEL_DETAILS_BID_OPTION_DROPDOWN, {
      option: translate(
        unit === EURO ? 'bid_form_cpc_set_to' : 'bid_form_cpc_change_by_percentage',
      ),
    });
  };

  return (
    <>
      {isRedirectActive && <Navigate to={SPONSORED_ANALYTICS_ROUTE} />}
      {isLoading ? (
        <div className="u-background--blue-very-light">
          <Loading isWhite={true} className="u-padding--gutter" />
        </div>
      ) : (
        <BidFormContext.Provider
          value={{
            value: bidFormValue,
            setValue: setBidFormValue,
            unit: bidFormUnit,
            setUnit: handleBidFormUnitChange,
            bidItems: [
              {
                baseValue: hotel?.slBidCpc as number,
                biddingType: 0,
                capValue: SL_BID_CAP,
                minValue: 0,
                name: hotel?.name as string,
                id: hotel?.partnerRef as string,
              },
            ],
          }}
        >
          <section>
            <HotelControlPanel
              hotels={hotel !== undefined ? [hotel] : []}
              onUpdateTable={onUpdate}
              onShowNotification={onShowNotification}
              onClickLeading={activateRedirect}
              isShowingDetail={true}
              trackingPrefix={SL_DETAILED_HOTEL_VIEW_PREFIX}
              biddingActionType={BiddingActionType.SlBid}
              maxCPCBidCap={SL_BID_CAP}
            />
            {hotel !== undefined && <SponsoredHotelDetailInformation hotel={hotel} />}
            {renderAlertNotification(notification, onCloseNotification)}
          </section>
        </BidFormContext.Provider>
      )}
    </>
  );
};
