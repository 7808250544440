import { useQueryParam } from '../use-query-param';
import {
  ANALYTICS_ROUTE,
  DASHBOARD_ROUTE,
  HOTEL_DETAILS_ROUTE,
  QUERY_PARAM_ACTIVE_TAB,
  SPONSORED_ANALYTICS_ROUTE,
  SPONSORED_HOTEL_DETAILS_ROUTE,
} from '../../constants';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { View } from '../../models';
import { DEFAULT_SPONSORED_ANALYTICS_TAB_KEY } from '../../views/sponsored/sponsored-analytics/sponsored-analytics';
import { DEFAULT_ANALYTICS_TAB_KEY } from '../../views/analytics/analytics';

type ViewId = keyof typeof View;

export const useCurrentView = (): View | 'dashboard' | null => {
  const { value: activeTab } = useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  const { pathname } = useLocation();
  const defaultRouteTabs: Record<string, string> = {
    [ANALYTICS_ROUTE]: DEFAULT_ANALYTICS_TAB_KEY,
    [HOTEL_DETAILS_ROUTE]: String(View.HOTEL_DETAIL),
    [SPONSORED_ANALYTICS_ROUTE]: DEFAULT_SPONSORED_ANALYTICS_TAB_KEY,
    [SPONSORED_HOTEL_DETAILS_ROUTE]: String(View.SPONSORED_HOTEL_DETAIL),
    [DASHBOARD_ROUTE]: '/dashboard',
  };
  if (activeTab) return View[activeTab as ViewId];
  const currentRoute = Object.keys(defaultRouteTabs).find(route =>
    matchPath({ path: route }, pathname),
  );

  const isDashboard = currentRoute === DASHBOARD_ROUTE;

  if (isDashboard) return 'dashboard';
  if (currentRoute)
    return View[defaultRouteTabs[currentRoute as keyof typeof defaultRouteTabs] as ViewId];
  return null;
};
