import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../report-tab.css';
import { Card } from '../../../components/card';
import { DatePickerAlignment, DatePickerDropdown } from '../../../components/date-picker-dropdown';
import { REPORT_TO_KEY } from '../../../constants';
import { TranslationKey, getLastXDays, translate } from '../../../helpers/utils';
import { DateRange } from '../../../models';
import styles from './report-history.module.css';
import { ReportArtifacts } from './report-artifacts';
import { Button } from '../../../core-ui/components/button/button';

export const ReportHistory = ({}) => {
  const { reportName } = useParams<{ reportName: string }>();
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<DateRange>({
    from: getLastXDays(30),
    to: getLastXDays(0),
  });

  if (!reportName) return null;

  return (
    <Card className={styles.reportHistoryPage}>
      <Button text={translate('reports_back')} variant="tertiary" onClick={() => navigate(-1)} />
      <div className={styles.header}>
        <h2>{translate((REPORT_TO_KEY[reportName] || reportName) as TranslationKey)}</h2>
        <DatePickerDropdown
          dateRange={dateRange}
          alignment={DatePickerAlignment.LEFT}
          onUpdateDateRange={setDateRange}
        />
      </div>
      <ReportArtifacts reportName={reportName} dateRange={dateRange} />
    </Card>
  );
};
