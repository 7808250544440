import cs from 'classnames';
import MinusThinDarkIcon from 'tcp-react-icons/lib/MinusThinDarkIcon';
import PlusThinDarkIcon from 'tcp-react-icons/lib/PlusThinDarkIcon';
import { Props } from './hotel-detail-information.types';
import './hotel-detail-information.css';
import { HotelDetailInformationColumn } from '../hotel-detail-information-column';
import { translate } from '../../../../helpers/utils';
import { number2percentage } from '../../../../helpers/formater';
import { FC, useState } from 'react';
import { useBidForm } from '../../../../features/bid-form/use-bid-form';

export const HotelDetailInformation: FC<Props> = ({ hotel }) => {
  const [isShowingDetail, setIsShowingDetail] = useState(true);
  const { adjustedBidItems } = useBidForm(false);

  const getStarsKeyTranslations = () =>
    hotel.stars > 1
      ? translate('analytics_hotel_detail_stars')
      : translate('analytics_hotel_detail_star');

  const bidCPCValue = () => {
    if (!hotel.bidCPC) return translate('common_not_available');

    return (
      <>
        {hotel.bidCPC} € to <b>{adjustedBidItems[0].acceptedValue} €</b>
      </>
    );
  };

  return (
    <div
      className={cs(
        'u-display--flex u-flex-direction--column u-padding--gutter c-hotel-detail-information',
        {
          'u-border-bottom--juri-very-light': isShowingDetail,
        },
      )}
    >
      <div className="u-display--flex u-font-weight-bold u-align-items--center">
        {isShowingDetail ? (
          <MinusThinDarkIcon
            className="u-cursor--pointer"
            onClick={() => {
              setIsShowingDetail(false);
            }}
          />
        ) : (
          <PlusThinDarkIcon
            className="u-cursor--pointer"
            onClick={() => {
              setIsShowingDetail(true);
            }}
          />
        )}
        <span className="u-margin-left--gutter--small">
          {translate('analytics_hotel_detail_details')}
        </span>
      </div>
      {isShowingDetail && (
        <div className="u-display--flex u-margin-top--gutter u-margin-left--huge">
          <HotelDetailInformationColumn
            fields={[
              {
                key: translate('analytics_hotel_detail_country'),
                value: hotel.country,
              },
              {
                key: translate('analytics_hotel_detail_region'),
                value: hotel.region,
              },
              {
                key: translate('analytics_hotel_detail_city'),
                value: hotel.city,
              },
              {
                key: translate('analytics_hotel_detail_stars_field'),
                value: `${hotel.stars} ${getStarsKeyTranslations()}`,
              },
            ]}
            extraClassesValues="c-hotel-detail-information__column"
          />
          <HotelDetailInformationColumn
            fields={[
              {
                key: translate('analytics_hotel_detail_rating'),
                value: hotel.rating,
              },
              {
                key: translate('analytics_hotel_detail_trivago_link'),
                value: (
                  <a href={hotel.trvLink} rel="noopener noreferrer" target="_blank">
                    {hotel.trvLink}
                  </a>
                ),
              },
              {
                key: translate('analytics_hotel_detail_partner_ref'),
                value: hotel.partnerRef,
              },
              {
                key: translate('analytics_hotel_detail_trivago_id'),
                value: hotel.trvId,
              },
            ]}
          />
          <HotelDetailInformationColumn
            fields={[
              {
                key: translate('analytics_hotel_detail_bid_cpc'),
                value: bidCPCValue(),
              },
              {
                key: translate('analytics_hotel_detail_bidding_type'),
                value: hotel.biddingType === '' ? '-' : hotel.biddingType,
              },
              {
                key: translate('analytics_hotel_detail_cpa_value'),
                value: number2percentage(Number(hotel.cpaValue)),
              },
            ]}
            extraClassesKeys="u-margin-left--auto"
          />
        </div>
      )}
    </div>
  );
};
