import { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import 'url-search-params-polyfill';
import {
  DASHBOARD_ROUTE,
  EMAIL_KEY,
  HOME_ROUTE,
  LOGIN_ROUTE,
  NotificationLevel,
  UNAUTHORIZED_ACCOUNT_ROUTE,
} from '../../constants';
import ApiAuthHelper from '../../helpers/api/api-auth-helper';
import {
  getProfileFromLocalStorage,
  saveItemToLocalStorage,
  saveProfileToLocalStorage,
} from '../../helpers/local-storage-helper';
import { translate } from '../../helpers/utils';
import { showNotification } from '../../services/notification-service';
import { isTokenExpired } from '../../services/profile-service';
import { LOGGED_IN_NAME } from '../../services/tracker/customer-actions';
import TrackerService from '../../services/tracker/tracker-service';
import { Loading } from '../loading';
import './authentication.css';
import uuid from 'uuid';

export const Authentication: FC = () => {
  const [hasCode, setHasCode] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const location = useLocation();
  const navigation = useNavigate();

  const login = useCallback(async (code: string) => {
    try {
      const profile = await ApiAuthHelper.login(code);
      const isValid = profile.user.partners.length > 0;
      const sessionId = uuid();

      profile.user.sessionId = sessionId;

      if (!isValid) {
        setIsUnauthorized(true);
        return;
      }

      saveProfileToLocalStorage(profile);

      setIsLogged(true);
      // Delay login event dispatch until base attributes are set
      setTimeout(() => TrackerService.track(LOGGED_IN_NAME, {}), 0);
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        throw e;
      }
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
      if (e?.response?.data.user) {
        const email = e.response.data.user.email;
        saveItemToLocalStorage(EMAIL_KEY, email);
      }
      setIsUnauthorized(true);
    }
  }, []);

  useEffect(() => {
    /**
     * Create function here to avoid wrapping it in useCallback
     */
    const isStillValidLocalSession = () => {
      const profile = getProfileFromLocalStorage();
      if (!profile) {
        return false;
      }

      const hasExpired = isTokenExpired(profile);
      return !hasExpired;
    };

    const search = location ? location.search : '';
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');

    if (!code) {
      if (isStillValidLocalSession()) {
        navigation(HOME_ROUTE);
      } else {
        setHasCode(false);
      }
      return;
    }
    login(code);
  }, [navigation, location, login]);

  if (isUnauthorized) {
    return <Navigate to={UNAUTHORIZED_ACCOUNT_ROUTE} />;
  }

  if (!hasCode) {
    return <Navigate to={LOGIN_ROUTE} />;
  }

  if (isLogged) {
    const lastLocation = JSON.parse(localStorage.getItem('last_location') || 'null');
    const to = lastLocation || DASHBOARD_ROUTE;
    return <Navigate to={to} />;
  }

  return (
    <div className="u-display--flex u-justify-content--center u-align-items--center u-height--100vh">
      <Loading message="Authenticating the user..." />
    </div>
  );
};
