import { PropertyPartnerName } from '../../helpers/api/analytics/types';
import { getAllBiddingTypesWithSL } from '../../helpers/transformers';
import { translate } from '../../helpers/utils';
import { DimensionValueLabels, Locale, Partner, SelectedDimensionApi } from '../../models';
import { breakoutTranslationKey } from '../../views/custom-dashboard/bid-modifier-dimension-menu/bm-dimension-breakouts';
import { parseDestination } from '../../views/custom-dashboard/destination-dimension-menu';
import {
  BidModifierDimensionName,
  SelectedDimension,
  isBidModifierDimension,
  BIDDING_TYPE_DIMENSION,
} from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import { usePropertyGroups } from '../use-property-groups';

export function useExtendDimensionInfo() {
  const propertyGroups = usePropertyGroups();

  const extendDimensionInfo = (
    dimensionInfo: SelectedDimensionApi | undefined,
    locales: readonly Locale[],
    subPartners: readonly Partner[],
    properties: readonly PropertyPartnerName[] = [],
  ): SelectedDimension | undefined => {
    if (!dimensionInfo) return;
    return {
      name: dimensionInfo.name,
      values: labeledDimensionValues(dimensionInfo, locales, subPartners, properties),
    };
  };

  const labeledDimensionValues = (
    dimensionInfo: SelectedDimensionApi,
    locales: readonly Locale[],
    subPartners: readonly Partner[],
    properties: readonly PropertyPartnerName[],
  ): DimensionValueLabels[] => {
    const { name: dimensionName, values } = dimensionInfo;

    switch (dimensionName) {
      case 'posCountry':
        return values
          .map(value => locales.find(({ localeCode }) => localeCode === value)!!)
          .filter(locale => locale)
          .map(locale => ({ label: locale.name, value: locale.localeCode }));
      case BIDDING_TYPE_DIMENSION: {
        return getAllBiddingTypesWithSL()
          .filter(campaignType => values.includes(campaignType.value.toString()))
          .map(campaignType => ({
            label: campaignType.code,
            value: String(campaignType.value),
          }));
      }
      case 'brand':
        return [...subPartners]
          .filter(({ partnerId }) => values.includes(String(partnerId)))
          .map(({ name, partnerId }) => ({ label: name, value: String(partnerId) }));
      case 'propertyName':
        return properties.map(property => ({
          label: property.name,
          value: String(property.itemId),
        }));
      case 'propertyGroups':
        return propertyGroups
          .map(property => ({
            label: property.alias ? `${property.name} (${property.alias})` : property.name,
            value: String(property.id),
          }))
          .filter(property => dimensionInfo.values.includes(property.value));
      case 'region':
        return values.map(value => ({
          label: parseDestination(value).region!,
          value,
        }));
      case 'city':
        return values.map(value => ({
          label: parseDestination(value).city!,
          value,
        }));
      default:
        if (isBidModifierDimension(dimensionName)) {
          return values.map(value => ({
            label: translate(
              breakoutTranslationKey(dimensionName as BidModifierDimensionName, Number(value)),
            ),
            value,
          }));
        }
        return values.map(value => ({
          label: String(value),
          value,
        }));
    }
  };

  return extendDimensionInfo;
}
