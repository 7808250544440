import { FC } from 'react';
import { ButtonRetry } from '../../../components/button-retry';
import { Loading } from '../../../components/loading';
import { useFilters } from '../../../global/context/filter-context';
import { translate } from '../../../helpers/utils';
import { Widget } from '../widget';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { WidgetRow } from '../widget/widget-row';
import { WidgetTable } from '../widget/widget-table';
import styles from './global-inventory-widget.module.css';
import { Props } from './global-inventory-widget.types';
import { useGlobalInventory } from './use-global-inventory';
import { ButtonIcon } from '../../../core-ui/components/button-icon/button-icon';
import { Tooltip } from '../../../core-ui/components/tooltip/tooltip';

export const GlobalInventoryWidget: FC<Props> = ({ onRemoveWidget }) => {
  const { data, isLoading, error, refetch, isDownloadingReport } = useGlobalInventory();

  function widgetBody() {
    if (error) {
      return <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />;
    }
    if (isDownloadingReport) {
      return (
        <div>
          <Loading message={translate('dashboard_global_inventory_download_in_progress')} />
        </div>
      );
    }

    return (
      <WidgetTable>
        {data.metrics.map(metric => (
          <WidgetRow
            key={metric.label}
            field={{ text: metric.label, tooltip: metric.tooltip || '' }}
          >
            <td>{metric.count}</td>
            {metric.downloadReport && metric.count > 0 && (
              <td>
                <Tooltip
                  title={translate('dashboard_global_inventory_download_disclaimer')}
                  position="bottomEnd"
                  className={styles.tooltip}
                >
                  <ButtonIcon
                    onClick={() => metric.downloadReport!()}
                    icon={{ name: 'download' }}
                    className={styles.downloadIcon}
                  />
                </Tooltip>
              </td>
            )}
          </WidgetRow>
        ))}
      </WidgetTable>
    );
  }

  const { includeSlData } = useFilters();
  const partialFiltersAppliedMessage = includeSlData
    ? 'SL filter is not applicable to this widget'
    : undefined;

  return (
    <Widget
      menuItems={[<WidgetRemoveMenuItem key="1" />]}
      onSelectMenuItem={onRemoveWidget}
      isLoading={isLoading}
      title={
        <span className={styles.widgetTitle}>
          {translate('dashboard_global_inventory_widget_title')}
        </span>
      }
      partialFiltersAppliedMessage={partialFiltersAppliedMessage}
    >
      {widgetBody()}
    </Widget>
  );
};
