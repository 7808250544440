import cn from 'classnames';
import { FC, useRef } from 'react';
import {
  CheckboxColumn,
  Column,
  ColumnBase,
  ColumnOnboardingTooltip,
  ColumnType,
  SortDirection,
} from '..';
import { NO_OF_NARROW_TABLE_COLUMNS } from '../../../constants';
import { TranslationKey, translate } from '../../../helpers/utils';
import { OnboardingTooltipContainer } from '../../onboarding-tooltip/onboarding-tooltip-container';
import { PopoverDirection } from '../../popover';
import { useStickyColumn } from '../hooks/use-sticky-column';
import { isColumnAlignedToTheLeft } from '../utils/grid-utils';
import styles from './grid-header.module.css';
import { GridHeaderProps } from './grid-header.types';
import { GridSortButton } from './grid-sort-button';
import { Tooltip } from '../../../core-ui/components/tooltip/tooltip';
import { Sort } from '../models/grid-model';
import { IS_DATE_COMPARISON_ENABLED, useSortParams } from '../../../hooks/use-sort-params';
import { ONBOARDING_TOURS } from '../../../constants/onboarding-targets';

export const GridHeader: FC<GridHeaderProps> = ({
  columns,
  numStickyColumns,
  numColumnsLeftAlignment,
  isPageSelected,
  currentSort,
  isShowingTooltips,
  isHorizontalScrollDisabled,
  onClickCell,
  columnOnboardingTooltips = [],
}) => {
  const { refRow, getCustomColumnStyle, isColumnSticky } = useStickyColumn(
    columns,
    numStickyColumns,
    undefined,
    isHorizontalScrollDisabled,
  );

  const { columnKey, direction, isDateComparisonActive } = useSortParams([
    'sortBy',
    'orderBy',
    IS_DATE_COMPARISON_ENABLED,
  ]);

  const colRef = useRef<HTMLTableCellElement>(null);

  if (colRef.current && isDateComparisonActive) {
    colRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }

  const getColumnStyle = (index: number) => {
    return isColumnSticky(index)
      ? getCustomColumnStyle(
          index,
          index > 0 && columns[index - 1].type === ColumnType.CHECKBOX ? 2 : 1,
          columns.length,
        )
      : undefined;
  };

  const isColumnSortable = (column: Column) => (column as ColumnBase).isSortable && onClickCell;

  const isColumnSelectedForSort = (column: Column) =>
    (isColumnSortable(column) && currentSort && currentSort.columnKey === column.key) ||
    getSortColumnFromParams(column)?.columnKey === column.key;

  const getSortColumnFromParams = (column: Column): Sort | undefined => {
    if (columnKey !== column.key) return undefined;

    return { columnKey, direction: direction.toUpperCase() as SortDirection };
  };

  const getOnboardingTooltipProps = (column: Column): ColumnOnboardingTooltip | undefined =>
    columnOnboardingTooltips.find(onboardingTooltip => onboardingTooltip.column === column);

  const tooltipMessage = (column: Column): string | undefined => {
    const tooltipKey = (column as ColumnBase).tooltip;
    if (!tooltipKey) return;
    return translate(tooltipKey as TranslationKey);
  };

  const renderColumnName = (column: Column) => {
    const isFirstColumn =
      columns
        .filter(columnTmp => columnTmp.type !== ColumnType.CHECKBOX)
        .findIndex(columnTmp => columnTmp.key === column.key) === 0;
    const isLastColumn =
      columns.findIndex(columnTmp => columnTmp.key === column.key) === columns.length - 1;

    const onboardingTooltipProps = getOnboardingTooltipProps(column);
    const onboardingTooltipText = onboardingTooltipProps?.text;
    const onboardingTooltipPosition =
      onboardingTooltipProps?.position ?? PopoverDirection.BOTTOM_TRAILING;

    const toolTipPosition = isFirstColumn
      ? 'bottomStart'
      : isLastColumn
        ? 'bottomEnd'
        : 'bottomCenter';

    return (
      <>
        <Tooltip
          position={toolTipPosition}
          title={tooltipMessage(column) || ''}
          isHidden={!isShowingTooltips || !tooltipMessage(column)}
          className={styles.tooltip}
        >
          <span
            className={cn(styles.columnTitle, { [styles.sortable]: isColumnSortable(column) })}
            onClick={() => {
              onClickCell?.(column);
            }}
          >
            {(column as ColumnBase).name}
          </span>
        </Tooltip>

        <OnboardingTooltipContainer
          onboardingKey={`${column.key}-onboarding`}
          onboardingText={onboardingTooltipText}
          location="gridHeader"
          direction={onboardingTooltipPosition}
        />
      </>
    );
  };

  const isAlignmentToTheLeft = (index: number) =>
    isColumnAlignedToTheLeft(columns, numStickyColumns, numColumnsLeftAlignment, index);

  const isColumnCheckbox = (column: Column): column is CheckboxColumn =>
    column.type === ColumnType.CHECKBOX;

  const buildDataQa = (column: Column) =>
    isColumnCheckbox(column) ? 'grid-header-checkbox' : `grid-header-${column.key}`;
  const isHeaderNarrow = (index: number) =>
    columns.length < NO_OF_NARROW_TABLE_COLUMNS && !isColumnSticky(index);

  const renderColumnHeaderCell = (column: Column, index: number) => {
    return isColumnCheckbox(column) ? (
      <input
        type="checkbox"
        disabled={!onClickCell}
        onChange={() => onClickCell?.(column)}
        checked={isPageSelected}
      />
    ) : (
      <span
        className={cn(styles.columnTitleWrapper, {
          [styles.sticky]: isColumnSticky(index),
          [styles.leftAlign]: isAlignmentToTheLeft(index),
          [styles.showSortButton]: getSortColumnFromParams(column),
        })}
      >
        {isAlignmentToTheLeft(index) && renderColumnName(column)}
        {isColumnSortable(column) && (
          <GridSortButton
            onSort={() => onClickCell?.(column)}
            sort={getSortColumnFromParams(column) ? getSortColumnFromParams(column) : currentSort}
            column={column}
            extraClasses={cn(styles.sortButton, {
              [styles.leftAlign]: isAlignmentToTheLeft(index),
            })}
          />
        )}
        {!isAlignmentToTheLeft(index) && renderColumnName(column)}
      </span>
    );
  };

  return (
    <thead className={styles.gridHeader}>
      <tr ref={refRow}>
        {columns.map((columnTmp, index) => {
          const onboardingTooltipProps = getOnboardingTooltipProps(columnTmp);
          return (
            <th
              data-qa={buildDataQa(columnTmp)}
              className={cn(styles.column, {
                [styles.sticky]: isColumnSticky(index),
                [styles.checkbox]: isColumnCheckbox(columnTmp),
                [styles.sorted]: isColumnSelectedForSort(columnTmp),
                [styles.sortable]: isColumnSortable(columnTmp),
                [styles.narrow]: isHeaderNarrow(index),
                [styles.hasTooltip]: !!onboardingTooltipProps,
              })}
              style={{ ...getColumnStyle(index) }}
              key={columnTmp.key}
              ref={columnTmp.key === columnKey ? colRef : null}
              data-tour={
                columnTmp.key === 'cost' ? ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.STEP_3 : null
              }
            >
              {renderColumnHeaderCell(columnTmp, index)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
