import { FC, useState, useCallback, useContext } from 'react';
import { TimeLineWidgetProps } from './time-line-widget.types';
import { Widget } from '../widget';
import { isTextOverflowing, translate } from '../../../helpers/utils';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { WidgetNoData } from '../widget/widget-no-data';
import { ButtonRetry } from '../../../components/button-retry';
import { useTimelineData } from '../../../hooks/use-timeline-data';
import { TimeLineChart } from './time-line-chart';
import { WidgetMenuItem } from '../widget/widget-menu';
import { ExtendedTimelineWidgetProps, TimelineWidgetProps, WidgetType } from '../../../models';
import { AppContext } from '../../../global/context/app-context';
import { MetricsPicker } from './metrics-picker';
import cloneDeep from 'lodash.clonedeep';
import cn from 'classnames';
import TrackerService from '../../../services/tracker/tracker-service';
import {
  CHART_BUILDER_DELETE,
  CHART_BUILDER_EDIT_START,
  NotificationLevel,
} from '../../../constants';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { WidgetTitleEdit } from './widget-title-edit';
import { usePropertiesData } from '../hooks/use-properties-data';
import { exportToCsv } from '../chart-builder/export-to-csv';
import { useExtendDimensionInfo } from '../../../hooks/use-extend-dimension-info';
import { showNotification } from '../../../services/notification-service';
import styles from './time-line-widget.module.css';

export const TimeLineWidget: FC<TimeLineWidgetProps> = ({
  timeOption,
  onRemoveWidget,
  onEditWidget,
  onFullScreen,
  isFullScreen,
  onExploreChart,
  widgetInfo: { uuid: widgetUuid, type: widgetType, props: widgetProps },
  onTitleEdit,
}) => {
  const dimension = widgetProps?.dimension;
  const { locales, partner, dateRangePeriod } = useContext(AppContext);
  const { from, to, period } = dateRangePeriod;

  const [isRenaming, setIsRenaming] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const itemIds =
    widgetProps.dimension?.name === 'propertyName' ? widgetProps.dimension?.values : [];
  const {
    data: propertiesData,
    isError: isPropertiesError,
    isLoading: isFetchingProperties,
  } = usePropertiesData(itemIds);
  const extendDimensionInfo = useExtendDimensionInfo();
  const extendTimelineWidgetProps = (
    timelineProps: TimelineWidgetProps,
  ): ExtendedTimelineWidgetProps => {
    const subBrands = partner?.subpartners?.length ? partner.subpartners : [partner];
    return {
      name: timelineProps.name,
      metrics: timelineProps.metrics,
      dimension: extendDimensionInfo(timelineProps.dimension, locales, subBrands, propertiesData),
      aggregationPeriod: timeOption,
      uuid: widgetUuid,
    };
  };

  const { columns, data, isFetchingColumns, isFetchingData, isError, refetch } = useTimelineData(
    timeOption,
    widgetProps.metrics.map(metric => metric.id),
    dimension,
    widgetType,
    [partner, widgetProps.dimension, widgetProps.metrics],
  );

  const onSelectMetric = (index: number, metricId: string) => {
    const dataTmp = cloneDeep(widgetProps);

    dataTmp.metrics[index].id = metricId;

    onEditWidget(dataTmp);
  };
  const shouldUseFullScreen = localStorage.getItem('useDashboardFullScreen') === 'true';

  const metricNames = widgetProps.metrics.map(
    metric => columns.find(column => column.key === metric.id)?.name ?? metric.id,
  );

  const widgetTitle: string = widgetProps.name || metricNames.join(' & ');

  const removeWidgetMenuItem = () => (
    <WidgetRemoveMenuItem
      onClick={() => {
        onRemoveWidget();
        TrackerService.track(CHART_BUILDER_DELETE, {
          deletedFrom: 'Dashboard',
          chartType: WidgetType.TIMELINE,
          dimension: widgetProps.dimension,
          metrics: widgetProps.metrics,
          aggregation: timeOption,
        });
      }}
    />
  );

  const renameWidgetMenuItem = () => (
    <WidgetMenuItem onClick={() => setIsRenaming(true)} title="Rename" icon="PencilIcon" />
  );

  const fullScreenMenuItem = () => (
    <WidgetMenuItem
      onClick={() => onFullScreen()}
      title={isFullScreen ? 'Close full screen' : 'Open in full screen'}
      icon="FullScreen"
    />
  );

  const exploreChartMenuItem = () => (
    <WidgetMenuItem
      onClick={() => {
        TrackerService.track(CHART_BUILDER_EDIT_START, {
          chartType: WidgetType.TIMELINE,
          dimension: widgetProps.dimension,
          metrics: widgetProps.metrics,
          aggregation: timeOption,
        });
        onExploreChart?.();
      }}
      title="Explore chart"
      icon="ExploreChartIcon"
    />
  );

  function exportChart() {
    exportToCsv({
      data,
      metrics: columns.filter(c => metricNames.includes(c.name)),
      fileName: widgetTitle,
      dimension: dimension ? extendTimelineWidgetProps(widgetProps).dimension! : undefined,
    }).catch(error => {
      console.error(error);
      showNotification({
        level: NotificationLevel.ERROR,
        message: 'Failed to export chart to CSV file',
      });
    });
  }

  const exportChartMenuItem = () => (
    <WidgetMenuItem onClick={exportChart} title="Export chart (.csv)" icon="ExportResource" />
  );

  function menuItems() {
    const items: JSX.Element[] = [];

    if (widgetType === WidgetType.CHART_BUILDER) {
      items.push(exploreChartMenuItem(), renameWidgetMenuItem(), exportChartMenuItem());
    }

    if (shouldUseFullScreen) items.push(fullScreenMenuItem());

    items.push(removeWidgetMenuItem());

    return items;
  }

  const getValidDropdownColumns = (index: number) =>
    columns.filter(columnTmp => columnTmp.key !== widgetProps.metrics[index === 0 ? 1 : 0].id);

  const widgetTitleRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setShowTooltip(isTextOverflowing(node));
    }
  }, []);

  async function editTitle(newTitle: string) {
    await onTitleEdit?.(newTitle);
    setIsRenaming(false);
  }

  function title() {
    if (isFetchingColumns || columns.length === 0) return [];

    if (isRenaming && onTitleEdit) {
      return (
        <div className={styles.widgetTitleEdit}>
          <WidgetTitleEdit
            initialValue={widgetTitle}
            onCancel={() => setIsRenaming(false)}
            onSave={editTitle}
          />
        </div>
      );
    }

    return [
      <TooltipWrapper
        key={widgetUuid}
        message={widgetTitle}
        position={TooltipPosition.BOTTOM_TRAILING}
        hideTooltip={!showTooltip}
        className={styles.widgetTitleTooltip}
        classNameParent={styles.widgetTitleTooltipParent}
      >
        <div className={cn(styles.widgetTooltip, styles.widgetTitle)} ref={widgetTitleRef}>
          {widgetTitle}
        </div>
      </TooltipWrapper>,
    ];
  }

  function legend() {
    if (widgetType !== WidgetType.TIMELINE || isFetchingColumns || columns.length === 0) return [];

    return widgetProps.metrics.map((metric, index) => (
      <MetricsPicker
        key={index}
        id={metric.id}
        color={metric.color!!}
        metrics={widgetProps.metrics.length > 1 ? getValidDropdownColumns(index) : columns}
        onSelectMetric={metricId => {
          onSelectMetric(index, metricId);
        }}
      />
    ));
  }
  const isInvalidAggregationForPoP =
    dimension?.name === 'timePeriod' &&
    timeOption !== 'Day' &&
    widgetType === WidgetType.CHART_BUILDER;

  function body() {
    if (isError || isPropertiesError)
      return <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />;

    if (columns.length === 0 || (data.length === 0 && widgetType !== WidgetType.CHART_BUILDER))
      return <WidgetNoData />;

    if (isInvalidAggregationForPoP)
      return (
        <div className={styles.noDataWrapper}>
          <WidgetNoData />
        </div>
      );

    return (
      <>
        {widgetType === WidgetType.CHART_BUILDER && data.length === 0 && (
          <div className={styles.noDataWrapper}>
            <WidgetNoData />
          </div>
        )}

        <TimeLineChart
          isFullScreen={false}
          widgetProps={extendTimelineWidgetProps(widgetProps)}
          data={data}
          columns={columns}
          isFetchingData={false}
          isError={false}
          widgetType={widgetType}
          leftYAxisLabel={metricNames[0]}
          rightYAxisLabel={metricNames[1]}
          dateRangePeriod={{ from, to, period }}
        />
      </>
    );
  }

  return (
    <Widget
      menuItems={menuItems()}
      // Empty function is passed here because actions are handled in each menu item
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSelectMenuItem={() => {}}
      isLoading={isFetchingColumns || isFetchingData || isFetchingProperties}
      title={title()}
      legend={legend()}
      className={styles.timeLineWidget}
      showMenu={!isRenaming}
    >
      {body()}
    </Widget>
  );
};
