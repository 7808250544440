import React, { FC } from 'react';
import { Props } from './admin.types';
import './admin.css';
import { translate } from '../../helpers/utils';
import { Tabs } from '../../components/tabs';
import { TeapotAnimation } from '../../components/teapot-animation';
import { TRACK_NAME_ADMIN_TABS, QUERY_PARAM_ACTIVE_TAB } from '../../constants';
import { BannerConfigurator } from './banner-configurator/banner-configurator';
import { NotificationSender } from './notification-sender/notification-sender';
import ApiNotificationsHelper from '../../helpers/api/notifications/api-notifications-helper';
import TrackerService from '../../services/tracker/tracker-service';
import { Tab } from '../../components/tabs';
import { useQueryParam } from '../../hooks/use-query-param';
import { Members } from './members/members';

export const Admin: FC<Props> = () => {
  const { value: activeTabKey, setQueryParam: setActiveTabKey } =
    useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  const changeTab = (tab: Tab) => {
    setActiveTabKey(tab.key);
    TrackerService.trackTabChange(tab, TRACK_NAME_ADMIN_TABS);
  };

  return (
    <div className="u-padding-top--gutter u-padding-left--gutter u-padding-right--gutter c-admin">
      <Tabs
        activeTabKey={activeTabKey || 'members'}
        onTabChange={changeTab}
        tabs={[
          {
            key: 'members',
            label: translate('admin_members'),
            content: <Members />,
          },
          {
            key: 'partners',
            label: translate('admin_partners'),
            content: <TeapotAnimation message={translate('admin_partners_not_implemented_yet')} />,
          },
          {
            key: 'banners',
            label: 'Banners',
            content: <BannerConfigurator />,
          },
          {
            key: 'notifications',
            label: 'Notifications',
            content: (
              <NotificationSender
                onSend={data => ApiNotificationsHelper.sendNotificationToAllMembers(data)}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
