import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DASHBOARD_ROUTE, HOTEL_DETAILS_PARTIAL_ROUTE } from '../../../../constants';
import { SponsoredAnalytics } from '../sponsored-analytics';
import { SponsoredHotelDetailView } from '../sponsored-hotel-detail-view';
import './sponsored-analytics-router.css';

export const SponsoredAnalyticsRouter: FC = () => {
  return (
    <Routes>
      <Route path="" element={<SponsoredAnalytics />} />
      <Route path={`/${HOTEL_DETAILS_PARTIAL_ROUTE}`} element={<SponsoredHotelDetailView />} />
      <Route path={DASHBOARD_ROUTE} element={<Navigate to={DASHBOARD_ROUTE} />} />
    </Routes>
  );
};
