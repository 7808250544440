import { ReactNode } from 'react';
import { PopoverDirection } from '../../popover';

export type NestedRow = Row & { rowHeader?: string };

export type Row = { [key: string]: any } & { id: number; nested?: NestedRow[] };

export type Column =
  | CurrencyColumn
  | PercentageColumn
  | TextColumn
  | CheckboxColumn
  | CustomColumn
  | IntColumn
  | FloatColumn;

export interface ColumnBase {
  key: string;
  name: string | number;
  isClickable?: boolean;
  isSortable?: boolean;
  tooltip?: string;
  cellClassName?: string;
}

export interface CurrencyColumn extends ColumnBase {
  type: ColumnType.CURRENCY;
}

export interface PercentageColumn extends ColumnBase {
  type: ColumnType.PERCENTAGE;
}

export interface TextColumn extends ColumnBase {
  type: ColumnType.TEXT;
}

export interface IntColumn extends ColumnBase {
  type: ColumnType.INT;
}

export interface FloatColumn extends ColumnBase {
  type: ColumnType.FLOAT;
}

export interface CheckboxColumn {
  type: ColumnType.CHECKBOX;
  key: string;
}

export interface CustomColumn extends ColumnBase {
  type: ColumnType.CUSTOM;
  children: (data: unknown, row: Row, nestingLevel?: number) => ReactNode | unknown;
}

export enum ColumnType {
  TEXT,
  PERCENTAGE,
  CURRENCY,
  CHECKBOX,
  CUSTOM,
  INT,
  FLOAT,
}

export type GridConfiguration = {
  locale: string;
  currency: {
    code: string;
  };
};

export type Sort = {
  columnKey: string;
  direction: SortDirection;
};

export type Pagination = {
  numPage: number;
  numItemsPerPage: number;
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum StyleRow {
  BOLD = 'bold',
  LARGE = 'large',
}

export type ColumnOnboardingTooltip = {
  column: Column;
  text: string;
  position: PopoverDirection;
};
