import { FilterQualifier } from '../features/filters/types';
import { View } from './view';

export type Table = {
  columns: ColumnDef[];
  data: TableData;
  total?: Row[];
};

export type TableData = {
  rows: Row[];
  count: number;
};

export interface ColumnDef {
  key: string;
  tooltip: string;
  isHiddenTotal: boolean;
  wrapper: string;
  name: string;
  isHidden: boolean;
  type: string;
  view?: View;
  isComparable: boolean;
}

export interface Row {
  id: number;
  [extraProps: string]: any;
}

export type Sort = {
  columnName: string;
  direction: SortDirection;
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FilterColumn = {
  columnName: string;
  columnLabel: string;
  type: FilterQualifier | string;
  value: string;
  condition?: FilterColumn[];
};

export type FilterType = {
  key: string;
  name: string;
};

export type ActiveFilterSet = string;

export type FilterColumnApi = {
  columnName: string;
  columnLabel: string;
  type: number;
  value: string;
};
