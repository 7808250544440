import { FC, PropsWithChildren } from 'react';
import './toolbar.css';
import { Props } from './toolbar.types';

export const Toolbar: FC<Props & PropsWithChildren> = ({
  filters = [],
  actions = [],
  children,
}) => {
  if (filters.length === 0 && actions.length === 0) {
    throw Error('At least one filter or actions needs to be defined');
  }

  return (
    <div className="u-flex-direction--column">
      <div className="u-display--flex u-margin-left--gutter u-margin-right--gutter">
        {filters.map((filterTmp, index) => (
          <div className="u-margin-right--gutter" key={index}>
            {filterTmp}
          </div>
        ))}
        <div className="u-margin-left--auto u-display--flex u-align-items--center">
          {actions.map((actionTmp, index) => (
            <div className="u-margin-left--gutter--medium" key={index}>
              {actionTmp}
            </div>
          ))}
        </div>
      </div>
      {children && <div className="u-margin-left--gutter u-margin-right--gutter">{children}</div>}
    </div>
  );
};
