import { FC, useState } from 'react';
import { translate } from '../../helpers/utils';
import {
  MULTI_SELECT_DROPDOWN_APPLY,
  MULTI_SELECT_DROPDOWN_OPEN,
  SHOWN_METRICS_SELECTOR_PREFIX,
  VIEW_NAME,
} from '../../constants';
import { Props } from './shown-metrics-selector.types';
import { OnboardingTooltip } from '../onboarding-tooltip';
import { PopoverDirection } from '../popover';
import { CheckboxSelect, CheckboxSelectOption } from '../checkbox-select';
import { Anchor } from '../../core-ui/components/anchor';
import { Button } from '../../core-ui/components/button/button';
import TrackerService from '../../services/tracker/tracker-service';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import styles from './shown-metrics-selector.module.css';

export const ShownMetricsSelector: FC<Props> = ({
  view,
  showSearchBox = true,
  isDisabled = false,
  ...props
}) => {
  const { options, selectedByDefaultOptions, onApply } = props;
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleApply = (selectedOptions: CheckboxSelectOption<unknown>[]): void => {
    const selectedOptionsMapped = selectedOptions
      .filter(({ checked }) => checked)
      .map(({ label, value }) => ({
        key: label,
        value: value as string,
      }));

    onApply(selectedOptionsMapped);
    setAnchor(null);

    TrackerService.trackWithPrefix(
      SHOWN_METRICS_SELECTOR_PREFIX(VIEW_NAME[view]),
      MULTI_SELECT_DROPDOWN_APPLY,
      {
        selectedOptions: selectedOptionsMapped.map(option => option.value),
      },
    );
  };
  return (
    <>
      <div className={styles.showMetricsSelectorContainer}>
        <TooltipWrapper
          hideTooltip={!isDisabled}
          position={TooltipPosition.TOP_LEADING}
          noTinyPaddingTop={true}
          message={translate('shown_metrics_dropdown_disabled_tooltip')}
        >
          <Button
            variant="tertiary"
            text={translate('shown_metrics_selector_label')}
            disabled={isDisabled}
            onClick={event => {
              setAnchor(event.currentTarget as HTMLElement);

              TrackerService.trackWithPrefix(
                SHOWN_METRICS_SELECTOR_PREFIX(VIEW_NAME[view]),
                MULTI_SELECT_DROPDOWN_OPEN,
              );
            }}
            icon={{ name: 'arrowDown', rotation: anchor ? 180 : 0 }}
          />
        </TooltipWrapper>
      </div>
      {anchor && (
        <Anchor
          anchor={anchor}
          anchorOrigin={{ horizontal: 'right' }}
          onClose={() => {
            setAnchor(null);
          }}
        >
          <CheckboxSelect
            options={options.map(option => ({
              id: option.value as string,
              label: option.key,
              value: option.value,
              checked: selectedByDefaultOptions.some(
                selectedOption => selectedOption.value === option.value,
              ),
            }))}
            onApply={handleApply}
            searchEnabled={showSearchBox}
            searchPlaceholder="Search for metrics"
          />
        </Anchor>
      )}
    </>
  );
};
