import { GridValue } from '../../components/grid/grid-cell';
import {
  getFilledFilters,
  getValidFilters,
} from '../../components/toolbar/custom-filters/helpers/validate-filters';
import { FILTERS_APPLY_FILTER_SET, QUERY_PARAM_ACTIVE_TAB } from '../../constants';
import { useFilters } from '../../global/context/filter-context';
import { saveDimensionFiltersToLocalStorage } from '../../helpers/local-storage-helper';
import { FilterColumn, GridContextMenu, View } from '../../models';
import { useCurrentFilterStorageKey } from '../use-current-filter-storage-key/use-current-filter-storage-key';
import { useQueryParam } from '../use-query-param';
import { useMemo } from 'react';
import trackerService from '../../services/tracker/tracker-service';

const menuItems: GridContextMenu[] = [
  { id: View.HOTEL, menu: 'Hotel' },
  { id: View.POS, menu: 'Point of Sale' },
  { id: View.TIME, menu: 'Time Period' },
  { id: View.BIDDING_TYPE, menu: 'Bidding Type' },
  {
    menu: 'Bid Modifiers',
    nestedMenu: [
      { id: View.PROPERTY_GROUPS, menu: 'Property Groups' },
      { id: View.BM_POS_LENGTH_OF_STAY, menu: 'Length of Stay' },
      { id: View.BM_POS_TIME_TO_TRAVEL, menu: 'Time to Travel' },
      { id: View.BM_POS_DEFAULT_DATE, menu: 'Default Date' },
      { id: View.BM_POS_GROUP_SIZE, menu: 'Group Size' },
      { id: View.BM_POS_COMBINED_MODIFIERS, menu: 'Combined Modifiers' },
    ],
  },
  {
    menu: 'Other',
    nestedMenu: [
      { id: View.CITY, menu: 'City' },
      { id: View.RATING, menu: 'Rating' },
      { id: View.COUNTRY, menu: 'Country' },
      { id: View.ACCOMMODATION_TYPE, menu: 'Accommodation Type' },
      { id: View.STARS, menu: 'Star Classification' },
      { id: View.REGION, menu: 'Region' },
    ],
  },
];

export const useGridContextMenu = () => {
  const { value: activeTab, setQueryParam: setSelectedTab } = useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  const { filters, setFilters, columns } = useFilters();
  const filterStorageKey = useCurrentFilterStorageKey();

  const {
    menuCol,
    menuItems: currentTabMenuItems,
    valueCol,
    applyMenuValue,
  } = useMemo(() => {
    const currentTab: string = View[activeTab as unknown as View] ?? 'hotel';

    const activeTabMenuItems = menuItems.filter(
      item => !item.menu.toLowerCase().includes(currentTab?.toLowerCase()),
    );

    const contextMenuColumns: Record<
      string,
      { menuCol: string; valueCol: string; colLabel: string }
    > = {
      hotel: { menuCol: 'pid', valueCol: 'name2', colLabel: 'Hotel Name' },
    };

    const applyValue = (value: GridValue, item: GridContextMenu | undefined) => {
      trackerService.track(`Examined by : ${item?.menu} clicked`, { from_view: currentTab });
      const filterToSet: FilterColumn = {
        columnLabel: contextMenuColumns[currentTab?.toLowerCase()]?.colLabel,
        columnName: contextMenuColumns[currentTab?.toLowerCase()]?.valueCol,
        value: value as string,
        type: 'EQUALS',
      };

      const allFilters = getUniqueFilters(filters, filterToSet);

      setFilters(getFilledFilters(getValidFilters(allFilters, columns)));
      saveDimensionFiltersToLocalStorage(allFilters, filterStorageKey);

      if (!item?.id) return;

      const findMenuById = (items: GridContextMenu[], id: number): string | null => {
        for (const item of items) {
          if (item.id === id) return item.menu;
          if (item.nestedMenu) {
            const nestedMenu = findMenuById(item.nestedMenu, id);
            if (nestedMenu) return nestedMenu;
          }
        }
        return null;
      };

      const destinationView = findMenuById(menuItems, item?.id);

      trackerService.track(FILTERS_APPLY_FILTER_SET, {
        filters: allFilters,
        destination_view: destinationView,
        triggered_by: `Examined by: ${currentTab}`,
      });

      setSelectedTab(String(item?.id));
    };

    return {
      menuCol: contextMenuColumns[currentTab?.toLowerCase()]?.menuCol,
      menuItems: activeTabMenuItems,
      valueCol: contextMenuColumns[currentTab?.toLowerCase()]?.valueCol,
      applyMenuValue: applyValue,
    };
  }, [activeTab]);

  return { menuCol, menuItems: currentTabMenuItems, valueCol, applyMenuValue };
};

const getUniqueFilters = (filters: FilterColumn[], filterToSet: FilterColumn) => {
  const hasMatchingFilter = filters.some(
    filter => filter.columnName === filterToSet.columnName && filter.type === filterToSet.type,
  );

  if (!hasMatchingFilter) return [...filters, filterToSet];

  return filters.map(filter => {
    const isMatchingFilter =
      filter.columnLabel === filterToSet.columnLabel && filter.type === filterToSet.type;

    if (!isMatchingFilter) return filter;
    return filterToSet;
  });
};
