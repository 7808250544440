import './header.css';
import { Props } from './header.types';
import { PartnerSelector } from '../partner-selector';
import { UserMenu } from '../user-menu/user-menu';
import { Navigation } from './../navigation/navigation';
import ResourceCenter from '../../../../components/resource-center/resource-center';
import { Banner } from '../banner';
import { AppContext } from '../../../context/app-context';
import logo from './trivago-intelligence-logo.svg';
import { BellAndNotificationDrawer } from '../bell-and-notification-drawer';
import { FC, useContext } from 'react';

export const Header: FC<Props> = ({ selectedPartnerId }) => {
  const {
    user: { partners },
    user,
    onUpdatePartner,
  } = useContext(AppContext);
  const hasPartners = partners.length > 0;

  const changeHandler = (selected: number) => {
    const partner = partners.find(p => p.partnerId === selected);
    onUpdatePartner(partner!);
  };

  return (
    <header className="u-border--juri-very-light">
      <div className="c-header__banner">
        <Banner />
      </div>
      <div className="c-header u-display--flex" role="banner">
        <img className="c-header__logo" src={logo} width="164" alt="trivago Intelligence logo" />
        <div className="u-margin-left--large">
          <Navigation />
        </div>

        {user ? (
          <div className="u-display--flex u-align-items--center u-margin-left--auto">
            <ResourceCenter />
            {hasPartners && (
              <PartnerSelector
                partners={partners}
                onChange={changeHandler}
                selectedValue={selectedPartnerId}
              />
            )}
            <BellAndNotificationDrawer />
            <UserMenu />
          </div>
        ) : null}
      </div>
    </header>
  );
};
