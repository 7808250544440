import { createContext } from 'react';
import { BidFormUnit, BidItem } from './bid-form.types';

type BidFormContextType = {
  value: string;
  setValue: (newValue: string) => void;
  unit: BidFormUnit;
  setUnit: (newUnit: BidFormUnit) => void;
  bidItems: BidItem[];
};

export const BidFormContext = createContext<BidFormContextType>({} as BidFormContextType);
