export function generateHash(value: string): string {
  let hash = 0;
  if (value.length === 0) return hash.toString();
  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash.toString();
}

export function generateRequestKey<T>(url: string, body?: T): string {
  const bodyString = JSON.stringify(body);
  return generateHash(`${url}|${bodyString}`);
}
