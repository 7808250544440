import { AxiosError } from 'axios';
import { RequestHelper } from '../../../helpers/api/request-helper';

const URL_GET_INVENTORY_STATS = '/new-inventory/stats';

export type ApiInventoryStats = {
  partner_id: number;
  active_matched: number;
  missing_matches: number;
  duplicate_matches: number;
  active_unmatched: number;
};

class ApiInventoryStatsHelper extends RequestHelper {
  public async getInventoryStats(partnerIds: number[]): Promise<ApiInventoryStats[]> {
    try {
      const response = await this.getRequest(URL_GET_INVENTORY_STATS, {
        params: { partnerIds },
        paramsSerializer: params => `partnerIds=${params.partnerIds.join(',')}`,
      });
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'widget_data_error');
      return [];
    }
  }
}

export default new ApiInventoryStatsHelper();
