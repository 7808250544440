import { ComponentProps, FC } from 'react';
import styles from './average-data-toggle.module.css';
import { Toggle } from '../../components/toggle';
import { translate } from '../../helpers/utils';
import TrackerService from '../../services/tracker/tracker-service';
import { Tooltip } from '../../core-ui/components/tooltip/tooltip';
import { ANALYTICS_VIEW_AVERAGE_DATA_TOGGLE } from '../../constants';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { useSortParams } from '../../hooks/use-sort-params';

export const AverageDataToggle: FC<
  {
    value: boolean;
    setValue: (newValue: boolean) => void;
    tooltip: string;
  } & ComponentProps<'div'>
> = ({ value, setValue, tooltip }) => {
  const currentView = String(useCurrentView()).toLowerCase();
  const { isDateComparisonActive, period } = useSortParams(['isDateComparisonEnabled', 'period']);

  const handleChange = (nextValue: boolean) => {
    const isToggleOn = nextValue ? 'on' : 'off';
    const props =
      isDateComparisonActive || period !== undefined
        ? { triggered_by: `yesterday's overview` }
        : {};

    TrackerService.track(`${currentView} ${ANALYTICS_VIEW_AVERAGE_DATA_TOGGLE}`, {
      toggle: isToggleOn,
      ...props,
    });
    setValue(nextValue);
  };

  return (
    <div className={styles.wrapper}>
      <Tooltip title={tooltip} position="topCenter" className={styles.tooltip}>
        <Toggle
          isActive={value}
          label={translate('average_data_label')}
          onChange={handleChange}
          style={{
            border: 'none',
            padding: 0,
            gap: 'var(--abs-8)',
          }}
        />
      </Tooltip>
    </div>
  );
};
