import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { getMatchingFilters } from '../../components/toolbar/custom-filters/helpers/validate-filters';
import {
  COLUMN_IS_SL_RECORD,
  COLUMN_KEY_CPA_GROUP,
  REACT_QUERY_STALE_TIME,
  ReactQueryKeys,
} from '../../constants';
import { AppContext } from '../../global/context/app-context';
import { useFilters, useGlobalColumns } from '../../global/context/filter-context';
import ApiWidgetsHelper from '../../helpers/api/dashboard/api-widgets-helper';
import { WidgetPayload } from '../../helpers/api/dashboard/types';
import { FilterColumn, View } from '../../models';
import { biddingTypes2Filter } from '../../services/analytics-service';
import { areMultiplePOSorGlobalSelected } from '../../services/app-service';
import { CHART_BUILDER_VIEWS } from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import { getTimePeriodData } from './get-time-period-data';
import { createIsSlFilterColumn } from '../../helpers/filter-columns';

const timelineViewsWithoutSLData = [View.TIMELINE];
const timelineViewsWithSLData = CHART_BUILDER_VIEWS.filter(
  view => !timelineViewsWithoutSLData.includes(view),
);
const isEligibleForSlFilter = (view: View) =>
  [...timelineViewsWithSLData, View.YESTERDAY_OVERVIEW_WITH_SL].includes(view);

const hasNoSlData = (view: View) =>
  [View.BMLU, View.POS_OVERVIEW, ...timelineViewsWithoutSLData].includes(view);

type ViewValidation = {
  [view in View]?: (payload: WidgetPayload) => boolean;
};

const viewValidations: ViewValidation = {
  [View.PROPERTY_GROUPS_WITH_SL]: ({ partnersId, localeCodes }) =>
    partnersId.length === 1 && !areMultiplePOSorGlobalSelected(localeCodes ?? []),
};

const isRequestPayloadValid = (v: View, payload: WidgetPayload) =>
  viewValidations[v]?.(payload) ?? true;

export const useWidgetData = (
  view: View,
  payload: WidgetPayload,
  dependencyList: Array<any> = [],
) => {
  const { filters, selectedBiddingTypes, includeSlData } = useFilters();
  const fetchColumns = async () => {
    const columns = await ApiWidgetsHelper.getColumns(view, payload.partnersId);
    const columnsToDisplay = columns.filter(column => !column.isHidden);
    const columnsToFilter = columns.filter(column => column.isComparable);
    return { columnsToDisplay, columnsToFilter };
  };

  const {
    isLoading: isFetchingColumns,
    isError: isColumnError,
    data: columns,
  } = useQuery({
    queryKey: [ReactQueryKeys.WIDGET_COLUMNS, view],
    queryFn: fetchColumns,
    staleTime: REACT_QUERY_STALE_TIME,
  });

  const { columnsToDisplay = [], columnsToFilter = [] } = columns ?? {};

  useGlobalColumns(columnsToFilter, view);
  const { dateRangePeriod } = useContext(AppContext);

  const conditions = getMatchingFilters(filters, columnsToFilter);

  const biddingTypeFilters = biddingTypes2Filter(selectedBiddingTypes, COLUMN_KEY_CPA_GROUP);
  if (
    view === View.BIDDING_TYPE_CB_WITH_SL ||
    (view !== View.INVENTORY && selectedBiddingTypes.length > 0)
  ) {
    conditions.push(biddingTypeFilters);
  }

  if (isEligibleForSlFilter(view)) {
    conditions.push(createIsSlFilterColumn(includeSlData));
  }

  const fetchData = () => {
    if (hasNoSlData(view) && includeSlData && selectedBiddingTypes.length === 0)
      return Promise.resolve([]);
    if (!isRequestPayloadValid(view, payload)) return Promise.resolve([]);
    const widgetPayload: WidgetPayload = {
      aggregations: payload.aggregations,
      condition: mergeConditions(conditions, payload.condition || []),
      partnersId: payload.partnersId,
      localeCodes: payload.localeCodes,
      from: payload.from,
      to: payload.to,
      sort: payload.sort,
      shownColumns: payload.shownColumns,
      timeOption: payload.timeOption,
      dimensionValues: payload.dimensionValues,
    };
    if (payload.dimension === 'timePeriod') {
      return getTimePeriodData({ basePeriod: dateRangePeriod, view, widgetPayload });
    }
    return ApiWidgetsHelper.getWidgetsData(view, widgetPayload);
  };

  const {
    isLoading: isFetchingData,
    isError: isDataError,
    data,
    refetch,
  } = useQuery({
    queryKey: [
      ReactQueryKeys.WIDGET_DATA,
      view,
      JSON.stringify(dependencyList),
      JSON.stringify(conditions),
      includeSlData,
      payload.partnersId,
      dateRangePeriod.period,
    ],
    queryFn: fetchData,
    staleTime: REACT_QUERY_STALE_TIME,
    enabled: columnsToDisplay.length > 0,
  });

  return {
    columns: columnsToDisplay,
    data: data || [],
    isFetchingColumns,
    isFetchingData,
    isLoading: isFetchingData || isFetchingColumns,
    isError: isDataError || isColumnError,
    refetch,
  };
};

const mergeConditions = (filterConditions: FilterColumn[], payloadConditions: FilterColumn[]) => {
  const allConditions = [...filterConditions, ...payloadConditions];
  const conditionsWithoutSL = allConditions.filter(
    condition => condition.columnName !== COLUMN_IS_SL_RECORD,
  );

  const hasMultipleSlConditions = allConditions.length - conditionsWithoutSL.length >= 2;
  if (!hasMultipleSlConditions) return allConditions;

  const isSlDisabled = allConditions
    .filter(condition => condition.columnName === COLUMN_IS_SL_RECORD)
    .map(condition => condition.value)
    .includes('0');

  return [...conditionsWithoutSL, createIsSlFilterColumn(!isSlDisabled)];
};
